import { ListItemText, MenuItem, MenuList } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export const NestedList = forwardRef((props, ref) => {
    const {items} = props;
    const [currentItem, setCurrentItem] = useState({});
    const [currentItems, setCurrentItems] = useState(items);
    const [currentView, setCurrentView] = useState();

    function handleBack() {
        setCurrentItems(currentItem.parentItems)
        setCurrentItem(currentItem.prevItem)

        return currentItem && Object.keys(currentItem).length > 0
    }
    
    useImperativeHandle(ref, () => ({
        loadPrevious() {
            handleBack()
        }    
    }))


    function processItems(items) {
        let view = null

        if(items && Array.isArray(items)) {
            const buffer = []
            items.forEach((item, index) => {
                const label = item.label
                const rightLabel = item.rightLabel
                const children = item.children

                if(label) {
                    buffer.push(<MenuItem key={index} onClick={() => {
                            item.parentItems = items;
                            item.prevItem = currentItem;

                            setCurrentItem(item)
                            setCurrentItems(children)
                        }} sx={{borderBottom : "1px solid #ccc"}}>
                        <ListItemText>{label}</ListItemText>
                        {rightLabel}
                    </MenuItem>
                    )
                }
                else {
                    buffer.push(item)
                }
            })
            view = <MenuList>{buffer}</MenuList>
        }
        else {
            view = items
        }
        
        setCurrentView(view)
    }

    useEffect(() => {
        if(currentItems) {
            processItems(currentItems);
        }
    }, [items, currentItems, currentItem])

    return currentView
})