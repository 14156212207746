import { createContext, useContext, useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';

const FilterContext = createContext();

export const FilterProvider = ({ children, dataFilters = [] }) => {
  const [filters, setFilters] = useState(dataFilters || []);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const updateFilterValue = (filter, value) => {
    const filterName = filter.name;
    setFilters(prevFilters => prevFilters.map(filter => (filter.name === filterName ? { ...filter, value } : filter)));
    const isSelected = CollectionUtil.hasMatchingElement(selectedFilters, filter => filter.name === filterName);
    if (!isSelected) {
      setSelectedFilters([...selectedFilters, { name: filterName, value }]);
    }
  };

  const addFilter = filterToAdd => {
    const existingFilter = filters.find(filter => filter.name === filterToAdd.name);
    if (!existingFilter) {
      setFilters(preFilters => [...preFilters, filterToAdd]);
    }
  };

  const selectFilter = filterToSelect => {
    setSelectedFilters(prevSelectedFilters => prevSelectedFilters.map(filter => (filter.name === filterToSelect.name ? { ...filterToSelect } : filter)));
  };

  return <FilterContext.Provider value={{ filters, selectedFilters, setFilters, updateFilterValue, addFilter, selectFilter }}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => useContext(FilterContext);
