import Layout2, { layout2Structure } from 'layout/frame/layout2/Layout2';
import { createContext, useContext, useState } from 'react';

export const FrameLayoutContext = createContext({
  layout: layout2Structure,
  changeLayout: () => {},
});

export const FrameLayoutContextProvider = ({ children }) => {
  const [currentLayout, setCurrentLayout] = useState(layout2Structure);
  const [currentLayoutName, setCurrentLayoutName] = useState('Layout2');
  const layouts = {
    Layout2: layout2Structure,
  };

  const changeLayout = layoutName => {
    setCurrentLayout(layouts[layoutName]);
    setCurrentLayoutName(layoutName);
  };

  return (
    <FrameLayoutContext.Provider
      value={{
        layoutName: currentLayoutName,
        layout: currentLayout,
        changeLayout,
      }}>
      {children}
    </FrameLayoutContext.Provider>
  );
};

export default function useLayout() {
  return useContext(FrameLayoutContext);
}
