import { HotGrid180 } from 'components/Containers/Grid';
import { FormInput, FormLabelledSwitch, FormNumericInput, FormTags, SmartFormInput } from 'components/Form/FormInputs';
import { NestedList } from 'components/NestedList';
import useAppState from 'hooks/useAppState';
import useRoleDetails from 'hooks/useRoleDetails';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CollectionUtil } from 'utils/CollectionUtil';
import StringUtil from 'utils/StringUtil';
import useHttp from "hooks/useHttp";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RoleDetailsContext } from './context/RoleDetailContext';

const initialState = {
    permissions : [],
    accountPermissionGroups : new Map(),
    accountPermissionGroupBuffer : new Map(),
    panelPermissionGroupBuffer : new Map(),
    loaded : false,
    loading : false,
}


  
/**
 * Renders all permissions.
 */
export function RolePermissions(props) {
    const {displayIsr, displayCheckBox } = props;
    const [permissionState, setPermissionState] = useState(initialState)
    const {permissionsMap, updateRoleDetails} = useRoleDetails();
    const {hasPermission} = useAppState();
    const {get} = useHttp();

    const {t} = useTranslation()
    useEffect(() => {
        fetchPermissions()
    }, [])

    
    function fetchPermissions () {
        if (!permissionState.loaded && !permissionState.loading) {
            setPermissionState({...permissionState,  'loading' : true})

            get("/permissions").then(p => { 
                const accountPermissionGroupBuffer = new Map()
                
                setPermissionState({...permissionState, 'permissions' : p})

                p.forEach(permission => {
                    if(permission.accountPermission) {
                        addPermissionModuleGroup(accountPermissionGroupBuffer, permission);
                    }
                })

                setPermissionState({...permissionState, 'loading' : false, loaded : true, 'accountPermissionGroups' : accountPermissionGroupBuffer})
            })
        }
    }

    
    function addPermissionModuleGroup (permissionGroups, permission) {
        if(permission.moduleGroup && permission.moduleGroup !== "") {
            if (permissionGroups.has(permission.moduleGroup)) {
                addPermissionModule(permissionGroups.get(permission.moduleGroup), permission)
            }
            else {
                const buffer = new Map();
                permissionGroups.set(permission.moduleGroup, buffer);
                addPermissionModule(buffer, permission);
            }
        }
    }

    function addPermissionModule (permissionGroups, permission) {
        if (permissionGroups.has(permission.moduleName)) {
            permissionGroups.get(permission.moduleName).push(permission);
        }
        else {
            const buffer = [];
            permission.isrFrom = permission.isrFrom && permission.isrFrom !== undefined ? permission.isrFrom : ''
            permission.isrTo = permission.isrTo && permission.isrFrom !== undefined  ? permission.isrTo : ''
            buffer.push(permission)

            permissionGroups.set(permission.moduleName, buffer);
        }
    }

    function getAllPermissionCount(field, value) {        
        const permissions = Array.from(permissionsMap.values());
        return permissions.filter(p => p[field] === value).length;
    }
    function getAssignedPermissionCount(field, value) {
        const permissions = Array.from(permissionsMap.values());
        const count = permissions.filter(p => p.assigned && (p[field] === value)).length;
        return count;
    }

    function updatePermissionAttribute(permissionName, attribute, value) {
        const p = permissionsMap.get(permissionName);
        p[attribute] = value;
        permissionsMap.set(permissionName, p);

        const permissions = permissionState.permissions.filter(p => p.name === permissionName)
        if(permissions && permissions.length > 0) {
            permissions.forEach(p => p[attribute] = value)
        }
        else {
            p[attribute] = value
            permissions.push(p)
        }

        updateRoleDetails({key : permissionsMap , value : new Map(permissionsMap)})
        setPermissionState({...permissionState, permissions : permissions})
    }

    const items = []
    if (permissionState.loaded) {
        const permissionGroups =  permissionState.accountPermissionGroups;

        let keys = [...permissionGroups.keys()]
        CollectionUtil.sort(keys)

        let index = 0;

        keys.forEach(key => {
        const permissionModuleMap = permissionGroups.get(key);
        const modules = [...permissionModuleMap.keys()]
        const moduleChildren = []
        modules.forEach(module => {
            if (!StringUtil.containingIgnoreCase(module, "System") || hasPermission('ADMINISTER_SYSTEM')) {
                const permissions = permissionModuleMap.get(module)
                const permissionChildren = []
                const i = index;
                index++;
                permissions.forEach(permission => {
                    if (!permissionsMap.has(permission.name)) {
                        permissionsMap.set(permission.name, {
                        moduleGroup: key,
                        moduleName: module,
                        name: permission.name,
                            assigned: false,
                            isrFrom: '',
                            isrTo: ''
                        })
                        
                        updateRoleDetails({key : 'permissionsMap', value : new Map(permissionsMap)})
                    }
                    
                    if(displayIsr) {
                        permissionChildren.push(<PermissionIsr permission={permission} index={i} key={permission.name} updatePermissionAttribute={updatePermissionAttribute} {...props}/>)
                    }
                    if(displayCheckBox) {
                        permissionChildren.push(<PermissionToggle permission={permission} index={i} key={permission.name} updatePermissionAttribute={updatePermissionAttribute} {...props}/>)
                    }
                })

                moduleChildren.push({
                    label : module,
                    rightLabel : <>
                            {getAssignedPermissionCount("moduleName", module) + "/" + getAllPermissionCount("moduleName", module)}
                            <ArrowForwardIcon />
                        </>,
                    children : <>{permissionChildren}</>,
                })
            }
        })

        items.push({
                label : key,
                rightLabel : <>
                        {getAssignedPermissionCount("moduleGroup", key) + "/" + getAllPermissionCount("moduleGroup", key)} 
                        <ArrowForwardIcon />
                    </>,
                children : moduleChildren
            })
        })

        return items && items.length > 0 && <NestedList items={items} label={t("permissions")}/>
    }
}

/**
 * Renders a permission.
 */
function PermissionToggle(props) {
    const { permission, index, updatePermissionAttribute } = props;
    const useRoleDetails = () => useContext(RoleDetailsContext);
    const {permissionsMap} = useRoleDetails();

    return <div key={permission.name}>
            <FormLabelledSwitch
                checked={permissionsMap.has(permission.name) && permissionsMap.get(permission.name).assigned}
                name={"permissions[" + index + "].assigned"}
                onChange={(e) => updatePermissionAttribute(permission.name, 'assigned', e.target.checked)}
                label={permission.name} />
            </div>
}


/**
 * Renders a permission.
 */
function PermissionIsr(props) {
    const { permission, index, updatePermissionAttribute} = props;
    const useRoleDetails = () => useContext(RoleDetailsContext);
    const {permissionsMap} = useRoleDetails();
    return <div key={permission.name}>
        <HotGrid180>
                <FormInput value={permission.name} readOnly/>
                <FormLabelledSwitch
                checked={permissionsMap.has(permission.name) && permissionsMap.get(permission.name).allLabelsAllowed}
                name={"permissions[" + index + "].allLabelsAllowed"}
                onChange={(e) => updatePermissionAttribute(permission.name, 'allLabelsAllowed', e.target.checked)}
                label="All ITL?" />
            <FormTags
                disabled={permissionsMap.has(permission.name) && permissionsMap.get(permission.name).allLabelsAllowed}
                name={"permissions[" + index + "].infoLabels"}
                label="ITL"
                tags={permissionsMap.get(permission.name).infoLabels}
                delimiter={","}
                onAdd={(tags) => updatePermissionAttribute(permission.name, 'infoLabels', tags)}
                afterDelete={(tags) => {
                    updatePermissionAttribute(permission.name, 'infoLabels', tags)
                }}
                placeholder="Add comma separated labels" />
            <SmartFormInput
                    label="ISR Range"
                    value={permissionsMap.get(permission.name).isrFrom + " - " + permissionsMap.get(permission.name).isrTo}
                    hiddenFields={
                        [
                            { name: "permissions[" + index + "].isrFrom", value: permissionsMap.get(permission.name).isrFrom },
                            { name: "permissions[" + index + "].isrTo", value: permissionsMap.get(permission.name).isrTo }
                        ]
                    }>
                    <FormNumericInput
                        label="From"
                        value={permissionsMap.get(permission.name).isrFrom}
                        name={"permissions[" + index + "].isrFrom"}
                        onChange={(e) => {
                            if (e.target.value > 0) {
                                updatePermissionAttribute(permission.name, 'isrFrom', e.target.value)
                            }
                        }} />
                    <FormNumericInput
                        label="To"
                        value={permissionsMap.get(permission.name).isrTo}
                        name={"permissions[" + index + "].isrTo"}
                        onChange={(e) => {
                            if (e.target.value > 0) {
                                updatePermissionAttribute(permission.name, 'isrTo', e.target.value)
                            }
                        }} />
            </SmartFormInput>
        </HotGrid180>
    </div>
}
