import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const recordKeeping = { name : 'Record-keeping',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Transaction Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Log Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Request Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Planning Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Dataset Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Handshake Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }