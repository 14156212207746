import { createTheme } from '@mui/material/styles';
import { useBaseTheme } from 'contexts/Theme/base-theme';

export function useComfortableTheme() {
  const baseTheme = useBaseTheme();

  const comfortableTheme = createTheme({
    ...baseTheme,
  });

  return comfortableTheme;
}
