import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { ChildCare } from '../../../node_modules/@mui/icons-material/index';

export const legal = { name : 'Legal',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'About the Company',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }