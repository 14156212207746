import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
// HOOK

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) return <>{children}</>;

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
}
