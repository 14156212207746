import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export function StandardAccordian(props) {
  const { title, open = false, permanentOpen, sx, children, leftToolButtons } = props;
  // Initialize the state to true to keep the Accordion open initially
  const [expanded, setExpanded] = useState(open ? true : false);

  const handleChange = (event, newExpanded) => {
    if (!permanentOpen) {
      setExpanded(newExpanded);
    }
  };
  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={handleChange}
        sx={{
          backgroundColor: 'transparent',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)', // Lighter top border
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)', // Lighter bottom border
          '&:before': { display: 'none' }, // Remove default MUI border
          borderLeft: 'none', // Remove left border
          borderRight: 'none', // Remove right border
          borderBottom: 'none',
          padding: 0, // Remove padding inside accordion
          ...sx,
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='features-content' id='features-header' sx={{ paddingLeft: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography variant='h6'>{title}</Typography>
            </Box>
            <Box>{leftToolButtons && leftToolButtons}</Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

export function PermanentOpenAccordian(props) {
  const { title, sx, children } = props;
  return (
    <StandardAccordian title={title} open={true} permanentOpen onChange={() => {}} sx={sx}>
      {children}
    </StandardAccordian>
  );
}
