import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import StringUtil from 'utils/StringUtil';

function UserProfileCard({user}) {
  return (
    <Card sx={{ maxWidth: 280, padding: 2, boxShadow: 3 }}>
      <Grid container justifyContent="flex-end">
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Grid>
      <Avatar {...StringUtil.stringAvatar(user.name)} sx={{
          width: 80,
          height: 80,
          mx: 'auto',
          mb: 2,
          bgcolor: 'primary.main',
        }}/>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>
          {user.name}
        </Typography>
        <Typography variant="subtitle2" align="center" color="text.secondary">
          {user.systemCode}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Email
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {user.principal}
        </Typography>
        {/* <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Phone
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Location
          </Typography>
        </Grid> */}
        {/* <Grid container justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            +9999999999
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Port Narcos
          </Typography>
        </Grid> */}
      </CardContent>
      {/* <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
        <Button
          variant="outlined"
          startIcon={<MessageIcon />}
          sx={{ borderRadius: 3, textTransform: 'none', mx: 1 }}
        >
          Message
        </Button>
        <Button
          variant="outlined"
          startIcon={<CallIcon />}
          sx={{ borderRadius: 3, textTransform: 'none', mx: 1 }}
        >
          Call
        </Button>
      </CardActions> */}
    </Card>
  );
}

export default UserProfileCard;
