
import { FileUtil } from 'utils/FileUtil';
/**
 */
export default class MenuService {

  static async loadMenu(path) {
    const data = await FileUtil.readJsonFile(`/assets/menu/${path}`);
    return data.items || [];
  }
}
