import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import StringUtil from 'utils/StringUtil';
import * as React from 'react';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { headCells, initials, order, orderBy, numSelected, rowCount, actionMenu, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {initials && <TableCell />}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actionMenu && <TableCell>
          Action
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}


export default function SimpleSortingTable({headCells, initials, items, totalElements, onRowClick, onPageChange, actionMenu, onDrop, ...props}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dense, setDense] = React.useState(false);
  React.useEffect(() => {
    setDense(true)
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  React.useEffect(() => {
    if(onPageChange) {
      onPageChange(page, rowsPerPage)
    }
  }, [page, rowsPerPage])

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }} className='applist-table-container'>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              initials={initials}
              actionMenu={actionMenu}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {items.map((row, index) => <SimpleSortingTableRow actionMenu={actionMenu} initials={initials} row={row} index={index} selected={selected} onRowClick={onRowClick} headCells={headCells} key={index} setSelected={setSelected} onDrop={onDrop}/>)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalElements || items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function SimpleSortingTableRow(props) {
  const {actionMenu, row, index, selected, onRowClick, headCells, setSelected, initials, onDrop} = props
  const isItemSelected = selected.includes(index);

  const [dragging, setDragging] = React.useState();

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  function handleDragOver() {
    if(onDrop && typeof(onDrop) === 'function') {
      setDragging(true)
    }
  }

  function handleDragLeave() {
    if(onDrop && typeof(onDrop) === 'function') {
      setDragging(false)
    }
  }

  function handleDrop(event) {
    if(onDrop && typeof(onDrop) === 'function') {
      onDrop(row, event)
    }
  }

  return <TableRow
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            hover
            onClick={(event) => handleClick(event, index)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected || dragging}
            sx={{ cursor: 'pointer' }}
            >
              {initials && <TableCell onClick={() => {
                if(onRowClick && typeof(onRowClick) === 'function') {
                  onRowClick(row)
                }
              }}>
                <Avatar {...StringUtil.stringAvatar(row[initials.text])} />
              </TableCell>}
              {headCells.map((head, index) => <TableCell key={index} scope="row" onClick={() => {
                if(head.onClick && typeof(head.onClick) === 'function') {
                  head.onClick(row)
                }
                else if(onRowClick && typeof(onRowClick) === 'function') {
                  onRowClick(row)
                }
              }}>
                  {
                    head.formatter 
                      ? head.formatter(row)
                      : row[head.id] ? row[head.id] : "-"
                  }
              </TableCell>
              )}

              {actionMenu && <TableCell>{actionMenu(row)}</TableCell>}
            </TableRow>
}