import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, CardHeader, IconButton, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import packageJson from "./../../../../package.json";
import { ColorUtil } from "utils/ColorUtil";
import StringUtil from "utils/StringUtil";
import UrlUtil from "utils/UrlUtils";
import { AppFlatMenuList } from 'components/Menu/AppMainMenuList';
import { SmartLabel } from 'components/Box/Box';
import { MoreVertMenu } from 'components/Menu/Menu';
import { CollectionUtil } from 'utils/CollectionUtil';

class AppStateHolder{
    static appState = {
        user: {}
    };
}

/**
 * Clickable nugget main.
 * @param {*} props The nugget main props.
 */
export function ClickableNuggetMain(props) {
    return <ClickableNuggetPart className="nugget-main" {...props} />
}

export function ClickableNuggetPart(props) {
    const { url, item, onClick, ...other } = props;

    let handleClick;
    if (onClick && typeof (onClick) === 'function') {
        handleClick = (e) => {
            if (item) {
                const event = new CustomEvent('itemDetailsDisplayed', { detail: item });
                window.dispatchEvent(event);
            }
            props.onClick(e);
        }
    }
    else {
        handleClick = () => {
            if (item) {
                const event = new CustomEvent('itemDetailsDisplayed', { detail: item });
                window.dispatchEvent(event);
            }
        }
    }

    return <div onClick={handleClick} {...other}>
        {props.children}
    </div>
}

export function ClickableNuggetAfter(props) {
    const { url, onClick } = props;

    let handleClick;
    if (onClick && typeof (onClick) === 'function') {
        handleClick = (e) => {
            onClick(e);
        }
    }
    else {
        handleClick = () => {
        }
    }

    return <div className="nugget-after" onClick={handleClick}>
        {props.children}
    </div>
}

export function NonClickableNuggetAfter(props) {
    return <div className="nugget-after">
        <div className="side-icon">
            {props.children}
        </div>
    </div>
}

export function ApplicationBuildNugget() {
    const { t } = useTranslation();
    return (
        <div className="rootnugget settingsnugget">
            <div className="nugget-main">
                <div className="nugget-card">
                    <div className="nugget-card1">
                        <div className="nugget-icon"> <i className="fa fa-copyright" aria-hidden="true"></i> </div>
                    </div>
                    <div className="nugget-card2">
                        <div className="nugget-title">{t("platform-build")}</div>
                        {/* {appState.appContext.applicationVersion} */}
                        <div className="nugget-subtitle">APPLICATION_VERSION | {packageJson.version}</div>
                    </div>
                </div>
            </div>
        </div>)
}

export function CopyrightNugget() {
    const { t } = useTranslation();
    return (
        <div className="rootnugget settingsnugget">
            <div className="nugget-main">
                <div className="nugget-card">
                    <div className="nugget-card1">
                        <div className="nugget-icon"> <i className="fa fa-copyright" aria-hidden="true"></i> </div>
                    </div>
                    <div className="nugget-card2">
                        <div className="nugget-title">{t("copyright")}</div>
                        <div className="nugget-subtitle">Hotlink Services Pvt Ltd</div>
                    </div>
                </div>
            </div>
        </div>)
}

export function MenuAfterNugget(props) {
    const { t } = useTranslation();
    const menuAnchor = <div style={{ width: "20px", display: "flex", justifyContent: "center" }}><div><i className="fas fa-ellipsis-v"></i></div></div>
    return <div className="nugget-after">
        <div className="side-icon">
            <AppFlatMenuList menuAnchor={menuAnchor}>
                {props.children}
            </AppFlatMenuList>
        </div>
    </div>
}

export function DeleteButtonNuggetAfter(props) {
    const { t } = useTranslation();
    const title = props.title
        ? props.title
        : t("delete")

    return <div className="nugget-after" title={title}>
        <div className="side-icon" onClick={() => props.onClick(props.item)}>
            <span className="fa fa-trash">&nbsp;</span>
        </div>
    </div>
}

export function FailedLoginNugget() {
    const { t } = useTranslation();
    const appState = AppStateHolder.appState;
    return (
        <div className="rootnugget settingsnugget">
            <div className="nugget-main">
                <div className="nugget-card">
                    <div className="nugget-card1">
                        <div className="nugget-icon"> <i className="fa fa-sign-in-alt" aria-hidden="true"></i> </div>
                    </div>
                    <div className="nugget-card2">
                        <div className="nugget-title">{t("last-failed-sign-in")}</div>
                            {
                                appState.user.lastFailedLoginAt ? <div className="nugget-subtitle">{appState.user.lastFailedLoginAt}</div> : "-"
                            }
                    </div>
                </div>
            </div>
        </div>)
}

export function Initials(props, key) {
    const { text, id, classes } = props;
    const avatar = StringUtil.stringAvatar(text)
    const bgColor = avatar.sx.bgcolor;

    
    return text && text !== ""
        ?
        <div className="nugget-initials">
            <div className={"initials " + classes} style={{ backgroundColor: bgColor, color : '#FFF'}}>
                {avatar.children}
            </div>
        </div>
        : ""
}

export function NuggetImageIcon(props) {
    const { icon, style } = props;

    return <div className="nugget-initials" style={style}>
        <div className='initials'> {icon} </div>
    </div>
}


export function NuggetAmount(props) {
    const amount = props.amount;
    return <div>
        <div></div>
        <div>
            <div className="nugget-amount">
                <span className="nugget-amount-currency">{amount.currency} </span>
                <span className="nugget-amount-whole">{amount.wholeAmount}</span>
                <span className="nugget-amount-fraction">{amount.fractionAmount}</span>
            </div>
        </div>
    </div>
}


export function NuggetIcon(props) {
    const { icon } = props;

    return <div className="nugget-icon">
        <i className={icon}></i>
    </div>
}

export function NuggetPicture(props) {
    const { pictureLocator, pictureType, alt, style } = props;

    return <div className="nugget-picture" style={style}>
        <img src={UrlUtil.getCompleteUrl('/picture/thumbnail/' + pictureLocator + "." + pictureType)} alt={alt} />
    </div>
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export function NuggetRibbonToolbar(props) {
    return <div className="nugget-toolbar">
        <div></div>
        <div className="nugget-toolbar2">
            <div className="nugget-ribbon">
                {props.children}
            </div>
        </div>
    </div>
}

export function NuggetQuantity(props) {
    const label = props.label;
    const value = props.value;
    return <div>
        <div></div>
        <div>
            <div className="nugget-qty">
                <span className="nugget-qty-caption">{label}: </span>
                <span className="nugget-qty-value">{value}</span>
            </div>
        </div>
    </div>
}

export function SuccessfulLoginNugget() {
    const { t } = useTranslation();
    const appState = AppStateHolder.appState;
    return (
        <div className="rootnugget settingsnugget">
            <div className="nugget-main">
                <div className="nugget-card">
                    <div className="nugget-card1">
                        <div className="nugget-icon"> <i className="fa fa-sign-in-alt" aria-hidden="true"></i> </div>
                    </div>
                    <div className="nugget-card2">
                        <div className="nugget-title">{t("last-successful-sign-in")}</div>
                        <div className="nugget-subtitle">{appState.user.lastSuccessfulLoginAt}</div>
                    </div>
                </div>
            </div>
        </div>)
}

export function ListingItemNugget(props) {
    const {item, key, onClick, menuItems} = props;

    return(
        <div className="rootnugget" key={key}>
            <div className="nugget-main">
                <ClickableNuggetPart item={item} onClick={() => {onClick && onClick(item)}}>
                    <Initials text={item.name} id={item.scopeSystemCode}/>
                    <div className="nugget__rootclick-appmain">
                        <div className="nugget-title" style={{display:"flex"}}> {item.name}</div>
                    </div>
                </ClickableNuggetPart>
            </div>
            {CollectionUtil.isNotBlank(menuItems) && 
                <ClickableNuggetAfter onClick={() => {}}>
                    <MoreVertMenu> 
                        {CollectionUtil.isNotBlank(menuItems) && menuItems}
                    </MoreVertMenu>
                </ClickableNuggetAfter>
            }
        </div>
    )
}

export function EntitySummaryNugget(props) {
    const { entityName, entityType, entityCode, showActionButton, ...others } = props;

    const nameTextAndBgColor = ColorUtil.getTextColorAndBackground(entityName)
    const cardAvatar = <Avatar sx={{ bgcolor: nameTextAndBgColor.bgColor }} title={entityName} > {StringUtil.getInitials(entityName)} </Avatar>
    const actionButton = showActionButton && <IconButton aria-label="settings"> <MoreVertIcon /> </IconButton>
    var subHeader = entityType;
    if (StringUtil.isNotBlank(entityCode)) {
        subHeader = subHeader + " :: " + entityCode;
    }
    const entityNameLabel = <SmartLabel title={entityName} >{entityName}</SmartLabel>
    // const subHeaderLabel = <SmartLabel title={entityName} >{subHeader}</SmartLabel>
    return <CardHeader avatar={cardAvatar} action={actionButton}
        title={entityNameLabel} subheader={subHeader} sx={{ "&:hover": { backgroundColor: "#d7e8f9" } }} {...others} />
}