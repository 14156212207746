import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, MenuItem, TextField } from "@mui/material";
import CopyToClipboard from 'components/CopyToClipboard';
import DialogBox from 'components/Dialog';
import { DropDownMenu } from "components/Menu/Menu";
import useFileSystem from "hooks/filesystem/useFileSystem";
import useHttp from 'hooks/useHttp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ProductBinderMenu({item, onRename}) {
    const {deleteObject} = useFileSystem();
    const {get} = useHttp();
    const [headers, setHeaders]= useState();
    const [showTemplate, setShowTemplate] = useState()
    useEffect(() => {
      get("/product/binder/headers").then((data) => {
        const mandatoryHeaders = data.mandatoryHeaders;
        const optionalHeaders = data.optionalHeaders;

        setHeaders(mandatoryHeaders.concat(optionalHeaders).join("\t"));
      });
    }, [])
    return <>
         {showTemplate && 
          <TemplateEdit item={item} onClose={() => setShowTemplate(false)}/>
        }

            <DropDownMenu menuIcon={<MoreVertIcon />}>
                <MenuItem onClick={() => {
                    onRename(item)
                }}>Rename</MenuItem>
                <MenuItem onClick={() => setShowTemplate(true)}>
                    Map a Excel
                </MenuItem>
                <CopyToClipboard value={item.handle}>
                  <MenuItem>
                      Copy Handle
                  </MenuItem>
                </CopyToClipboard>
                <CopyToClipboard value={headers}>
                  <MenuItem>
                      Copy Headers
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem onClick={() => {
                    deleteObject({systemCode : item.systemCode, type : item.type}).then(() => alert("Deletion Successful."))
                }}>Delete</MenuItem>

            </DropDownMenu>
    </>
}

function TemplateEdit({item, onClose}) {
  const [handle, setHandle] = useState();
  const [showError, setShowError] = useState();
  const {editObject} = useFileSystem();
  const {t} = useTranslation();

  function handleSubmit() {
      if(handle) {
          editObject({url : '/product/binder/import', attachmentHandle: handle, systemCode : item.systemCode, type : item.type})
          setShowError(false)
      }
      else {
          setShowError(true)
      }
  }

  return <DialogBox
          open={true}
          title={'Provide Template'}
          description={
            <TextField
              label={t('handle')}
              helperText="Please provide a valid handle"
              error={showError}
              value={handle}
              size='small'
              onChange={e => setHandle(e.target.value)}
            />
          }
          handleClose={() => onClose()}
          buttons={[
            <Button key={'Yes'} onClick={() => handleSubmit()}>
              {t('save')}
            </Button>,
            <Button key={'No'} onClick={() => onClose()}>
              {t('cancel')}
            </Button>,
          ]}
        />
}