import { createContext, useState } from "react";
import useHttp from "hooks/useHttp"
// CUSTOM COMPONENT

const initialState = {
  loading : false,
  items : [],
  totalPages : 0,
  totalCount : 0,
  currentPage : 1,
  afterLoad : () => {},
};

const PageableDataManagerContext = createContext({
  ...initialState,
});

export const PageableDataManagerProvider = ({ children, url, afterLoad, eventName }) => {
  const [state, setState] = useState(initialState)
  const {get} = useHttp();
  const setTotalCount = (totalCount) =>  {
    setState((prevState) => {
      return {...prevState, totalCount : totalCount}
    })
  }

  const deleteItem = (item) => {
      const deletedItemText = JSON.stringify(item);
      const buffer = state.items.filter(i => JSON.stringify(i) !== deletedItemText);
      setItems(buffer)
  }

  const loadFirstPage = (requestParams) => {
      if (!state.loading) {
          setCurrentPage(1)
          requestParams['page'] = 1
          loadData(requestParams);
      }
  }

  const loadPage = (data, page) => {
      setCurrentPage(page)
      data['page'] = page
      loadData(data, true)                
  }

  const loadData = (requestParams, removeExisting) => {
    if (!state.loading) {
        setLoading(true);
        var data = { ...requestParams };

        get(url, data)
            .then(response => {
                setTotalPages(response.pages)
                setTotalCount(response.total);
                let existing = [];
                // If page is greater than, append into existing.
                if (state.currentPage > 1 && !removeExisting) {
                    existing = state.items.splice(0);
                }

                if (response.rows) {
                  response.rows.map(c => existing.push(c));
                }
                else if(Array.isArray(response)){
                    response.map(c => existing.push(c));
                }

                setItems(existing);
                setLoading(false);

                if(afterLoad && typeof(afterLoad) === 'function') {
                    afterLoad(existing);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }
  }

  /**
   * Handler to load next page.
   */
  const nextPage = (requestParams) => {        
      if(!state.loading) {
          let p = state.currentPage ? state.currentPage : 0;
          requestParams['page'] = p + 1;
          setCurrentPage(++p);
          if (state.totalPages >= p) {    
              loadData(requestParams);
          }
      }
  };


  /**
   * Sets the items.
   */
  const setCurrentPage = (page) => {
    setState((prevState) => {
      return {...prevState, currentPage : page}
    })
  }

  /**
   * Sets the items.
   */
  const setItems = (items) => {
    setState((prevState) => {
      return {...prevState, items : items}
    })
  }

  const setLoading = (flag) => {
    setState((prevState) => {
      return {...prevState, loading : flag}
    })
  }

  const setTotalPages = (totalPages) => {
    setState((prevState) => {
      return {...prevState, totalPages : totalPages}
    })
  }

  return (
    <PageableDataManagerContext.Provider value={{ ...state, setTotalCount, deleteItem, loadFirstPage, loadPage, nextPage, setCurrentPage, setItems, setLoading, setTotalPages}}>
      {children}
    </PageableDataManagerContext.Provider>
  );
};

export default PageableDataManagerContext;
