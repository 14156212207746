import { Typography } from "@mui/material";
import { RenderHtml } from "components/html/RenderHtml";
import { useEffect } from "react";
import ReactDOM from 'react-dom';

export function HtmlBlockHtmlPreview({data}) {
    const {html, css} = data
    useEffect(() => {
        // Create a <style> tag to inject CSS
        const styleTag = document.createElement('style');
        styleTag.innerHTML = css;

        // Append the style tag to the head of the document
        document.head.appendChild(styleTag);

        // Cleanup by removing the style tag when the component unmounts
        return () => {
            if (styleTag.parentNode) {
                styleTag.parentNode.removeChild(styleTag);
            }
        };

    }, [css])

    useEffect(() => {
        // Find all target div elements
        const divs = document.querySelectorAll('div.faq-component');

        divs.forEach((div) => {
            // Replace the div content with the React component
            ReactDOM.render(<Typography>The FAQ Component has been replaced the react Component....</Typography>, div);
        });
    });

   return <RenderHtml html={html} />
}