import { Box, TextField, Typography } from "@mui/material";
import { firstColumnRightBorder, firstColumnWidth, mainTitleColor, mainTitleFontSize, menuBackgroundColor, menuPadding } from "./constants";
import SearchIcon from "@mui/icons-material/Search";

export function PropertiesFirstColumn({title, ...props}) {
    return <Box
                sx={{
                display : "flex",
                flexDirection : "column",
                backgroundColor: menuBackgroundColor,
                borderRight: firstColumnRightBorder,
                paddingTop : menuPadding,
                width : firstColumnWidth
            }}
            >
            <Box sx={{paddingX : menuPadding}}>
                <Typography
                    variant="h6"
                    sx={{
                        mb: menuPadding,
                        fontSize: mainTitleFontSize,
                        fontWeight: "bold",
                        color: mainTitleColor,
                    }}
                >
                    {title}
                </Typography>
                {/* Search Bar */}
                <TextField
                fullWidth
                variant="outlined"
                placeholder="Search"
                InputProps={{
                    startAdornment: <SearchIcon sx={{ fontSize: 18, color : 'rgba(0, 0, 0, 0.54)'}} />,
                }}
                size="small"
                sx={{ 
                    mb: 2,
                    ' & .MuiInputBaseRoot' : {
                        paddingLeft : '8px',
                    },
                    ' & .MuiInputBase-input' : {
                        p : '6px',
                    }
                    }}
                />
            </Box>
            {/* Menu Options */}
            <Box className="hide-scrollbar" sx={{
                    flex: "1 1 auto",
                    overflowY : "auto",
                }}>
                {props.children}
            </Box>
        </Box>

}