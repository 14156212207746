import { LoadingButton } from '@mui/lab';
import { Card, Grid, TextField, useTheme } from '@mui/material';
import { Paragraph } from 'components/Typography';
import { Formik } from 'formik';
import useHttp from 'hooks/useHttp';
import { SessionContentBox, SessionRootContainer } from 'layout/session';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

// initial login credentials
const initialValues = {
  password: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Password must be 6 character length').required('Password is required!'),
});

export function ForgotPasswordConfirmation() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('vt');
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const theme = useTheme();
  const { post } = useHttp();
  const validateToken = async values => {
    setLoading(true);
    const response = await post('/credential/reset', { token: token, credential: values.password });
    setLoading(false);

    if (response && response.data.errors.length > 0) {
      setVerified(false);
    } else {
      setVerified(true);
    }
  };

  return (
    <SessionRootContainer>
      <Card className='card'>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <div className='img-wrapper'>
              <img src="/assets/images/logo/121-640x460.png" alt="" />
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <SessionContentBox>
              <h2>Reset Password</h2>
              {verified ? (
                <Paragraph>
                  Congratulations, your password has been successfuly reset.
                  <NavLink to='/session/signin' style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                    Login
                  </NavLink>
                </Paragraph>
              ) : (
                <Formik onSubmit={validateToken} initialValues={initialValues} validationSchema={validationSchema}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        size='small'
                        name='password'
                        type='password'
                        label='Password'
                        variant='outlined'
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        error={Boolean(errors.password && touched.password)}
                        sx={{ mb: 1.5 }}
                      />

                      <LoadingButton type='submit' color='primary' loading={loading} variant='contained' sx={{ my: 2 }}>
                        Reset
                      </LoadingButton>

                      <Paragraph>
                        Already have an account?
                        <NavLink to='/session/signin' style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                          Login
                        </NavLink>
                      </Paragraph>
                    </form>
                  )}
                </Formik>
              )}
            </SessionContentBox>
          </Grid>
        </Grid>
      </Card>
    </SessionRootContainer>
  );
}
