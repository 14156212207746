import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const setup = { 
    name : 'Setup',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'About',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Addresses',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Locations',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Payments',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Legal',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Contact Us',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Messaging',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }