import RequestPath from "common/RequestPath";
const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+[\w.,@?^=%&:/~+#-]*$/;

/**
 * Utility for URLs.
 */
export default class UrlUtil {
  /**
     * Gets whether the first value contains second value.
     * @param first  The first value.
     * @param second  The second value.
     */
  static isUrl(str) {
    return str && urlRegex.test(str);;
  }
  
  /**
   */
  static getCompleteUrl(url) {
    return url ? RequestPath.DOMAIN + url : "";
  }

  /**
   * Gets the panel url.
   * @param url The url to convert into panel url.
   * @returns A panale url.
   */
  static getPanelUrl(url) {
    if(RequestPath.DOMAIN.endsWith("/")) {
      if(url.startsWith("/")) {
        url = url.substring(1);
      }
    } else {
      if(!url.startsWith("/")) {
        url = "/" + url;
      }
    }
    url = RequestPath.DOMAIN + url;
    // const appState = AppStateHolder.appState;

    // if(appState) {
    //   if (!url.includes("ou=")) {
    //     if (url.includes("?")) {
    //       url = url + "&ou=" + appState.panelCode
    //     }
    //     else {
    //       url = url + "?ou=" + appState.panelCode
    //     }
    //   }

    // }  
    return url;
  }

  /**
   * Gets the panel url.
   * @param url The url to convert into panel url.
   * @returns A panale url.
   */
  static getInternalPanelUrl(url) {
    // const appState = AppStateHolder.appState;

    // if (!url.includes("ou=")) {
    //   if (url.includes("?")) {
    //     url = url + "&ou=" + appState.panelCode
    //   }
    //   else {
    //     url = url + "?ou=" + appState.panelCode
    //   }
    // }

    return RequestPath.PATH_PREFIX + url;
  }

  static getUrlParts(url) {
    var a = document.createElement('a');
    a.href = url;

    return {
        href: a.href,
        host: a.host,
        hostname: a.hostname,
        port: a.port,
        pathname: a.pathname,
        protocol: a.protocol,
        hash: a.hash,
        search: a.search
    };
  }

  static isAbsoluteUrl(url) {
    // Regular expression to match absolute URLs
        const absoluteUrlRegex = /^(?:https?|ftp):\/\/.+/i;
        return absoluteUrlRegex.test(url);
  }

  static isImageUrl(url) {
    const extension = url.split('.').pop().toLowerCase();

    // Check if the file extension indicates an image format
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension);
  }
}