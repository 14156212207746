import useFileSystem from "hooks/filesystem/useFileSystem";
import { useEffect, useState } from "react";
import { HtmlBlockHtmlPreview } from "./HtmlBlockHtmlPreview";
import { HtmlBlockTemplatePreview } from "./HtmlBlockTemplatePreview";

export function HtmlBlockPreview({ item }) {
    const [data, setData] = useState({
        html : "",
        css : ""
    });
    const [template, setTemplate] = useState({});
    const {loadObject} = useFileSystem();

    useEffect(() => {
        loadObject({url : "/block/html/live", systemCode : item.systemCode, type: "HTML_BLOCK", handle : item.handle}).then(res => {
                setData({
                    html : res.overview,
                    css : res.css           
                })
                setTemplate(JSON.parse(res.jsonString))
            })
    }, [])

    return template 
    ? <HtmlBlockTemplatePreview data={template}/>
    : <HtmlBlockHtmlPreview data={data}/>
}