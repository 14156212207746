import { useState } from "react";
import axios from 'axios';

export function OllamaExample() {
    const [value, setValue] = useState();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    
    function onSubmit() {
        if(!loading) {
            setLoading(true)
            axios.post("http://65.1.111.47:11434/api/generate", {
                "model": "stablelm2",
                "prompt": value,
                "stream": false
            }).then(({data}) => {
                setLoading(false)
                setResponse(data.response)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    return <>
        <textarea onChange={(e) => setValue(e.target.value)}>
            {value}
        </textarea>
        <button onClick={onSubmit}>Submit</button>
        {loading && "Preparing Your Response..."}
        <div>
            {!loading && response}
        </div>
    </>
}