import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getColor, menuItemFontSize, menuItemIconBorder, menuItemIconBorderValue, menuItemIconMinHeight, menuItemIconMinWidth, menuItemIconPaddingLeft, menuItemIconPaddingLeftValue, menuItemLeftPadding, menuPadding } from "./constants";
import { useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from "../../../node_modules/@mui/joy/index";

export function MenuItem({item, selectedItem, onClick}) {
    const text = item.name;
    const icon = item.icon;
    const children = item.children;
    const [open, setOpen] = useState(false);
    const selected = selectedItem?.name === item.name

    const handleClick = () => {
        if(children) {
            setOpen(!open);
        }
        else {
            onClick(item)
        }
      };
    return item.section 
        ? <Typography sx={{
            paddingLeft : menuItemLeftPadding,
            paddingTop : menuItemLeftPadding,
            paddingBottom : menuItemLeftPadding,
            lineHeight: 1.5,
            fontSize: "0.6875rem",
            fontWeight: 600,
            textTransform: "uppercase",
            letterSpacing: "0.1rem",
            color: "rgb(87, 99, 117)"
        }}>{item.name}</Typography>
        : <>
        <ListItemButton
            selected={selected}
            onClick={handleClick}
            sx={{
            fontSize : menuItemFontSize,
            paddingRight : menuPadding,
            paddingLeft : menuItemLeftPadding,
            "&.Mui-selected": {
                backgroundColor: "#f1f1f1",

                "& .MuiTypography-root" : {
                    fontWeight : 600
                },
                "& .MuiSvgIcon-root" : {
                    color : getColor(item.type)
                }
            }}}>
            {<ListItemIcon sx={{
                borderLeft : selected ? menuItemIconBorder + getColor(item.type) : '',
                minWidth : menuItemIconMinWidth,
                paddingLeft : selected ? menuItemIconPaddingLeft : (menuItemIconPaddingLeftValue + menuItemIconBorderValue) + 'px',
                minHeight : menuItemIconMinHeight
            }}>
                {icon && icon}
            </ListItemIcon>}
            <ListItemText primary={text} />
            {children 
                ? open ? <ExpandLess /> : <ExpandMore />
                : <></>
            }
        </ListItemButton>
        {children && 
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    {children?.map((child, index) => <MenuItem item={child} key={index} onClick={onClick} selectedItem={selectedItem}/>)}
                </List>
            </Collapse>
        }
    </>
}