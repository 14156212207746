import { LoadingButton } from "@mui/lab";
import { Box, Card, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Paragraph } from "components/Typography";
import useAuth from "hooks/useAuth";
import { SessionRootContainer } from "layout/session";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));


// initial login credentials
const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  salutation: "",
  remember: false
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  firstName: Yup.string()
    .required("First Name is required!"),
  lastName: Yup.string()
    .required("Last Name is required!"),
    salutation: Yup.string()
    .required("Salutation is required!"),
  email: Yup.string().email("Invalid Email address").required("Email is required!"),
  remember : Yup.bool().oneOf([true], "You need to accept the terms and conditions").required("You need to accept the terms and conditions")
});

export default function Register() {
  const theme = useTheme();
  const { register } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const handleFormSubmit = (values) => {
    setLoading(true);

    try {
      register({
        "principal" : values.email,
        "firstName" : values.firstName,
        "lastName" : values.lastName,
        "password" : values.password,
        "salutation" : values.salutation,
        "source" : "UI"
      }).then((response) => {
        if(response && response.data && response.data.errors.length > 0)
        {
            // Do Nothing.
        }
        else {
            setRegistered(true)
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <SessionRootContainer>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img src="/assets/images/logo/121-640x460.png" alt="" />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              {registered 
              ? <Paragraph>
                  You have successfuly registered, We have sent you an email verification link on your registered email id. Please click on the link and enter the credentials used for registeration to verify your email.
                  <NavLink
                    to="/session/signin"
                    style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                    Login
                  </NavLink>
                </Paragraph>
              : <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    {console.log(errors)}
                    <FormControl fullWidth size="small">
                      <InputLabel id="salutation">Salutation</InputLabel>
                      <Select
                        id="salutation"
                        name="salutation"
                        value={values.salutation}
                        label="Salutation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.salutation && errors.salutation}
                        error={Boolean(errors.salutation && touched.salutation)}
                        sx={{ mb: 3}}
                      >
                        <MenuItem value={"Mr."}>Mr.</MenuItem>
                        <MenuItem value={"Ms."}>Ms.</MenuItem>
                        <MenuItem value={"Mrs."}>Mrs</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.firstName}
                      onChange={handleChange}
                      helperText={touched.firstName && errors.firstName}
                      error={Boolean(errors.firstName && touched.firstName)}
                      sx={{ mb: 3}}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.lastName}
                      onChange={handleChange}
                      helperText={touched.lastName && errors.lastName}
                      error={Boolean(errors.lastName && touched.lastName)}
                      sx={{ mb: 3}}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 2 }}
                    />

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="remember"
                        onChange={handleChange}
                        checked={values.remember}
                        helperText={touched.remember && errors.remember}
                        error={Boolean(errors.remember && touched.remember)}
                        sx={{ padding: 0 }}
                      />

                      <Paragraph fontSize={13} sx={{color : errors.remember ? "#d32f2f" : "initial"}}>
                        I have read and agree to the terms of service.
                      </Paragraph>
                    </FlexBox>

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ mb: 2, mt: 3 }}>
                      Register
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/session/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
              }
            </Box>
          </Grid>
        </Grid>
      </Card>
    </SessionRootContainer>
  );
}
