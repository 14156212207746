import { AppBar } from '@mui/material';
import { headerHeight } from './constants';
import { headerColor } from './constants';
export function HeaderContainer(props) {
  return (
    <AppBar className='app-header' sx={{ height: headerHeight, backgroundColor: headerColor }}>
      {props.children}
    </AppBar>
  );
}
