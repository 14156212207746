import CircularProgress from '@mui/material/CircularProgress';
import useFileUpload from "hooks/filesystem/useFileUpload";
import { useDropzone } from "react-dropzone";

// Enable retry logic for axios


const FolderUpload = () => {

  const {setFiles, uploadProgress} = useFileUpload();
  const onDrop = (acceptedFiles) => {
    const organizedFiles = [];
    acceptedFiles.forEach((file) => {
      const path = file.path || file.webkitRelativePath;
      organizedFiles.push({ file, path });
    });
    setFiles(organizedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()} style={{ border: "2px dashed #aaa", padding: "20px" }}>
        <input {...getInputProps()} webkitdirectory="true" mozdirectory="true" directory />
        <p>Drag & Drop folder here or click to browse</p>
      </div>
      <CircularProgress variant="determinate" value={Math.round(uploadProgress)} />
      {/* {Object.keys(fileUploadProgress).map((obj) => <div>
        <p>{obj} : {Math.round(fileUploadProgress[obj])}%</p>
      </div>)} */}
    </div>
  );
};

export default FolderUpload;
