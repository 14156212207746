import { AppContentDataContainerBoxWithTabs, AppContentInnerBox } from 'components/Box/Box';
import { SideDrawer } from 'components/Drawer/Drawer';
import { AppContentSearchableHeader } from 'components/Header/Header';
import { HorizontalTab, UiTabContext, UiTabPanel } from 'components/Tabs';
import { AppContentMainDataFrame } from 'components/application-content/AppContentMainDataFrame';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtil from 'utils/StringUtil';
import AddressCardGrid from 'views/account/address/AddressCardList';
import AddressTableList from 'views/account/address/AddressTableList';
import { AddressDetail } from './AddressDetail';
import { AddButton } from 'components/Buttons/ActionButtons';
const PAGE_URL = '/account/address/page';

export default function AddressList() {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState(null);
  const [systemCode, setSystemCode] = useState();
  const [details, setShowDetails] = useState();
  const [showCardView] = useState(true);

  function showDetails(address) {
    if (address && StringUtil.isNotBlank(address.systemCode)) {
      setSystemCode(address.systemCode);
    } else {
      setSystemCode(null);
    }
    setShowDetails(true);
  }

  const allTabData = {
    keywords: keywords,
  };

  const tabs = [<HorizontalTab label='All' value='all' key='all' />];
  const headerButtons = [<AddButton onClick={() => showDetails()} />];
  return (
    <>
      <SideDrawer anchor='right' rightToolBarId={'location-' + systemCode} headerTitle={t('addresses')} open={details} onClose={() => setShowDetails(false)}>
        <AddressDetail systemCode={systemCode} rightToolBarId={'location-' + systemCode} />
      </SideDrawer>
      <AppContentInnerBox>
        <AppContentSearchableHeader buttons={headerButtons} itemNameToSearch={t('address')} setKeywords={setKeywords} onSearch={() => {}} />
        <AppContentMainDataFrame>
          <UiTabContext tabs={tabs} defaultTab={'all'}>
            <UiTabPanel value='all'>
              <PageableDataManagerProvider url={PAGE_URL}>
                <AppContentDataContainerBoxWithTabs>{showCardView ? <AddressCardGrid data={allTabData} showDetails={showDetails} /> : <AddressTableList data={allTabData} showDetails={showDetails} />}</AppContentDataContainerBoxWithTabs>
              </PageableDataManagerProvider>
            </UiTabPanel>
          </UiTabContext>
        </AppContentMainDataFrame>
      </AppContentInnerBox>
    </>
  );
}
