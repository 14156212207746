import { Box } from '@mui/material';
import useTheme from 'contexts/Theme/ThemeContext';
import usePageableDataManager from 'hooks/datamanager/usePageableDataManager';
import { useEffect, useState } from 'react';

export function PagebaleCardGrid280(props) {
  const { data, getCard, sx } = props;
  const { items, loadPage, totalCount } = usePageableDataManager();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  const { theme } = useTheme();

  useEffect(() => {
    loadPage({ ...data, pageSize: pageSize, rows: pageSize }, 0);
  }, [data]);

  return (
    <Box
      className='item-card-grid'
      component='div'
      sx={{
        display: 'grid',
        columnGap: theme.appContentCssVars.appContentCardColGap,
        rowGap: theme.appContentCssVars.appContentCardRowGap,
        gridTemplateColumns: theme.appContentCssVars.appContentCardGridTemplateColumns,
        ...sx,
      }}>
      {items.map((item, index) => {
        return getCard(item, index);
      })}
    </Box>
  );
}
