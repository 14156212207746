import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { Typography } from '@mui/material';
import { Properties } from '../../components/properties/properties';
import { activityCodes } from './activity-codes';
import { bookkeeping } from './bookkeeping';
import { commercial } from './commercial';
import { consolidation } from './consolidation';
import { hierarchy } from './hierarchy';
import { legal } from './legal';
import { marketing } from './marketing';
import { networks } from './networks';
import { operations } from './operations';
import { planning } from './planning';
import { recordKeeping } from './record-keeping';
import { sales } from './sales';
import { setup } from './setup';
import { structure } from './structure';

const membersMenu = [
  {
    name : 'Company',
    section : true,
  }, 
  {...structure}, 
  {...setup}, 
  {...recordKeeping}, 
  {...bookkeeping}, 
  {...marketing}, 
  {...sales}, 
  {...commercial}, 
  {...operations}, 
  {...planning}, 
  {...activityCodes}, 
  {...networks}, 
  {...legal}, 
  {
    name : 'Group',
    section : true,
  }, 
  {...hierarchy}, 
  {...consolidation}, 
  {
    name : 'More',
    section : true,
  }, 
  {
    name : "Intranets",
    icon : <PeopleAltIcon />,
  }, 
  {
    name : "Learning / Training",
    icon : <PeopleAltIcon />,
  },
  { name : 'Vault',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Inbound Data Rules',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        },
        {
            name : 'Sub-Drive',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        },
        {
            name : 'Binders',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Admin Center',
    icon : <PeopleAltIcon />,
    component : <><Typography>Open the Properties Window</Typography></>
  }, 
]
  
export function Bizmo() {
    return <Properties menu={membersMenu} title={"Bizmo"}/>
}