import { createContext, useEffect, useState } from "react";
import { ObjectUtil } from "utils/ObjectUtil";
import useHttp from "hooks/useHttp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FileUploadProvider } from "./FileUploadContext";

const initialState = {
    familyId: null,
    familyHeadType: null,
    familyHeadCode : null,
    parentType : null,
    parentSystemCode : null,
    familyHeadScope : null,
    driveCode : null,
    currentDirectory : null,
};


export const FileSystemContext = createContext({
    ...initialState,
});

export const FileSystemProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const {get, post, postMultipart, download} = useHttp();
    const {driveCode, driveName, ...remainingParams} = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        let levels = remainingParams["*"]

        if(levels) {
            levels = levels.split("/");

            const breadcrumbsBuffer = []
            levels.forEach((level, index) => {
                const directoryParts = level.split(":");
                const directory = {
                    systemCode : directoryParts[0],
                    type : directoryParts[1],
                    name : directoryParts[2],
                    root : index == 0
                }
                
                breadcrumbsBuffer.push(directory);
                updateFieldValue("currentDirectory", directory);

                if(directory.root) {
                    updateFieldValue("familyHeadCode", directory.systemCode)
                    updateFieldValue("familyHeadType", directory.type)
                }        
            });

            setBreadCrumbs(breadcrumbsBuffer)
        }
        else {
            updateFieldValue("currentDirectory", null);

            updateFieldValue("familyHeadCode", null)
            updateFieldValue("familyHeadType", null)
            setBreadCrumbs([])
        }
        
        setInitialized(true)
    }, [pathname]);

    const updateFieldValue = (field, value) => {
        setState((prevState) => {
            return {...prevState, [field] : value}
        })
    }

    function checkIfObjectTypeExist(data) {
        if(!data.type) {
            throw new Error("Type is not present..")
        }
    }
    function checkIfObjectSystemCodeExist(data) {
        if(!data.systemCode) {
            throw new Error("SystemCode is not present..")
        }
    }
    function checkIfObjectSystemCodeOrHandleExist(data) {
        if(!data.systemCode && !data.handle) {
            throw new Error("Neither SystemCode or Handle is present..")
        }
    }

    const addObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            parentObjectCode : state.currentDirectory?.systemCode,
            parentObjectType : state.currentDirectory?.type,
            ...data
        })
    }

    const addMultipartObject = ({url, files, ...data}) => {
        checkIfObjectTypeExist(data)

        return postMultipart(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        }
        , files)
    }

    const editObjectName = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            parentObjectCode : state.currentDirectory?.systemCode,
            parentObjectType : state.currentDirectory?.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    }

    const editObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    }

    const deleteObject = (data) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return post("/file-object/delete", {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        }).then(() => 
            // Intionally updated object, so that the Directory List gets refreshed.
            setCurrentDirectory({...state.currentDirectory})
        )
    }

    const getObjectRequestParams = () => {
        return ObjectUtil.deepMerge({}, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
        })
    }

    const loadMeta = ({url, ...data}) => {
        checkIfObjectSystemCodeOrHandleExist(data)
        return get(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    };

    const loadObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeOrHandleExist(data)
        return get(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    };

    const downloadObject = async ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return download(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    };

    const loadObjects = ({systemCode, parentObjectCode}) => {
        return get("/file-object/page", {
            systemCode : systemCode || state.familyHeadCode,
            driveCode : driveCode,
            scope : state.familyHeadScope,
            familyHeadType : state.familyHeadType,
            familyHeadCode : state.familyHeadCode,
            parentObjectCode : parentObjectCode,
            type : state.familyHeadType
        })
    }

    const setCurrentDirectory = (directory) => {
        updateFieldValue("currentDirectory", directory);

        if(directory.root) {
            updateFieldValue("familyHeadCode", directory.systemCode)
            updateFieldValue("familyHeadType", directory.type)
        }

        let buffer = [...breadcrumbs]

        buffer.push(directory)
        
        setBreadCrumbs(buffer)    

        navigate(`${pathname}/${directory.systemCode}:${directory.type}:${directory.name}`)
    }

    const getMandatoryRequestParams = () => {
        return {
            driveCode : driveCode,
            scope : state.familyHeadScope,
            familyHeadType : state.familyHeadType,
            familyHeadCode : state.familyHeadCode,
            parentObjectCode : state.currentDirectory?.systemCode, 
            parentObjectType : state.currentDirectory?.type 
        }
    }

    const goToBreadcrumb = (index) => {
        let levels = remainingParams["*"]

        if(levels) {
            levels = levels.split("/").splice(0, index + 1);
            levels = levels.join("/")
            
            navigate(`/folders/${driveCode}/${driveName}/${levels}`)
        }
    }

    return (
        <FileSystemContext.Provider value={{ ...state, breadcrumbs,  updateFieldValue, addObject, editObjectName, editObject, loadObject, loadObjects, getObjectRequestParams, addMultipartObject, getMandatoryRequestParams, setCurrentDirectory, downloadObject, goToBreadcrumb, deleteObject, loadMeta}}>
            {initialized && <FileUploadProvider>
                {children}
                </FileUploadProvider>
            }
        </FileSystemContext.Provider>
        );    
};
  

