import { FileUtil } from 'utils/FileUtil';

export default class NavService {
  static async getNavItems(panelType) {
    if (panelType.items) {
      return panelType.items;
    }

    const data = FileUtil.readJsonFile(`/assets/nav/${panelType.fileName.toLowerCase()}.json`);
    return data.items || [];
  }
}
