import { Button, Typography } from '@mui/material';

export function ListingCardItemHeaderLine(props) {
  const { children, ...other } = props;
  return (
    <ListingCardItemLine color='textPrimary' variant='h6' {...other}>
      {children}
    </ListingCardItemLine>
  );
}

export function ListingCardItemSecondaryLine(props) {
  const { children, ...other } = props;
  return (
    <div>
      <Typography variant='body1' sx={{ fontSize: '1rem', lineHeight: '1.2', color: '#0f1111' }} {...other}>
        {children}
      </Typography>
    </div>
  );
}

export function ListingCardItemButton(props) {
  const { label, onClick } = props;

  return (
    <Button variant='text' size='small' onClick={onClick} sx={{ minWidth: '0px', textTransform: 'none', fontSize: '13px', fontWeight: '400' }}>
      {label}
    </Button>
  );
}

export function ListingCardItemLine(props) {
  const { children, ...other } = props;
  return (
    <div>
      <Typography variant='body1' sx={{ fontSize: '1rem', lineHeight: '1.2', color: '#0f1111' }} {...other}>
        {children}
      </Typography>
    </div>
  );
}
