import { Box } from '@mui/material';
import useTheme from 'contexts/Theme/ThemeContext';

export function AppContentMainDataFrame(props) {
  const { sx, children } = props;
  const { theme } = useTheme();

  return (
    <Box
      component='div'
      className='applistframe application-main-data-frame'
      sx={{
        height: theme.appContentCssVars.appContentDataFrameHeight,
        ...sx,
      }}>
      {children}
    </Box>
  );
}
