import React, { useState } from 'react';
import './search.scss';

/**
 * A search component.
 */
function Search(props) {
  const [searchDisplayed, setSearchDisplayed] = useState(false);

  /**
   * Toggles search.
   */
  function toggleSearch() {
    setSearchDisplayed(!searchDisplayed);

    if (searchDisplayed) {
      props.setKeywords(null);
    }
  }

  /**
   * Handles search.
   */
  function handleSearch(event) {
    let keywords = event.target.value;
    props.setKeywords(keywords);
  }

  return (
    <React.Fragment>
      {searchDisplayed ? (
        <div
          style={{
            flex: '1 0 auto',
            padding: '0 2px',
            fontWeight: 600,
          }}>
          <input type='text' autoFocus='autoFocus ' placeholder='Search' className='search' onChange={handleSearch}></input>
        </div>
      ) : (
        <div
          style={{
            flex: '1 0 auto',
            padding: '0 2px',
            fontWeight: 600,
          }}
          onClick={toggleSearch}>
          {props.title}
        </div>
      )}
      <div
        style={{
          width: '36px',
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={toggleSearch}>
        {searchDisplayed ? <i className='fas fa-times'>&nbsp;</i> : <i className='fas fa-search'>&nbsp;</i>}
      </div>
    </React.Fragment>
  );
}

export default Search;
