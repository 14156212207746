import useFileSystem from "hooks/filesystem/useFileSystem";
import { useEffect, useState } from "react";
import { HtmlBlockPreview } from "views/block/html/HtmlBlockPreview";
import { RenderHtml } from "./RenderHtml";
import StringUtil from "utils/StringUtil";

export function AsyncHtml({data}) {
    const [jsonData, setJsonData] = useState();
    const {loadObject} = useFileSystem();
    const [className] = useState("class-" + StringUtil.getRandomString().replace(".", ""))

    function handleCss(css) {
        // Create a <style> tag to inject CSS
        const styleTag = document.createElement('style');
        styleTag.className = className;
        styleTag.innerHTML = css;
    
        // Append the style tag to the head of the document
        document.head.appendChild(styleTag);
    
        // Cleanup by removing the style tag when the component unmounts
        return () => {
            if (styleTag.parentNode) {
                styleTag.parentNode.removeChild(styleTag);
            }
        };
    }

    function cleanup() {
        const elements = document.querySelectorAll("." + className)
        if(elements) {
            elements.forEach((el) => el.remove());
        }
    }
    
    useEffect(() => {
        if(data?.data) {
            try {
                const json = JSON.parse(data.data)
                setJsonData(json)
            }
            catch {
                // Do Nothing.
            }
        }

        if(data?.css && data?.css.length > 0) {
            const handles = data?.css.map(c => c.cssHandle)

            if(handles && handles.length > 0) {
                handles.forEach(handle => {                    
                    loadObject({url : "/attachment/download", handle : handle, type : "ATTACHMENT_ARTIFACT"})
                    .then(res => {
                        handleCss(res)
                    })        
                });
            }

            let css = data?.css.map(c => c.css);
            if(css && css.length > 0) {
                css = css.join("\n")
            }

            handleCss(css);
        }

        return () => {
            cleanup()
        }
    }, [data])

    if(data && data.html) {
        return <RenderHtml html={data.html} data={jsonData} meta={data}/>
    }
    else if(data && data.htmlHandle) {
        return <LoadHtmlByHandleAndRender handle={data.htmlHandle} jsonData={jsonData} meta={data}/>
    }
    else if(data && data.htmlBlockHandle)
    {
        return <HtmlBlockPreview item={{
            handle : data.htmlBlockHandle,
        }}/> 
    }
}

function LoadHtmlByHandleAndRender({handle, jsonData, ...others}) {
    const {loadObject} = useFileSystem()
    const [html, setHtml] = useState();
    useEffect(() => {
        loadObject({url : "/attachment/download", handle : handle, type : "ATTACHMENT_ARTIFACT"})
        .then(res => {
            setHtml(res)
        })
    }, [handle])
    return html && <RenderHtml html={html} data={jsonData} {...others}/>
}