import AsyncPageableSimpleSortingTable from 'components/tables/AsyncPageableSimpleSortingTable';
import { ScreenUtil } from 'utils/ScreenUtil';
import { forwardRef, useEffect, useState } from 'react';
import { ClickableNuggetMain, Initials } from 'components/object-list/Nuggets/Nugget';
import { PageableOnScrollList } from '../nugget-list/PageableOnScrollList';

const ObjectList = forwardRef(({ ...props }, ref) => {
  const { headCells, initials, onRowClick, nuggetTemplate, actionMenu } = props;
  const [nuggetFields, setNuggetFields] = useState([]);
  useEffect(() => {
    const sortedData = [...headCells]
      .sort((a, b) => a.nuggetInfo.order - b.nuggetInfo.order)
      .map(item => ({
        type: item.nuggetInfo.type,
        id: item.id,
        formatter: item.formatter,
      }));

    setNuggetFields(sortedData);
  }, [headCells]);

  /**
   * Nugget template
   */
  function getNuggetTemplate(item) {
    if (nuggetTemplate && typeof nuggetTemplate === 'function') {
      return nuggetTemplate(item);
    }
    return (
      <div className={item.focused ? 'rootnugget appactive' : 'rootnugget'} key={item.systemCode}>
        <ClickableNuggetMain item={item} onClick={() => onRowClick(item)}>
          <div>
            <Initials text={item[initials.text]} id={item[initials.id]} />
            <div className='nugget__rootclick-appmain'>
              {nuggetFields.map((f, index) => {
                return (
                  <div className={'nugget-' + f.type} key={index}>
                    {f.formatter ? f.formatter(item) : item[f.id]}
                  </div>
                );
              })}
            </div>
          </div>
        </ClickableNuggetMain>
        {actionMenu && <div className='nugget-after'>{actionMenu(item)}</div>}
      </div>
    );
  }

  return ScreenUtil.isMobile(ref?.current) ? (
    <div className='application-main-data-container'>
      <PageableOnScrollList {...props} nuggetTemplate={getNuggetTemplate} />
    </div>
  ) : (
    <div className='application-main-data-container'>
      <AsyncPageableSimpleSortingTable {...props} />
    </div>
  );
});

export default ObjectList;
