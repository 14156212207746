import { LabelledBox } from "components/Box/Box";
import { SideDrawer } from "components/Drawer/Drawer";
import { DataForm } from "components/Form/Form";
import { FormInput } from "components/Form/FormInputs";
import useHttp from "hooks/useHttp";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import StringUtil from "utils/StringUtil";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoleDetails } from "./RoleDetails";

export function RolesTab(props) {
    const { rolesUrl, systemCode, panel } = props;
    const [roleInfo, setRoleInfo] = useState(null);
    const [sideDrawerDisplayed, setSideDrawerDisplayed] = useState(false);
    const [roles, setRoles] = useState([])
    const { t } = useTranslation();
    const {get} = useHttp();

    function handleRoleClick(role) {
        setRoleInfo(role)
        setSideDrawerDisplayed(true)
    }
    const url = rolesUrl + systemCode
   
    useEffect(() => {
        get(url).then(res => setRoles(res.roles || []))
    }, [url])

    function unassignRole(role) {
        setRoles(prevRoles => 
            prevRoles.map(item => 
              item.systemCode === role.systemCode ? { ...item, assigned: false } : item
            )
          );
    }

    function assignRole(role) {
        setRoles(prevRoles => 
            prevRoles.map(item => 
              item.systemCode === role.systemCode ? { ...item, assigned: true } : item
            )
          );
    }

    function getAssignedRoles(keywords) {
        return roles.filter(role => role.assigned && (StringUtil.isBlank(keywords) || StringUtil.containingIgnoreCase(role.name, keywords)))
    }

    function getUnassignedRoles(keywords) {
        return roles.filter(role => !role.assigned && (StringUtil.isBlank(keywords) || StringUtil.containingIgnoreCase(role.name, keywords)))
    }

    return ( <>
            {roleInfo && <SideDrawer anchor="right" headerTitle={t("role") + " :: " + roleInfo.name} open={sideDrawerDisplayed} onClose={() => setSideDrawerDisplayed(false)}>
                <RoleDetails systemCode={roleInfo.systemCode} />
            </SideDrawer>
            }
            <LabelledBox label="Roles" collapsed>
                <UnassignedRoles onRoleClick={handleRoleClick} roles={(keywords) => getUnassignedRoles(keywords)} onIconClick={assignRole} {...props} />
            </LabelledBox>
            <DataForm action={url} id="accessRightForm">
                <LabelledBox label="Assigned Roles">
                    <AssignedRoles onRoleClick={handleRoleClick} roles={(keywords) => getAssignedRoles(keywords)} onIconClick={unassignRole} {...props} />
                </LabelledBox>
            </DataForm>
        </>
    )
}

function AssignedRoles(props) {

    function renderFormFields(role, index) {
        return <input name="roles" type="hidden" value={role.systemCode} />
    }

    return <Roles {...props} icon={<DeleteIcon />}  renderFormFields={renderFormFields} />
}

function UnassignedRoles(props) {
    return <Roles {...props} icon={<AddIcon />} />
}


function Roles(props) {
    const { icon, onIconClick, roles, renderFormFields, onRoleClick } = props
    const [keywords, setKeywords] = useState("");

    return  <div>
                <FormInput label="Search" value={keywords} onValueChange={e => setKeywords(e.target.value)} />
                <div style={{ overflowY: 'scroll', maxHeight: "500px" }}>
                    {roles(keywords).map((role, index) => <React.Fragment key={role.systemCode}>
                        {renderFormFields && renderFormFields(role, index)}
                        <ListItem>
                            <ListItemIcon onClick={() => onIconClick(role)} sx={{ cursor: 'pointer' }}>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={role.name} sx={{ cursor: "pointer" }} onClick={() => onRoleClick(role)} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>)
                    }
                </div>
            </div>
}