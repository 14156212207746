import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, IconButton } from '@mui/material';
import { AppContentHeaderButtonBox } from 'components/Buttons/ActionButtons';
import { SideDrawer } from 'components/Drawer/Drawer';
import { DropDownMenu } from 'components/Menu/Menu';
import FilterComponent from 'components/data-filter/FilterComponent';
import Search from 'components/object-list/Search/Search';
import useTheme from 'contexts/Theme/ThemeContext';
import { useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';
export function HeaderWithLabelMenu(props) {
  const { leftToolSet, rightToolSet, menuItems, sx } = props;

  function getRightToolSet() {
    if (rightToolSet) {
      return rightToolSet;
    } else {
      return CollectionUtil.isNotBlank(menuItems) ? <DropDownMenu> {menuItems} </DropDownMenu> : <></>;
    }
  }

  return (
    <div className='appvertabs-frame-header' style={{ marginBottom: '10px', ...sx }}>
      <div className='appvertabs-title'>
        <div className='toolbar'>
          <div className='grid-header left-tool-set'>{leftToolSet ? leftToolSet : <></>}</div>
          <div className='grid-header right-tool-set'>{getRightToolSet()}</div>
        </div>
      </div>
    </div>
  );
}

export function ApplContentHeader(props) {
  const { sx, children } = props;
  const { theme } = useTheme();

  return (
    <Box
      component='header'
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        borderBottom: '1px solid #555',
        height: theme.appContentCssVars.appContentHeaderHeight,
        fontSize: '14px',
        lineHeight: '35px',
      }}>
      {children}
    </Box>
  );
}

export function AppContentSearchableHeader(props) {
  const { buttons, itemNameToSearch, setKeywords, onDataFilter } = props;
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <SideDrawer
        anchor='right'
        rightToolBarId={'data-filters'}
        headerTitle={'Filters'}
        open={showFilters}
        onClose={() => {
          setShowFilters(false);
          onDataFilter && onDataFilter();
        }}>
        <FilterComponent />
      </SideDrawer>
      <ApplContentHeader>
        {buttons && buttons.map(button => <AppContentHeaderButtonBox>{button}</AppContentHeaderButtonBox>)}
        <Search title={itemNameToSearch} setKeywords={setKeywords} />
        <IconButton onClick={() => setShowFilters(true)}>
          <FilterListIcon />
        </IconButton>
      </ApplContentHeader>
    </>
  );
}
