import StringUtil from "utils/StringUtil";

/**
 * User Navigation component.
 */
export default class PanelNuggetHelper {

  
  /**
   * An event listener to monior changes in elements of this component.
   * @param changes Changes in the elements.
   */
  static filterPanels(panels, keywords) {

    if (keywords && keywords !== "") {
      return panels.filter(panel => {
        return StringUtil.containingIgnoreCase(panel.name, keywords)
          || StringUtil.containingIgnoreCase(panel.virtualId, keywords)
          || StringUtil.containingIgnoreCase(panel.type.name, keywords)
          || StringUtil.containingIgnoreCase(panel.code, keywords)
          || StringUtil.containingIgnoreCase(panel.shortCode, keywords)
      });
    }
    else {
      return panels;
    }
  }
}
