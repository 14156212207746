import { Box } from '@mui/material';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import useHttp from 'hooks/useHttp';
import { useEffect, useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';
import { FileUtil } from 'utils/FileUtil';
import StringUtil from 'utils/StringUtil';
import { FormFileSelect } from '../Form/FormInputs';
const XLSX = require('xlsx');

export default function AppDataGrid(props) {
  const { columnDef, initialRowCount = 5, onDataChange, url, urlData } = props;
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [fieldId, setFieldId] = useState();
  const { download, downloadFile } = useHttp();

  function showBlankGrid() {
    if (!columnDef) return;
    // Create header row
    const headerRow = {
      rowId: 'header', // Use a unique identifier for the header row
      cells: columnDef.map(col => ({
        columnId: col.columnId,
        type: 'header', // Specify the type as 'header' for styling (ReactGrid supports custom styling for headers)
        text: col.name || '', // Use column name for header text
      })),
    };

    // Create blank data rows
    const dataRows = Array.from({ length: initialRowCount }, (_, rowIndex) => ({
      rowId: rowIndex,
      cells: columnDef.map(col => ({
        columnId: col.columnId,
        type: 'text', // Regular text type for data rows
        text: '',
      })),
    }));

    // Combine header and data rows
    const rows = [headerRow, ...dataRows];

    setGridRows(rows);
    setGridColumns(columnDef);
  }

  useEffect(() => {
    setFieldId('file-' + StringUtil.generateRandomNumberString(10));
    if (StringUtil.isNotBlank(url)) {
      download('/account/location/area', urlData).then(response => {
        const blob = response.data;
        // Convert Blob to ArrayBuffer
        blob.arrayBuffer().then(arrayBuffer => {
          const json = FileUtil.readExcelData(arrayBuffer);
          if (json && json.length > 0) {
            fillRowsAndColumnInfo(json);
          } else {
            showBlankGrid();
          }
        });
      });
    } else {
      showBlankGrid();
    }
  }, []);

  function handleXlsx(files) {
    const file = files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const json = FileUtil.readExcelData(data);
        fillRowsAndColumnInfo(json);
        const file = FileUtil.toExcelFile(json);
        setAsTargetFile(file);
      };

      reader.readAsArrayBuffer(file);
    }
  }

  function fillRowsAndColumnInfo(json) {
    const tempSheetColumns = Object.keys(json[0]).map(key => ({ text: key, type: 'header' }));
    const tempGridColumns = transformToGridColumns(tempSheetColumns);
    setGridRows(transformToGridRows(json, tempSheetColumns));
    setGridColumns(tempGridColumns);
  }

  function setAsTargetFile(file) {
    onDataChange(file, gridRows, gridColumns);
  }

  const transformToGridColumns = c => [
    ...c.map(c => {
      return {
        columnId: c.text,
        width: 150,
        resizable: true,
      };
    }),
  ];

  const transformToGridRows = (sd, sheetColumns) => [
    {
      rowId: 'header',
      cells: sheetColumns,
    },
    ...sd.map((data, idx) => ({
      rowId: idx,
      cells: Object.keys(data).map(key => ({ text: '' + data[key], type: 'text', columnId: key })),
    })),
  ];

  function hasSheetData() {
    return CollectionUtil.isNotBlank(gridRows) && CollectionUtil.isNotBlank(gridColumns);
  }

  // Handle cell changes
  const handleChanges = changes => {
    const updatedRows = gridRows.map(row => {
      const updatedCells = row.cells.map(cell => {
        const change = changes.find(change => change.rowId === row.rowId && change.columnId === cell.columnId);
        return change ? { ...cell, ...change.newCell } : cell;
      });
      return { ...row, cells: updatedCells };
    });
    setGridRows(updatedRows);
    const file = FileUtil.toExcelFile(gridRows);
    setAsTargetFile(file);
  };

  return (
    <Box>
      <Box sx={{ overflowX: 'auto', maxWidth: ' 100%' }}>{hasSheetData() && <ReactGrid rows={gridRows} columns={gridColumns} onCellsChanged={handleChanges} />}</Box>
      <Box sx={{ marginTop: '10px' }}>
        <FormFileSelect onSelect={handleXlsx} name={'file-' + StringUtil.getRandomString(10)} />
      </Box>
    </Box>
  );
}
