import { ListItemText, MenuItem, MenuList } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';
import { ScreenTypeEnum, ScreenUtil } from 'utils/ScreenUtil';
import { SlidingBox } from 'components/Box/Box';
import './apptabs.scss';

export function VerticalAsyncTabContainer(props) {
    const { activeItemIndex, items, itemHeaderLeftToolSet, itemHeaderRightToolSet, itemDetail } = props;
    const [slidingDirection, setSlidingDirection] = useState("Right");
    const [previousSelectedItemIndex, setPreviousSelectedItemIndex] = useState(activeItemIndex);
    const [showItemList, setShowItemList] = useState(true);

    function toggleSlideDirection(toggleDirection) {
        if (toggleDirection === "Right") {
            return "Left";
        } else if (toggleDirection === "Left") {
            return "Right";
        }
        console.log("Invalid sliding direction");
    }

    function toggleItemDetail() {
        setSlidingDirection(toggleSlideDirection(slidingDirection));
    }

    function hideItemDetail() {
        setSlidingDirection(toggleSlideDirection(slidingDirection));
        setShowItemList(true);
    }

    function selectItem(itemIndex) {
        const screenType = ScreenUtil.getScreenType();
        if (screenType === ScreenTypeEnum.MOBILE) {
            setShowItemList(false);
            setSlidingDirection("Left");
        } else {
            setShowItemList(true);
            if (itemIndex === previousSelectedItemIndex) {
                toggleItemDetail();
            } else {
                setSlidingDirection("Left");
            }
        }
        setPreviousSelectedItemIndex(itemIndex);
    }

    return <>
        <div className='appobject-body'>
            <div className='appvertabs'>
                {
                    CollectionUtil.isNotBlank(items) && showItemList &&
                    <div className="appvertabs-names" >
                        {items.map((item, itemIndex) => {
                            const isItemActive = activeItemIndex === itemIndex;
                            return isItemActive ? <div key={itemIndex} className="apptab-active-item" onClick={() => selectItem(itemIndex)}>{item}</div>
                                : <div key={itemIndex} onClick={() => selectItem(itemIndex)}>{item}</div>
                        }
                        )}
                    </div>
                }
                {
                    CollectionUtil.isNotBlank(items) && <div className="appvertabs-frame" >
                        <SlidingBox slidingDirection={slidingDirection}>
                            {
                                activeItemIndex !== -1 && itemDetail &&
                                <>
                                    <VerticalItemDetailHeader leftToolSet={itemHeaderLeftToolSet} rightToolSet={itemHeaderRightToolSet} hideItemDetail={hideItemDetail} />
                                    {itemDetail}
                                </>
                            }
                        </SlidingBox>
                    </div>
                }
            </div>
        </div>
    </>
}

export function VerticalTabContainer(props) {
    const { items, onBack, defaultIndex, onSectionChange, classes } = props;
    const [slidingDirection, setSlidingDirection] = useState();
    const [activeItemIndex, setActiveItemIndex] = useState(defaultIndex ? defaultIndex : 0);
    const [previousSelectedItemIndex, setPreviousSelectedItemIndex] = useState(activeItemIndex);
    const [showItemList, setShowItemList] = useState(true);

    useEffect(() => {
        setSlidingDirection("Left")

        if (defaultIndex) {
            setActiveItemIndex(defaultIndex)
        }
    }, [defaultIndex])

    function toggleSlideDirection(toggleDirection) {
        if (toggleDirection === "Right") {
            return "Left";
        } else if (toggleDirection === "Left") {
            return "Right";
        }

        console.log("Invalid sliding direction");
    }

    function toggleItemDetail() {
        setSlidingDirection(toggleSlideDirection(slidingDirection));
    }
    const backActions = {}

    function handleBack() {
        const legendTitle = items[activeItemIndex].legendTitle;
        const backAction = backActions[legendTitle];
        let flag = false;
        if (backAction && typeof (backAction) == "function") {
            flag = backAction();
        }
        else if (onBack && typeof (onBack) == "function") {
            flag = onBack(activeItemIndex)
        }

        if (!flag) {
            setSlidingDirection(toggleSlideDirection(slidingDirection));
            setShowItemList(true);
        }
    }

    function registerBackAction(legendTitle, action) {
        backActions[legendTitle] = action;
    }

    function selectItem(itemIndex) {
        const screenType = ScreenUtil.getScreenType();
        if (screenType === ScreenTypeEnum.MOBILE) {
            setShowItemList(false);
            setSlidingDirection("Left");
        } else {
            setShowItemList(true);
            if (itemIndex === previousSelectedItemIndex) {
                toggleItemDetail();
            } else {
                setSlidingDirection("Left");
            }
        }
        setPreviousSelectedItemIndex(itemIndex);
        setActiveItemIndex(itemIndex);

        if (onSectionChange && typeof (onSectionChange) === 'function') {
            onSectionChange(activeItemIndex);
        }
    }
    var item = items[activeItemIndex];
    const itemDetail = item.detail;
    const itemHeaderLeftToolSet = item.itemHeaderLeftToolSet;
    const itemHeaderRightToolSet = item.itemHeaderRightToolSet;
    return <VerticalTabContext.Provider value={{ registerBackAction: registerBackAction }}>
        <div className={classes + " appvertabs"}>
            {
                CollectionUtil.isNotBlank(items) && showItemList &&
                <MenuList className="appvertabs-names">
                    {items.map((item, itemIndex) => {
                        const isItemActive = activeItemIndex === itemIndex;
                        return <MenuItem key={itemIndex} className={isItemActive ? "apptab-active-item" : ""} onClick={() => selectItem(itemIndex)}>
                            <ListItemText>{item.legendTitle}</ListItemText>
                            {item.legendIcon}
                        </MenuItem>
                    }
                    )}
                </MenuList>
            }
            <div className="appvertabs-frame" >
                <SlidingBox slidingDirection={slidingDirection} style={{ height: '100%' }}>
                    {
                        itemDetail &&
                        <>
                            <VerticalItemDetailHeader leftToolSet={itemHeaderLeftToolSet} rightToolSet={itemHeaderRightToolSet} hideItemDetail={handleBack} />
                            <div className='appvertabs-frame-body'>{itemDetail}</div>
                        </>
                    }
                </SlidingBox>
            </div>
        </div>
    </VerticalTabContext.Provider>
}

export function VerticalItemDetailHeader(props) {
    const { leftToolSet, rightToolSet, hideItemDetail } = props;

    return (
        <div className="appvertabs-frame-header">
            <div className="appvertabs-backbutton appvertabs-closeclick" onClick={hideItemDetail}><i className="fas fa-arrow-left">&nbsp;</i></div>
            <div className="appvertabs-title">
                <div className="toolbar">
                    <div className="grid-header left-tool-set">
                        {leftToolSet ? leftToolSet : <></>}
                    </div>
                    <div className="grid-header right-tool-set">
                        {rightToolSet ? rightToolSet : <></>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const VerticalTabContext = createContext({
    registerBackAction: () => { }
});

const useVerticalTabContext = () => useContext(VerticalTabContext);

export default useVerticalTabContext;
