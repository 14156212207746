import { createContext, useContext, useEffect, useState } from "react";

const ContextMenuContext = createContext({
    contextMenu : { visible: false, position: { x: 0, y: 0 } },
    handleContextMenu : () => {},
    handleCloseContextMenu : () => {},
    handleClickOutside : async () => {}
});

export const ContextMenuContextProvider = ({ children }) => {
    const [contextMenu, setContextMenu] = useState({ visible: false, position: { x: 0, y: 0 } });

    const handleContextMenu = (event, containerRef) => {
        event.preventDefault();
        const container = containerRef.current.getBoundingClientRect();
        setContextMenu({
          visible: true,
          position: { x: event.clientX - container.left
            , y: event.clientY - container.top
        }
        });
      };
    
    const handleCloseContextMenu = () => {
      setContextMenu({ ...contextMenu, visible: false });
    };
    
    const handleClickOutside = (event) => {
        if (contextMenu.visible) {
            handleCloseContextMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, [contextMenu.visible]);

    return (
      <ContextMenuContext.Provider value={{ contextMenu, handleContextMenu, handleCloseContextMenu, handleClickOutside }}>
          {children}
      </ContextMenuContext.Provider>
    );
};

export const useContextMenu = () => useContext(ContextMenuContext);