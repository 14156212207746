/**
 * Utility for Html DOM elements.
 */
export default class HtmlUtil {

    /*
     * Determines if a container should be scrolled.
     */
    static isScrollAtEnd = function (container) {
      const offset = 100;
      return container.offsetHeight + container.scrollTop + offset >= container.scrollHeight;
    }
  
    static isScrollAtTop = function (container) {
     return container.scrollTop - 100 <= 0;
    }
  }