import { Box } from '@mui/material';
import { AppContentDataContainerBoxWithTabs, AppContentInnerBox } from 'components/Box/Box';
import { AddButton } from 'components/Buttons/ActionButtons';
import { SideDrawer } from 'components/Drawer/Drawer';
import { AppContentSearchableHeader } from 'components/Header/Header';
import { HorizontalTab } from 'components/Tabs';
import { AppContentMainDataFrame } from 'components/application-content/AppContentMainDataFrame';
import { FilterProvider } from 'contexts/data-filter/FilterContext';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtil from 'utils/StringUtil';
import LocationCardGrid from 'views/account/location/LocationCardList';
import { DeletableChip } from './../../../components/Chip';
import { LocationDetail } from './LocationDetail';

export default function LocationList(props) {
  const { t } = useTranslation();
  const [systemCode, setSystemCode] = useState();
  const [details, setShowDetails] = useState();
  const [allTabData, setAllTabData] = useState({});
  const initialFilters = [
    // { name: 'Price', type: 'Slider', min: 0, max: 1000, step: 100, value: 500 },
    // { name: 'Category', type: 'DropDown', options: ['Electronics', 'Clothing', 'Books'], value: '' },
    // { name: 'Color', type: 'Color', value: '#ff0000' },
    // { name: 'Brand', type: 'FieldValue', value: '', valueLabel: '', fieldName: 'paren' },
  ];

  const [dataFilters, setDataFilters] = useState(initialFilters);

  const PAGE_URL = '/account/location/page';
  function showDetails(location) {
    if (location && StringUtil.isNotBlank(location.systemCode)) {
      setSystemCode(location.systemCode);
    } else {
      setSystemCode(null);
    }
    setShowDetails(true);
  }

  const tabs = [<HorizontalTab label='All' value='all' key='all' />];

  const headerButtons = [<AddButton onClick={() => showDetails()} />];

  const { keywords, addressSystemCode, parentLocationSystemCode, parentLocationName, setKeywords, setParentLocationSystemCode } = useLocationListingContext();
  function deleteParentLocationFilter() {
    setParentLocationSystemCode(null);
  }

  useEffect(() => {
    setAllTabData({ keywords, addressSystemCode, parentLocationSystemCode });
  }, [keywords, addressSystemCode, parentLocationSystemCode]);

  return (
    <>
      <FilterProvider dataFilters={dataFilters}>
        <SideDrawer anchor='right' rightToolBarId={'location-' + systemCode} headerTitle={t('locations')} open={details} onClose={() => setShowDetails(false)}>
          <LocationDetail systemCode={systemCode} rightToolBarId={'location-' + systemCode} />
        </SideDrawer>
        <AppContentInnerBox>
          <AppContentSearchableHeader buttons={headerButtons} itemNameToSearch={t('location')} setKeywords={setKeywords} onSearch={() => {}} />
          <AppContentMainDataFrame>
            <Box sx={{ marginTop: '5px' }}>{StringUtil.isNotBlank(parentLocationSystemCode) && <DeletableChip label={parentLocationName} onDelete={() => deleteParentLocationFilter()} />}</Box>
            <PageableDataManagerProvider url={PAGE_URL}>
              <AppContentDataContainerBoxWithTabs>
                <LocationCardGrid data={allTabData} showDetails={showDetails} />
              </AppContentDataContainerBoxWithTabs>
            </PageableDataManagerProvider>
          </AppContentMainDataFrame>
        </AppContentInnerBox>
      </FilterProvider>
    </>
  );
}

const LocationListingContext = createContext({
  keywords: null,
  systemCode: null,
  addressSystemCode: null,
  parentLocationName: null,
  parentLocationSystemCode: null,
  setKeywords: () => {},
  setSystemCode: () => {},
  setParentLocationName: () => {},
  setParentLocationSystemCode: () => {},
});

export const LocationListingProvider = ({ children }) => {
  const [keywords, setKeywords] = useState(null);
  const [systemCode, setSystemCode] = useState();
  const [parentLocationName, setParentLocationName] = useState(null);
  const [parentLocationSystemCode, setParentLocationSystemCode] = useState(null);
  const [addressSystemCode, setAddressSystemCode] = useState(null);

  return (
    <LocationListingContext.Provider
      value={{
        keywords,
        systemCode,
        parentLocationName,
        parentLocationSystemCode,
        addressSystemCode,
        setKeywords,
        setSystemCode,
        setParentLocationName,
        setParentLocationSystemCode,
        setAddressSystemCode,
      }}>
      {children}
    </LocationListingContext.Provider>
  );
};

export const useLocationListingContext = () => useContext(LocationListingContext);
