import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { ChildCare } from '../../../node_modules/@mui/icons-material/index';

export const activityCodes = { name : 'Activity Codes',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Setup',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
        , {
            name : 'Config',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }