import { Box, Drawer, Typography } from '@mui/material';
import { headerHeight, rightSidebarWidth } from './constants';

export function Feed() {
  /* Right Sidebar */
  return (
    <Drawer
      className='app-feed-box'
      variant='permanent'
      anchor='right'
      sx={{
        flexShrink: 0,
        display: { xs: 'none', sm: 'none', lg: 'block' },
        width: { xs: 0, sm: rightSidebarWidth },
        [`& .MuiDrawer-paper`]: {
          width: { xs: 0, sm: rightSidebarWidth },
          bgcolor: 'rgba(0, 0, 0, 0.3)',
          boxSizing: 'border-box',
          mt: headerHeight,
          display: { xs: 'none', sm: 'block' },
          zIndex: 100,
        },
      }}>
      {/* <Paper className='app-feed-box-paper'> */}
      <Box sx={{ p: 2 }}>
        <Typography variant='body1' color='white'>
          Right Sidebar Content
        </Typography>
      </Box>
      {/* </Paper> */}
    </Drawer>
  );
}
