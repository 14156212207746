import { Box } from '@mui/material';
import { FormBox } from 'components/Box/Box';
import { HotGrid280 } from 'components/Containers/Grid';
import { FormAutoCompleteSingleSelect, FormInput, FormLabelledSwitch } from 'components/Form/FormInputs';
import { SaveIconButton } from 'components/Icons';
import { ContainerButtons } from 'components/TitleBar/Titlebar';
import { StandardAccordian } from 'components/accordian/Accordian';
import AppDataGrid from 'components/data-grid/AppDataGrid';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { Formik } from 'formik';
import useHttp from 'hooks/useHttp';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionUtil } from 'utils/CollectionUtil';
import { ObjectUtil } from 'utils/ObjectUtil';
import StringUtil from 'utils/StringUtil';
import LocationCardGrid from 'views/account/location/LocationCardList';
import * as Yup from 'yup';

const BASIC_DETAILS = '/account/location/edit';

export function LocationDetail(props) {
  const { systemCode, rightToolBarId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { get, postMultipart, download } = useHttp();
  const [addressList, setAddressList] = useState([]);
  const initalState = {
    name: '',
    referenceCode: '',
    addressSystemCode: '',
    addressName: '',
    parentLocationSystemCode: '',
    parentLocationName: '',
    file: null,
    replaceExistingData: false,
    childCount: 0,
  };

  const [location, setLocation] = useState(initalState);

  function loadAddresses() {
    const ADDRESS_SEARCH_URL = '/account/address/page';
    get(ADDRESS_SEARCH_URL).then(data => {
      setAddressList(data.rows);
    });
  }

  const [locations, setLocations] = useState([]);
  function loadLocationList() {
    get('/account/location/page').then(data => {
      setLocations(data.rows);
    });
  }

  useEffect(() => {
    const dataFetchUrl = systemCode ? BASIC_DETAILS + '/' + systemCode : null;
    loadAddresses();
    loadLocationList();
    if (StringUtil.isNotBlank(systemCode)) {
      setLoading(true);
      get(dataFetchUrl).then(res => {
        setLocation(ObjectUtil.deepMerge(initalState, res));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [systemCode]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required!'),
    referenceCode: Yup.string(),
  });

  const handleFormSubmit = async values => {
    try {
      setLoading(true);
      await postMultipart(BASIC_DETAILS, values, [values.file]).then(() => {
        setLoading(false);
        setLocation(values);
      });
    } finally {
      setLoading(false);
    }
  };

  function getIndexOfSelectedAddress() {
    if (StringUtil.isNotBlank(location.addressSystemCode) && CollectionUtil.isNotBlank(addressList)) {
      const matchedAddressIndex = CollectionUtil.findIndexOfFirstMatch(addressList, address => address.systemCode === location.addressSystemCode);
      return matchedAddressIndex && matchedAddressIndex >= 0 ? matchedAddressIndex : -1;
    } else {
      return -1;
    }
  }

  function getIndexOfSelectedLocation() {
    if (StringUtil.isNotBlank(location.parentLocationSystemCode) && CollectionUtil.isNotBlank(locations)) {
      const matchedLocationIndex = CollectionUtil.findIndexOfFirstMatch(locations, locationItem => locationItem.systemCode === location.parentLocationSystemCode);
      return matchedLocationIndex && matchedLocationIndex >= 0 ? matchedLocationIndex : -1;
    } else {
      return -1;
    }
  }

  const toolBarButtons = [<SaveIconButton form='submission-form' type='submit' />];
  return (
    !loading && (
      <FormBox>
        <ContainerButtons buttons={toolBarButtons} containerId={rightToolBarId} />
        <Formik onSubmit={handleFormSubmit} initialValues={location} validationSchema={validationSchema}>
          {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
            <form id='submission-form' onSubmit={handleSubmit}>
              <Box sx={{ padding: '10px', paddingBottom: '0px' }}>
                <HotGrid280>
                  <FormInput fullWidth showInputBox helperText={touched.name && errors.name} error={Boolean(errors.name && touched.name)} name='name' label={t('name')} onBlur={handleBlur} onValueChange={handleChange} value={values.name} />
                  <FormInput
                    fullWidth
                    showInputBox
                    helperText={touched.referenceCode && errors.referenceCode}
                    error={Boolean(errors.referenceCode && touched.referenceCode)}
                    name='referenceCode'
                    label={t('reference-code')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.referenceCode}
                    maxLength={4}
                  />
                </HotGrid280>

                <HotGrid280>
                  <input type='hidden' name='parentLocationSystemCode' value={values.parentLocationSystemCode} />
                  <FormAutoCompleteSingleSelect
                    options={locations}
                    fullWidth
                    hideClearIcon
                    onValueChange={(e, value) => {
                      setFieldValue('parentLocationSystemCode', value.systemCode);
                    }}
                    displayField={'displayName'}
                    label='Select Parent Location'
                    selectedValueIndex={getIndexOfSelectedLocation()}
                  />

                  <input type='hidden' name='addressSystemCode' value={values.addressSystemCode} />
                  <FormAutoCompleteSingleSelect
                    options={addressList}
                    fullWidth
                    hideClearIcon
                    onValueChange={(e, value) => {
                      setFieldValue('addressSystemCode', value.systemCode);
                    }}
                    displayField={'name'}
                    label='Select Address'
                    selectedValueIndex={getIndexOfSelectedAddress()}
                  />
                </HotGrid280>
                {location.childCount > 0 && <LocationAreas location={location} values={values} handleChange={handleChange} setFieldValue={setFieldValue} />}
              </Box>
            </form>
          )}
        </Formik>
        {location.childCount > 0 && <SubLocationList location={location} />}
      </FormBox>
    )
  );
}

function LocationAreas(props) {
  const { location, values, handleChange, setFieldValue } = props;
  const systemCode = location.systemCode;
  return (
    <Box>
      <StandardAccordian title='Areas'>
        {StringUtil.isBlank(systemCode) ? <AppDataGrid onDataChange={file => setFieldValue('file', file)} /> : <AppDataGrid onDataChange={file => setFieldValue('file', file)} url={`/account/location/area`} urlData={{ systemCode: systemCode }} />}
        <FormLabelledSwitch label={t('replaceExistingData')} name='replaceExistingData' checked={values.replaceExistingData} onChange={handleChange} />
      </StandardAccordian>
    </Box>
  );
}

function SubLocationList(props) {
  const { location } = props;

  const PAGE_URL = '/account/location/page';
  const allTabData = {
    addressSystemCode: location.addressSystemCode,
    parentLocationSystemCode: location.systemCode,
  };

  return (
    <Box sx={{ padding: '10px', paddingTop: '0px' }}>
      <StandardAccordian title='Sub Locations'>
        <PageableDataManagerProvider url={PAGE_URL}>
          <LocationCardGrid data={allTabData} />
        </PageableDataManagerProvider>
      </StandardAccordian>
    </Box>
  );
}
