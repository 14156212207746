import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import useFileSystem from 'hooks/filesystem/useFileSystem';

export function Companies(props) {
    return <Members {...props} fieldType={"Handle"} memberType="ACCOUNT" icon={<BusinessIcon />}/>
}

export function People(props) {
    return <Members {...props} fieldType="UID" memberType="PERSON" icon={<PersonIcon />}/>
}

export function Members({systemCode, type, memberType, icon}) {
    const {loadObject} = useFileSystem();
    const [members, setMembers] = useState([]);
    useEffect(() => {
        loadObject({url : "/file-object/page/members/" + systemCode, type : type, systemCode, systemCode, memberType : memberType}).then(res => setMembers(res.members || []))
    }, [systemCode, type])
    // return <></>
    return <List>
        {members.map((member) => <ListItemButton>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={member} />
        </ListItemButton>)}
    </List>
}