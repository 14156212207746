import { Skeleton } from "@mui/material";

export function NuggetSkeleton(key) {
    return <div className="rootnugget" key={key}>
        <div className="nugget-main">
            <div>
                <div className="nugget-initials">
                    <div className="initials"></div>
                </div>
                <div className="nugget__rootclick-appmain">
                    <div className="nugget-title">
                        <Skeleton animation="wave"></Skeleton>
                    </div>
                    <div className="nugget-subtitle">
                        <Skeleton animation="wave"></Skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export function HeirarchyNuggetSkeleton(key) {
    return <div className="rootnugget" key={key}>
        <div className="nugget-main">
            <div>
                <div className="nugget-initials">
                    <div className="initials"></div>
                </div>
                <div className="nugget__rootclick-appmain">
                    <div className="nugget-title">
                        <Skeleton animation="wave"></Skeleton>
                    </div>
                    <div className="nugget-subtitle">
                        <Skeleton animation="wave"></Skeleton>
                    </div>
                </div>
            </div>
            <div className="nugget-hierarchy">
            <div className="nugget-hierarchy1">
                <div className="nugget-treelevel-number">L-</div>
            </div>
            <div className="nugget-hierarchy2">
                <div className="nugget-treelevel-marker">
                    <div className="treelevel-marker-arrows">
                        <div className="treelevel-marker1"><Skeleton animation="wave"></Skeleton></div>
                        <div className="treelevel-marker2"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
}