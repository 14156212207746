import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, MenuItem, TextField } from "@mui/material";
import CopyToClipboard from 'components/CopyToClipboard';
import DialogBox from 'components/Dialog';
import { FullWidthSideDrawer } from "components/Drawer/Drawer";
import { GrapeContainer } from "components/Grapesjs/GrapeContainer";
import HotlinkLoading from 'components/HotlinkLoading';
import { DropDownMenu } from "components/Menu/Menu";
import useFileSystem from "hooks/filesystem/useFileSystem";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { HtmlBlockPreview } from './HtmlBlockPreview';

export function HtmlBlockMenu({item, onRename}) {
    const [showHtml, setShowHtml] = useState()
    const [showPreview, setShowPreview] = useState()
    const [showTemplate, setShowTemplate] = useState()
    const {editObject, loadObject, loadObjects ,addMultipartObject, deleteObject} = useFileSystem();
    const [loading, setLoading] = useState()
    function publish() {
        editObject({url : "/block/html/publish", systemCode : item.systemCode, type : item.type}).then(res => setLoading(false)).catch(() => setLoading(false))
    }

    return <>
        {loading && <HotlinkLoading />}
        {showHtml && 
        <FullWidthSideDrawer anchor="right" headerTitle={item.name} open={showHtml} onClose={() => setShowHtml(false)}>
            <GrapeContainer 
                onStore={(data) => {
                    editObject({url : '/block/html/overview', systemCode : item.systemCode, type : item.type, html : data.html, css : data.css})
                }} 
                onLoad={() => loadObject({url : "/block/html/overview/" + item.systemCode, systemCode : item.systemCode, type : item.type,})}
                onAssetUpload={(files) => addMultipartObject({url : "/file/upload/pcr", parentObjectCode : item.systemCode, files : files})}
                onLoadAsset={() => loadObjects({parentObjectCode : item.systemCode})}
            />
        </FullWidthSideDrawer>
        }
        {showPreview && 
        <FullWidthSideDrawer anchor="right" headerTitle={item.name} open={showPreview} onClose={() => setShowPreview(false)}>
            <HtmlBlockPreview item={item}/>
        </FullWidthSideDrawer>
        }
        {
         showTemplate && 
          <TemplateEdit item={item} onClose={() => setShowTemplate(false)}/>
        }
            <DropDownMenu menuIcon={<MoreVertIcon />}>
                <MenuItem onClick={() => {
                    onRename(item)
                }}>Rename</MenuItem>
                <MenuItem onClick={() => setShowHtml(true)}>
                    Edit Html
                </MenuItem>
                <MenuItem onClick={() => setShowTemplate(true)}>
                    Create/Edit with Template
                </MenuItem>
                <MenuItem onClick={() => publish()}>
                    Publish
                </MenuItem>
                <MenuItem onClick={() => setShowPreview(true)}>
                    Preview
                </MenuItem>
                <CopyToClipboard value={item.handle}>
                  <MenuItem>
                      Copy Handle
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem onClick={() => {
                    deleteObject({systemCode : item.systemCode, type : item.type}).then(() => alert("Deletion Successful."))
                }}>Delete</MenuItem>
            </DropDownMenu>
    </>
}



function TemplateEdit({item, onClose}) {
    const [handle, setHandle] = useState();
    const [showError, setShowError] = useState();
    const {editObject} = useFileSystem();
    const {t} = useTranslation();

    function handleSubmit() {
        if(handle) {
            editObject({url : '/block/html/attachment/reference', attachmentArtifactHandle: handle, systemCode : item.systemCode, type : item.type})
            setShowError(false)
        }
        else {
            setShowError(true)
        }
    }

    return <DialogBox
            open={true}
            title={'Provide Template'}
            description={
              <TextField
                label={t('handle')}
                helperText="Please provide a valid handle"
                error={showError}
                value={handle}
                size='small'
                onChange={e => setHandle(e.target.value)}
              />
            }
            handleClose={() => onClose()}
            buttons={[
              <Button key={'Yes'} onClick={() => handleSubmit()}>
                {t('save')}
              </Button>,
              <Button key={'No'} onClick={() => onClose()}>
                {t('cancel')}
              </Button>,
            ]}
          />
}