import { green } from '@mui/material/colors';
import { Field, FieldArray, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { FormInput, FormTags } from "components/Form/FormInputs";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const validationSchema = Yup.object().shape({
    members: Yup.array().required("Members are required!")
  });
  
export function AddCompanyMember(props) {
    return <AddMember {...props} fieldPrefix="Handle"/>
}

export function AddPeopleMember(props) {
    return <AddMember {...props} fieldPrefix="UID"/>
}

export function AddMember({onSave, fieldPrefix}) {
    const [loading, setLoading] = useState();
    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            onSave(values).then(() => setLoading(false))
        }
        finally {
          setLoading(false);
        }
      };
    
    const initialValues = {
        members : [""]
    }

    return <Formik
            onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form>
                <FieldArray name="members">
                    {({ push, remove }) => (
                    <div>
                            {values?.members?.map((item, index) => (
                        <Box display="flex" alignItems="center" marginBottom={2}>
                            <Field
                                key={index}
                                name={`members[${index}]`}
                                value={item}
                                onBlur={handleBlur}
                                render={({ field }) => (
                                    <FormInput
                                    {...field}
                                    onValueChange={handleChange}
                                    label={`${fieldPrefix} #${index + 1}`}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    fullWidth
                                    />
                                )}
                                />
                            
                            {(values.members.length - 1 === index) && 
                                <IconButton
                                    sx={{ color: green[500] }}
                                    onClick={() => push("")}
                                    aria-label={`remove-item-${index}`}
                                    >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            }
                            <IconButton
                                onClick={() => remove(index)}
                                color="secondary"
                                aria-label={`remove-item-${index}`}
                                >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    </div>
                    )}
                </FieldArray>
                <LoadingButton
                    onClick={handleSubmit}
                    type="submit"
                    color="primary"
                    loading={loading}
                    variant="contained"
                    sx={{ my: 2 }}>
                    Save
                </LoadingButton>
            </form>    
            )}
        </Formik>
           
}