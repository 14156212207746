import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { ChildCare } from '../../../node_modules/@mui/icons-material/index';

export const hierarchy = {
    name : 'Hierarchy',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Group of Companies',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Units and Functions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Org',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }