import { StyledBadge } from 'components/Badge';
import { DragAndDropSortable } from 'components/Drag-n-Drop/DragAndDropSort';
import { CopyIcon } from 'components/Icons';
import useHttp from 'hooks/useHttp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Radio,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CollectionUtil } from 'utils/CollectionUtil';
import { ObjectUtil } from 'utils/ObjectUtil';
import StringUtil from 'utils/StringUtil';
import UrlUtil from 'utils/UrlUtils';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './form-input.scss';

export function DisplayPictureModal(props) {
  const { open, handleClose, pictureLocator, fileType, title, alt } = props;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
        <img alt={alt} src={UrlUtil.getCompleteUrl('/picture/thumbnail/' + pictureLocator + '.' + fileType)} title={title} />
      </Box>
    </Modal>
  );
}

export function MobileFullWidthFormAsyncSingleSelect(props) {
  return <FormAsyncSingleSelect {...props} />;
}

export const FormAsyncMultiSelect = React.forwardRef(({ url, minKeywords, queryParams, responseTransformer, data, existing, ...props }, ref) => {
  const [open, setOpen] = useState(false);
  const { get } = useHttp();
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  let keywordLengthThreshold = minKeywords;
  keywordLengthThreshold = keywordLengthThreshold ? keywordLengthThreshold : 3;

  let searchText = queryParams;
  searchText = searchText ? searchText : 'searchText';

  function load(e) {
    const value = e.target.value;
    data = data ? data : {};
    if (value && value !== '' && value.length >= keywordLengthThreshold) {
      data[searchText] = value;
      get(url, data).then(res => {
        if (responseTransformer && typeof responseTransformer === 'function') {
          setOptions(responseTransformer(res));
        } else {
          setOptions(res);
        }
      });
    }
  }
  const { label, name, error, onValueChange, helperText, displayField, valueField, onValueSelect, ...others } = props;
  return (
    <Autocomplete
      {...others}
      multiple
      size='small'
      onKeyDown={load}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}
      ref={ref}
      onChange={onValueChange}
      getOptionLabel={option => option[displayField]}
      renderInput={params => {
        return (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          if (onValueSelect && typeof onValueSelect === 'function') {
            return onValueSelect(option);
          } else {
            return (
              <React.Fragment key={option[valueField]}>
                <input name={name} type='hidden' value={option[valueField]} />
                <Chip variant='outlined' label={option[displayField]} size='small' {...getTagProps({ index })} />
              </React.Fragment>
            );
          }
        });
      }}
    />
  );
});

export const FormAsyncSingleSelect = React.forwardRef(({ url, minKeywords, queryParams, data, responseTransformer, ignoredItems, renderOption, ...props }, ref1) => {
  const { label, name, error, onValueChange, helperText, displayField, isOptionEqualToValue, ...others } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const { get } = useHttp();
  let keywordLengthThreshold = minKeywords;
  keywordLengthThreshold = keywordLengthThreshold ? keywordLengthThreshold : 3;

  let searchText = queryParams;
  searchText = searchText ? searchText : 'searchText';

  function load(e) {
    setOptions([]);
    const value = e.target.value;
    data = data ? data : {};
    if (value && value !== '' && value.length >= keywordLengthThreshold) {
      data[searchText] = value;
      setShowLoading(true);
      get(url, data).then(res => {
        let options;
        if (responseTransformer && typeof responseTransformer === 'function') {
          options = responseTransformer(res);
        } else {
          options = res;
        }
        if (ignoredItems && ignoredItems.length > 0) {
          options = options.filter(item => !ignoredItems.some(ii => isOptionEqualToValue(item, ii)));
        }

        setOptions(options ? options : []);
        setShowLoading(false);
      });
    }
  }
  const ref = useRef();
  const [key, setKey] = useState('');

  return (
    <Autocomplete
      {...others}
      size='small'
      onKeyDown={load}
      open={open}
      sx={{ display: 'inline-flex' }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      key={key}
      options={options}
      loading={showLoading}
      clearOnBlur={true}
      filterOptions={options => options}
      onChange={(e, value, reason) => {
        onValueChange(e, value);
        setKey(StringUtil.getRandomString(5));
      }}
      getOptionLabel={option => (option[displayField] ? option[displayField] : '')}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue ? isOptionEqualToValue : () => true}
      renderInput={params => {
        return (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            ref={ref}
            {...others}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {showLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
});

export const FormAutoCompleteSingleSelect = React.forwardRef(({ onValueChange, displayField, label, options, fullWidth, hideClearIcon, selectedValueIndex, ...others }, ref) => {
  const [key, setKey] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const filterOptions = (options, state) => {
    return options.filter(option => option[displayField].toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  useEffect(() => {
    setSelectedValue(CollectionUtil.isNotBlank(options) && selectedValueIndex >= 0 ? options[selectedValueIndex] : null);
  }, [options, selectedValueIndex]);

  return (
    <Autocomplete
      {...others}
      options={options}
      size='small'
      open={open}
      sx={{
        display: 'inline-flex',
        width: fullWidth ? 'max(100%, 52ch) !important' : undefined, // Conditional width based on fullWidth
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      key={key}
      clearOnBlur={true}
      filterOptions={filterOptions}
      onChange={(e, value, reason) => {
        setSelectedValue(value);
        onValueChange(e, value);
        setKey(StringUtil.getRandomString(5));
      }}
      value={selectedValue}
      getOptionLabel={option => (option[displayField] ? option[displayField] : '')}
      isOptionEqualToValue={(option, value) => value && option.systemCode === value.systemCode}
      clearIcon={hideClearIcon ? null : undefined}
      renderInput={params => {
        return (
          <TextField
            {...params}
            fullWidth
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
    />
  );
});

export const FormAsyncMultiContextSearch = React.forwardRef(({ ...props }, ref) => {
  const { label, minKeywords, queryParams, ignoredItems, onValueChange, sx, contexts, displayField, renderOption, isOptionEqualToValue, ...others } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [key, setKey] = useState('');
  const { get } = useHttp();
  let keywordLengthThreshold = minKeywords;
  keywordLengthThreshold = keywordLengthThreshold ? keywordLengthThreshold : 3;

  function load(e) {
    const buffer = contexts.filter(c => c.isValid(e.target.value));

    setOptions([]);
    const value = e.target.value;
    const context = buffer[0];
    let searchText = context.queryParams;
    searchText = searchText ? searchText : 'searchText';
    const data = context.data ? context.data : {};
    if (value && value !== '' && value.length >= keywordLengthThreshold) {
      data[searchText] = value;

      if (context.url) {
        get(context.url, data).then(res => {
          let options;
          if (context.responseTransformer && typeof context.responseTransformer === 'function') {
            options = context.responseTransformer(res);
          } else {
            options = res;
          }
          if (ignoredItems && ignoredItems.length > 0) {
            options = options.filter(item => !ignoredItems.some(ii => isOptionEqualToValue(item, ii)));
          }

          setOptions(options ? options : []);
        });
      } else if (context.responseTransformer && typeof context.responseTransformer === 'function') {
        let options;
        options = context.responseTransformer(value);
        setOptions(options ? options : []);
      }
    }
  }

  return (
    <Autocomplete
      {...others}
      size='small'
      onKeyDown={load}
      open={open}
      sx={{ display: 'inline-flex', ...sx }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      key={key}
      options={options}
      loading={showLoading}
      clearOnBlur={true}
      filterOptions={options => options}
      onChange={(e, value, reason) => {
        onValueChange(e, value);
        setKey(StringUtil.getRandomString(5));
      }}
      getOptionLabel={option => (option[displayField] ? option[displayField] : '')}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue ? isOptionEqualToValue : () => true}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            ref={ref}
            {...others}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {showLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
});

// export const FormDatePicker = React.forwardRef(({ onValueChange, value, ...props }, ref) => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker {...props} value={dayjs(value)} onChange={onValueChange} format='D-MMM-YYYY' />
//     </LocalizationProvider>
//   );
// });

export const FormDatePicker = ({ field, form, ...props }) => {
  const { name, value } = field;
  const { touched, errors } = form;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        name={name}
        value={value ? dayjs(value) : null} // Ensure value is a dayjs object
        onChange={newValue => form.setFieldValue(name, newValue)} // Use Formik's setFieldValue to update the value
        renderInput={params => <TextField {...params} name={name} helperText={touched[name] && errors[name]} error={Boolean(touched[name] && errors[name])} />}
        {...props}
      />
    </LocalizationProvider>
  );
};

export const FormPreloadedSingleSelect = React.forwardRef(({ options, ...props }, ref) => {
  const [open, setOpen] = useState(false);

  const { label, name, error, onValueChange, helperText, displayField, sx, ...others } = props;
  return (
    <Autocomplete
      {...others}
      size='small'
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      onChange={onValueChange}
      getOptionLabel={option => (option[displayField] ? option[displayField] : '')}
      renderInput={params => {
        return (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            sx={{ maxWidth: '100%', marginRight: '0px', marginLeft: '0px', ...sx }}
            {...others}
            label={label}
            ref={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
          />
        );
      }}
    />
  );
});

export const MobileFullWidthFormEditor = React.forwardRef(({ style, ...props }, ref) => {
  return <FormEditor {...props} fullWidth style={{ maxWidth: '500px', ...style }} />;
});

export const FormEditor = React.forwardRef(({ onValueChange, borderless, sx, style, ...props }, ref) => {
  const borderlessStyle = borderless === undefined ? false : true;
  const borderlessStyleSx = borderlessStyle ? { '& fieldset': { border: 'none' } } : '';
  return <TextField className='editor' sx={{ ...sx, ...borderlessStyleSx }} style={{ ...style }} {...props} onChange={onValueChange} inputProps={{ maxLength: 100000 }} multiline maxRows={5} />;
});

export const FormFileSelect = React.forwardRef(({ accept, onSelect, name, ...props }, ref) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState();

  function getSimpleFileName(fileName) {
    const parts = fileName.split('\\');
    const lastValue = parts[parts.length - 1];

    // if(onSelect && typeof(onSelect) === "function") {
    //     onSelect(fileName)
    // }
    return lastValue;
  }
  const fieldName = StringUtil.isNotBlank(name) ? name : 'file';

  return (
    <>
      <Button variant='contained' component='label'>
        {t('choose-file')}
        <input
          hidden
          accept={accept ? accept : '.csv,.xls,.xlsx,application/vnd.ms-excel'}
          name={fieldName}
          type='file'
          onChange={e => {
            setFileName(getSimpleFileName(e.target.value));

            if (onSelect && typeof onSelect === 'function') {
              onSelect(Array.from(e.target.files));
            }
          }}
        />
      </Button>{' '}
      &nbsp; {fileName}
    </>
  );
});

export const FormMultiFileSelect = React.forwardRef(({ accept, handleUpload, name, ...props }, ref) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  return (
    <>
      <Button variant='contained' component='label'>
        {t('choose-file')}
        <input hidden accept={accept} name={name} type='file' onChange={e => setFiles(Array.from(e.target.files))} multiple />
      </Button>
      <ImageList sx={{ maxHeight: 450, marginTop: '10px' }} cols={5} rowHeight={164}>
        <DragAndDropSortable
          items={files}
          cardTemplate={(file, index) => {
            return (
              <ImageListItem key={index}>
                <img src={URL.createObjectURL(file)} alt={URL.createObjectURL(file)} />
                <ImageListItemBar
                  sx={{
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  title={file.name}
                  position='top'
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      onClick={e => {
                        setFiles(CollectionUtil.deleteElementAt(files, index));
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  actionPosition='left'
                />
              </ImageListItem>
            );
          }}
        />
      </ImageList>
      {files && files.length > 0 && (
        <Button variant='contained' component='label' onClick={() => handleUpload(files)} sx={{ marginTop: '10px' }}>
          Upload
        </Button>
      )}
    </>
  );
});

export const FormPictureSelect = React.forwardRef(() => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState();

  function getSimpleFileName(fileName) {
    const parts = fileName.split('\\');
    const lastValue = parts[parts.length - 1];
    return lastValue;
  }

  return (
    <>
      <Button variant='contained' component='label'>
        {t('choose-image')}
        <input hidden accept='image/*' name='file' type='file' onChange={e => setFileName(getSimpleFileName(e.target.value))} />
      </Button>{' '}
      &nbsp; {fileName}
    </>
  );
});

export const MobileFullWidthFormInput = React.forwardRef(({ style, ...props }, ref) => {
  return <FormInput {...props} hideIcon showInputBox style={{ width: '100%', maxWidth: '500px', ...style }} />;
});

export const MobileFullWidthFormSelect = React.forwardRef(({ ...props }, ref) => {
  return <FormSelect {...props} style={{ width: '100%', maxWidth: '500px' }} />;
});

export const FormInput = React.forwardRef(({ onValueChange, className, onEditable, InputProps, label, hideIcon, showInputBox, borderless, maxLength, showCopyIcon, onCopy, sx, ...props }, ref) => {
  const value = props.value;
  const [displayInputBox, setDisplayInputBox] = useState(true);
  const [manuallyChanged, setManuallyChanged] = useState(false);
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const editable = !readOnly && !disabled;
  const decoratedLabel = (
    <span style={{ cursor: 'pointer' }} onClick={handleEditable}>
      {label}
    </span>
  );
  const borderlessStyle = borderless === undefined ? false : true;
  const borderlessStyleSx = borderlessStyle ? { '& fieldset': { border: 'none' } } : '';

  function handleEditable(e) {
    setDisplayInputBox(editable);

    if (onEditable && typeof onEditable === 'function') {
      onEditable(e);
    }
  }
  var hasValue = (value && typeof value === 'number') || StringUtil.isNotBlank(value);
  hasValue = hasValue ? hasValue : false;

  useEffect(() => {
    if (!manuallyChanged) {
      setDisplayInputBox(!hasValue || showInputBox);
    }
  }, [hasValue, showInputBox]);

  return (
    <>
      <TextField
        className={displayInputBox ? className : 'form-text-value'}
        size='small'
        label={decoratedLabel}
        sx={{ ...sx, ...borderlessStyleSx }}
        {...props}
        ref={ref}
        onChange={e => {
          setManuallyChanged(true);
          onValueChange(e);
        }}
        inputProps={{ maxLength }}
        InputProps={{
          ...InputProps,
          startAdornment: !displayInputBox && (
            <div style={{ display: 'flex', maxWidth: '100%', cursor: 'pointer' }}>
              <div className={editable ? 'editable field-text' : 'field-text'} onClick={handleEditable}>
                {props.value}
              </div>
              {editable && !hideIcon && (
                <div onClick={handleEditable} style={{ lineHeight: '1em', marginLeft: '5px', display: 'flex', alignItems: 'flex-end' }}>
                  <i className='fa fa-pencil-alt'></i>
                </div>
              )}
              {showCopyIcon && <div style={{ lineHeight: '1em', marginLeft: '5px', display: 'flex', alignItems: 'flex-end' }}>{showCopyIcon && <CopyIcon value={onCopy ? onCopy() : value} />}</div>}
            </div>
          ),
        }}
      />
    </>
  );
});

export function GroupedFormInput(props) {
  const { showEditMode, ...others } = props;
  const defaultShowEditMode = showEditMode !== undefined && typeof props.showEditMode == 'boolean' ? props.showEditMode : false;
  const [showEditable, setShowEditable] = useState(defaultShowEditMode);

  useEffect(() => {
    setShowEditable(defaultShowEditMode);
  }, [defaultShowEditMode]);

  function getControl() {
    if (!showEditable) {
      return <FormInput readOnly={true} {...others} />;
    } else {
      return <FormInput {...others} />;
    }
  }

  return <>{getControl()}</>;
}

export const FormLabelledSwitch = React.forwardRef(({ containerStyles, label, labelPlacement, readOnly, ...props }, ref) => {
  return <FormControlLabel control={<Switch {...props} size='small' ref={ref} readOnly />} size='small' label={label} sx={containerStyles} labelPlacement={labelPlacement} />;
});
export const AntSwitch = React.forwardRef(({ ...props }, ref) => {
  const styles = {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      boxSizing: 'border-box',
    },
  };

  return <Switch {...props} size='small' ref={ref} readOnly />;
});

export const FormNumericInput = React.forwardRef(({ ...props }, ref) => {
  return <TextField type='number' InputLabelProps={{ shrink: true }} variant='outlined' size='small' {...props} ref={ref} />;
});

export const FormRadio = React.forwardRef(({ ...props }, ref) => {
  return <FormControlLabel {...props} control={<Radio />} />;
});

export const FormSelect = React.forwardRef(({ borderless, sx, style, labelSx, fullWidth, ...props }, ref) => {
  const label = props.label;
  const labelId = props.labelId;

  const borderlessStyle = borderless === undefined ? false : true;
  const borderlessStyleSx = borderlessStyle ? { '& fieldset': { border: 'none' } } : '';

  return (
    <FormControl sx={{ ...sx, ...borderlessStyleSx }} fullWidth={fullWidth} style={{ ...style }}>
      <InputLabel id={labelId} size='small' sx={labelSx}>
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId={labelId}
        autoWidth
        size='small'
        {...props}
        ref={ref}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          getContentAnchorEl: null,
        }}>
        {props.children}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
});

export function SmartFormInput(props) {
  const { showEditMode, label, value, name, hiddenFields, onEditClick, ...others } = props;
  const defaultShowEditMode = props.showEditMode !== undefined && typeof props.showEditMode == 'boolean' ? props.showEditMode : false;
  const [showEditable, setShowEditable] = useState();

  useEffect(() => {
    setShowEditable(defaultShowEditMode);
  }, [defaultShowEditMode]);

  if (!showEditable && StringUtil.isNotBlank(value)) {
    return (
      <>
        {CollectionUtil.isNotBlank(hiddenFields) && hiddenFields.map((hiddenField, index) => <input type='hidden' name={hiddenField.name} value={hiddenField.value} key={index} />)}
        <FormInput
          label={label}
          name={name}
          value={value}
          onClick={e => {
            if (onEditClick && typeof (onEditClick === 'function')) {
              onEditClick();
            } else {
              setShowEditable(true);
            }
          }}
        />
      </>
    );
  } else {
    return <>{others.children}</>;
  }
}

export const FormControlStyledBadge = React.forwardRef(({ ...props }, ref) => {
  return (
    <FormControl>
      <div>
        <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant='dot'>
          {props.children}
        </StyledBadge>
      </div>
    </FormControl>
  );
});

export const FormTags = React.forwardRef(({ ...props }, ref) => {
  const { delimiter, onDelete, onAdd, afterDelete, name, tags, sx, ...others } = props;
  let finalTags = [];
  if (tags) {
    if (typeof tags === 'object') {
      finalTags = [...new Set(tags)];
    } else if (typeof tags === 'string' && delimiter) {
      finalTags = [...new Set(tags.split(delimiter))];
    }
  }

  function defaultOnDelete(item) {
    finalTags = finalTags.filter(tag => {
      return tag !== item;
    });

    if (afterDelete && typeof afterDelete === 'function') {
      afterDelete(finalTags);
    }
  }

  const handleDelete = onDelete ? onDelete : defaultOnDelete;

  function addTag(e) {
    if (e.key === ' ') {
      finalTags.push(e.target.value.replace(' ', ''));
      e.target.value = '';
    }

    if (onAdd && typeof onAdd === 'function') {
      onAdd(finalTags);
    }
  }

  return (
    <Box
      sx={{
        '& .MuiInputBase-root': { display: 'block' },
        ...sx,
      }}>
      <TextField
        {...others}
        multiple
        size='small'
        onKeyPress={e => addTag(e)}
        InputProps={{
          startAdornment: finalTags.map(item => (
            <React.Fragment key={item}>
              <input type='hidden' name={name} value={item} />
              <Chip label={item} onDelete={() => handleDelete(item)} />
            </React.Fragment>
          )),
        }}></TextField>
    </Box>
  );
});

export const MandatoryAsyncMultiSelect = React.forwardRef(({ onValueChange, ...props }, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return <FormAsyncMultiSelect {...props} onValueChange={onValueChange} ref={ref} error={errors[props.name] ? true : false} helperText={errors[props.name] ? 'This field is mandatory.' : ''} {...register(props.name, { required: true })} />;
});

export const MandatoryFileInput = React.forwardRef(({ onChange, ...props }, ref) => {
  return <FormInput accept='.csv,.xls,application/vnd.ms-excel' {...props} label='Upload' name='file' type='file' required={true} InputLabelProps={{ shrink: true }} />;
});

export const MobileFullWidthMandatoryInputField = React.forwardRef(({ ...props }, ref) => {
  return <MandatoryInputField {...props} style={{ width: '100%', maxWidth: '500px' }} />;
});

/**
 * A mandatory input text fields.
 */
export const MandatoryInputField = React.forwardRef(({ onChange, style, ...props }, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormInput
      {...props}
      onValueChange={onChange}
      ref={ref}
      error={errors[props.name] ? true : false}
      helperText={errors[props.name] ? 'This field is mandatory.' : ''}
      style={{ maxWidth: '100%', ...style }}
      {...register(props.name, { required: true })}
    />
  );
});

export function MandatoryInputFieldWithoutRef(props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const onChange = props.onChange;
  return <FormInput {...props} onValueChange={onChange} error={errors[props.name] ? true : false} helperText={errors[props.name] ? 'This field is mandatory.' : ''} {...register(props.name, { required: true })} />;
}

/**
 * A mandatory input text fields.
 */
export const MandatoryCodeField = React.forwardRef(({ onChange, ...props }, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  function isValidCode(value) {
    const valid = /^\w([\w\-.]*\w)?$/.test(value);

    return valid ? valid : 'Please provide only letters (A-Z), digits (0-9), hyphens (-) or periods (.), ensuring the first and last character is a letter or a digit.';
  }

  return (
    <FormInput
      {...props}
      onValueChange={onChange}
      ref={ref}
      error={errors[props.name] ? true : false}
      helperText={errors[props.name] ? errors[props.name].message : ''}
      {...register(props.name, {
        required: 'This field is mandatory.',
        validate: isValidCode,
      })}
    />
  );
});

/**
 * A mandatory handle fields.
 */
export const MandatoryHandleField = React.forwardRef(({ onChange, ...props }, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  function isValidCode(value) {
    const valid = StringUtil.isValidHandle(value);

    return valid ? valid : 'Please provide minimum 10 characters, only letters (A-Z), digits (0-9), hyphens (-) or periods (_).';
  }

  return (
    <FormInput
      {...props}
      onValueChange={onChange}
      ref={ref}
      error={ObjectUtil.getProperty(errors, props.name) ? true : false}
      helperText={ObjectUtil.getProperty(errors, props.name) ? ObjectUtil.getProperty(errors, props.name).message : ''}
      {...register(props.name, {
        required: 'This field is mandatory.',
        validate: isValidCode,
      })}
    />
  );
});

export function NonEditableField(props) {
  const { label, value } = props;
  return (
    <div style={{ width: '100%', marginTop: '8px', marginBottom: '8px', ...props }}>
      <span style={{ fontSize: 'small', fontWeight: '500', color: '#936' }}>{label}</span> &nbsp; &nbsp; <span>{value}</span>
    </div>
  );
}

export function MandatoryEditableTextField(props) {
  const { label, value, fieldName, showInEditMode, onChange } = props;
  const fieldInitiallyEditable = showInEditMode === undefined ? StringUtil.isBlank(value) : showInEditMode;
  const [showEditableField, setShowEditableField] = useState(fieldInitiallyEditable);

  return (
    <>
      {showEditableField && <MandatoryInputField label={label} sx={{ marginLeft: '0px' }} name={fieldName} value={value} fullWidth={true} onChange={onChange} />}

      {!showEditableField && (
        <>
          <input type='hidden' name={fieldName} value={value} />
          <div style={{ width: '100%', marginTop: '8px', marginBottom: '8px', ...props }}>
            <span style={{ fontSize: 'small', fontWeight: '500', color: '#936' }} onClick={() => setShowEditableField(true)}>
              {label}
            </span>{' '}
            &nbsp; &nbsp;
            <span onClick={() => setShowEditableField(true)}>{value}</span> &nbsp; &nbsp;
            <span>
              <i className='fa fa-pen' onClick={() => setShowEditableField(true)}></i>
            </span>
          </div>
        </>
      )}
    </>
  );
}

export function NativeFormInput(props) {
  const { style, ...others } = props;
  return <input type='text' style={{ border: '1px solid #CCC', borderRadius: '5px', ...style }} {...others} />;
}

export function SelectUOM(props) {
  const [UOMs, setUOMs] = useState();
  const [keys, setKeys] = useState();
  const { t } = useTranslation();
  const { get } = useHttp();
  useEffect(() => {
    get('/catalog/measurement/unit/page').then(res => {
      const e = CollectionUtil.groupBy(res, 'measurementClass');
      setKeys(Object.keys(e));
      setUOMs(e);
    });
  }, []);

  const array = [];
  if (keys) {
    keys.forEach(key => {
      array.push(<ListSubheader key={key}>{key}</ListSubheader>);
      UOMs[key].forEach(uom => {
        array.push(
          <MenuItem value={uom.singularName} key={uom.referenceCode}>
            {uom.singularName} ({uom.singularAcronym.toUpperCase()})
          </MenuItem>
        );
      });
    });
  }

  return (
    UOMs && (
      <FormSelect label={t('uom')} {...props}>
        {array.map(w => w)}
      </FormSelect>
    )
  );
}
