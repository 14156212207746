import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideDrawer } from 'components/Drawer/Drawer';
import Search from 'components/object-list/Search/Search';
import { SubscriptionDetail } from './SubscriptionDetails';
import PermissionBoundary from 'components/security/PermissionBoundary';
import ObjectList from 'components/object-list/ObjectList';
import { AddButton } from 'components/Buttons/ActionButtons';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';

const PAGE_URL = '/cloud/subscription/history?ac=';
export function SubscriptionListingPage({ accountCode }) {
  const [keywords, setKeywords] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState();
  const { t } = useTranslation();
  function handleShowDetails(id) {
    setId(id);
    setShowDetails(true);
  }

  return (
    <PermissionBoundary permissions={['AdministerSystem']}>
      <div className='application-main-content'>
        <div className='applistheader application-main-content-header'>
          <AddButton onClick={() => handleShowDetails(null)} />
          <Search title={t('entitlements')} setKeywords={setKeywords} />
        </div>
        <div className='applistframe application-main-data-frame'>
          {showDetails && (
            <SideDrawer anchor='right' headerTitle={t('entitlement')} open={showDetails} onClose={() => setShowDetails(false)}>
              <SubscriptionDetail accountCode={accountCode} id={id} />
            </SideDrawer>
          )}
          <PageableDataManagerProvider url={PAGE_URL + accountCode}>
            <ObjectList
              data={{ keywords: keywords }}
              onRowClick={item => handleShowDetails(item.id)}
              headCells={[
                {
                  id: 'startDate',
                  numeric: false,
                  label: 'Start Date',
                  nuggetInfo: {
                    order: 2,
                    type: 'subtitle',
                  },
                },
                {
                  id: 'endDate',
                  numeric: false,
                  label: 'End Date',
                  nuggetInfo: {
                    order: 1,
                    type: 'title',
                  },
                },
                {
                  id: 'state',
                  numeric: false,
                  label: 'Status',
                  nuggetInfo: {
                    order: 3,
                    type: 'subtitle',
                  },
                },
              ]}
            />
          </PageableDataManagerProvider>
        </div>
      </div>
    </PermissionBoundary>
  );
}
