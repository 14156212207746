import AuthGuard from 'auth/AuthGuard';
import AppDataGrid from 'components/data-grid/AppDataGrid';
import { OllamaExample } from 'components/ollama/Ollama';
import SpeechToText from 'components/speech-to-text/SpeechToText';
import useLayout from 'layout/frame/FrameLayoutContext';
import { Navigate } from 'react-router-dom';
import AccountCreationView from 'views/account/AccountCreationView';
import AddressList from 'views/account/address/AddressList';
import EmployeeList from 'views/account/employee/EmployeeList';
import LocationList, { LocationListingProvider } from 'views/account/location/LocationList';
import { Bizmo } from 'views/bizmo/Bizmo';
import DriveList from 'views/drive/DriveList';
import UserDrive from 'views/drive/UserDrive';
import { Directory } from 'views/file-system/DirectoryList';
import LandingPage from 'views/home/landing-page';
import RoleList from 'views/roles/RoleList';
import { ForgotPasswordConfirmation } from 'views/sessions/ForgotPasswordConfirmation';
import ForgotPasswordRequest from 'views/sessions/ForgotPasswordRequest';
import Login from 'views/sessions/Login';
import NotFound from 'views/sessions/NotFound';
import Register from 'views/sessions/Register';
import { VerifyJwtToken } from 'views/sessions/VerifyToken';
import { AccountListingPage } from 'views/system-admin/account/AccountList';
import PlatformUserList from 'views/system-admin/user/UserList';

export const useAppRoutes = () => {
  const { layout } = useLayout();

  return [
    {
      element: (
        <AuthGuard>
          {/* <Layout2 /> */}
          {layout.component}
        </AuthGuard>
      ),
      children: [
        // ...materialRoutes,
        { path: '/hub', element: <LandingPage /> },
        { path: 'admin/teams', element: <EmployeeList /> },
        { path: '/account/apply', element: <AccountCreationView /> },
        { path: '/platform/admin/users', element: <PlatformUserList /> },
        { path: '/platform/admin/accounts/all', element: <AccountListingPage /> },

        // Drive Urls
        { path: '/drive/admin', element: <DriveList /> },
        { path: '/drive', element: <UserDrive /> },
        { path: '/folders/:driveCode/:driveName/*', element: <Directory /> },
        { path: '/bizmo', element: <Bizmo /> },

        { path: '/address', element: <AddressList /> },
        {
          path: '/location',
          element: (
            <LocationListingProvider>
              <LocationList />
            </LocationListingProvider>
          ),
        },

        //Roles Urls
        { path: '/roles/admin', element: <RoleList /> },
      ],
    },

    // session pages route
    { path: '/session/404', element: <NotFound /> },
    { path: '/session/signin', element: <Login /> },
    { path: '/session/signup', element: <Register /> },
    { path: '/session/forgot-password', element: <ForgotPasswordRequest /> },
    { path: '/credential/reset', element: <ForgotPasswordConfirmation /> },
    { path: '/people/verify', element: <VerifyJwtToken /> },

    { path: '/', element: <Navigate to='hub' /> },
    { path: '*', element: <NotFound /> },
    { path: '/bizmo/fs', element: <Bizmo /> },
    { path: '/speech-to-text', element: <SpeechToText /> },
    { path: '/ollama', element: <OllamaExample /> },
    { path: '/appdatagrid', element: <AppDataGrid /> },
  ];
};
