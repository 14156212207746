import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const bookkeeping = { name : 'Bookkeeping',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Setup',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Currency',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Currency Conversions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Timeline Cutoffs',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Default Accounting Settings',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Chart of Accounts',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Predefined Ledgers',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Fixed Asset',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Fixed Asset Classification',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Inventory Classification',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'AP Classification',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'AR Classification',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }