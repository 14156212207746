import { Properties } from "components/properties/properties"

export function ThreeColumnTemplate({data}) {
    try {
        data = JSON.parse(data)
    }
    catch {
        // Do nothing
    }

    return <Properties menu={data.menu} />
}