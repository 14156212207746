import { Box, Card, CardContent, MenuItem } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { MoreVertMenu } from 'components/Menu/Menu';
import { ListingCardItemButton, ListingCardItemHeaderLine, ListingCardItemSecondaryLine } from 'components/card-grid/ListItemCard';
import { PagebaleCardGrid280 } from 'components/card-grid/PagebaleCardGrid';
import { useFilterContext } from 'contexts/data-filter/FilterContext';
import useHttp from 'hooks/useHttp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StringUtil from 'utils/StringUtil';
import { useLocationListingContext } from 'views/account/location/LocationList';

export default function LocationCardGrid(props) {
  const { data, showDetails } = props;

  function getCard(location, index) {
    return <LocationCard location={location} onEdit={showDetails} key={location.systemCode} />;
  }

  return (
    <div className='item-card-list location-card-list'>
      <PagebaleCardGrid280
        getCard={getCard}
        data={data}
        sx={{
          gridTemplateColumns: {
            xs: 'repeat(auto-fit, minmax(100%, 1fr))', // Small screens
            sm: 'repeat(auto-fit, minmax(280px, 0.5fr))', // Medium and larger screens
            md: 'repeat(auto-fit, minmax(280px, 0.25fr))', // Medium and larger screens
          },
        }}
      />
    </div>
  );
}

function LocationCard(props) {
  const { location, onEdit } = props;
  const { t } = useTranslation();
  const { downloadFile, deleteResource } = useHttp();
  const navigate = useNavigate();
  const [hideCard, setHideCard] = useState(false);

  const { addFilter, selectFilter } = useFilterContext();

  function getTrimmedLocationText(line) {
    return StringUtil.curtailWithEllipses(line, 30, true);
  }

  const { setParentLocationSystemCode, setParentLocationName, setAddressSystemCode } = useLocationListingContext();
  function showSubLocations(location) {
    setParentLocationSystemCode(location.systemCode);
    setParentLocationName(location.name);
    setAddressSystemCode(location.addressSystemCode);

    const parentLocationFilter = {
      name: 'Parent Location',
      type: 'FieldValue',
      value: location.name || '',
      valueCode: location.systemCode || '',
      fieldName: 'parentLocationSystemCode',
      onClear: () => {
        setParentLocationSystemCode(null);
      },
    };

    addFilter(parentLocationFilter);
    selectFilter(parentLocationFilter);
  }

  function downloadAreas(location) {
    const AREA_DOWNLOAD_URL = '/account/location/area';
    downloadFile(AREA_DOWNLOAD_URL, { systemCode: location.systemCode });
  }

  function deleteLocation(location) {
    const LOCATION_DELETE_URL = '/account/location';
    deleteResource(LOCATION_DELETE_URL, { systemCode: location.systemCode }).then(response => {
      setHideCard(true);
    });
  }

  return (
    !hideCard && (
      <Card className='item-card' variant='outlined' sx={{ borderRadius: '10px', boxShadow: '0 2px 1px 0 rgba(0,0,0,.16)' }}>
        <CardContent>
          <ListingCardItemHeaderLine>{getTrimmedLocationText(location.name + ' (' + location.referenceCode + ')')} </ListingCardItemHeaderLine>
          <ListingCardItemSecondaryLine>{getTrimmedLocationText(location.addressName + ' (' + location.addressShortCode + ')')} </ListingCardItemSecondaryLine>
        </CardContent>
        <CardActions>
          <Box sx={{ display: 'flex' }}>
            <ListingCardItemButton onClick={() => onEdit(location)} label='Edit' />
            {location.childCount > 0 && <ListingCardItemButton onClick={() => showSubLocations(location)} label={'Sub-Locations' + ' (' + location.childCount + ')'} />}
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <MoreVertMenu>
              <MenuItem
                onClick={() => {
                  downloadAreas(location);
                }}>
                {t('download-areas')}
              </MenuItem>
              <MenuItem onClick={() => deleteLocation(location)}>{t('delete')}</MenuItem>
            </MoreVertMenu>
          </Box>
        </CardActions>
      </Card>
    )
  );
}
