import { FormBox } from 'components/Box/Box';
import useHttp from 'hooks/useHttp';
import { Backdrop, CircularProgress } from '@mui/material';
import StringUtil from 'utils/StringUtil';
import UrlUtil from 'utils/UrlUtils';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const DataForm = React.forwardRef(({ onChange, validate, afterFormSubmission, afterResponse, afterDetails, state, id, eventName, saveData, defaultValues, fetchData, removeDefaultStyles, onSubmit, ...props }, ref) => {
  const [loading, setLoading] = useState(false);
  const { get, post } = useHttp();
  const onFormSubmit =
    onSubmit && typeof onSubmit === 'function'
      ? onSubmit
      : (formData, event) => {
          event.preventDefault();

          const form = event.target;
          var data = saveData ? JSON.stringify(saveData) : new FormData(form);

          const formAction = UrlUtil.getUrlParts(form.action);
          const path = formAction.pathname + formAction.search;

          let valid = true;
          if (validate && typeof validate === 'function') {
            valid = validate();
          }
          if (valid && !loading) {
            setLoading(true);
            post(path, data, eventName).then(response => {
              setLoading(false);
              if (!response.errors) {
                if (afterFormSubmission && typeof afterFormSubmission === 'function') {
                  afterFormSubmission(response);
                }
              }
              if (afterResponse && typeof afterResponse === 'function') {
                afterResponse(response);
              }
            });
          }

          return false;
        };

  const dataUrl = props.dataurl;

  /**
   * Loading details/default values for the form.
   */
  async function loadDefaultValues() {
    if (StringUtil.isNotBlank(dataUrl)) {
      setLoading(true);
      const res = await get(dataUrl, fetchData);
      setLoading(false);

      if (afterDetails && typeof afterDetails === 'function') {
        afterDetails(res);
      }
      return res;
    }
  }

  const methods = useForm({
    defaultValues: async () => {
      return StringUtil.isNotBlank(dataUrl) ? await loadDefaultValues() : defaultValues ? defaultValues : {};
    },
  });

  return (
    <FormProvider {...methods}>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <FormBox id={id ? id : 'form'} component='form' onSubmit={methods.handleSubmit(onFormSubmit)} autoComplete='off' {...props} ref={ref} removeDefaultStyles={removeDefaultStyles}>
        {props.children}
      </FormBox>
    </FormProvider>
  );
});

export function MultipartForm(props) {
  return <DataForm encType='multipart/form-data' {...props}></DataForm>;
}
