import { createTheme } from '@mui/material/styles';
import useLayout from 'layout/frame/FrameLayoutContext';
import styleVariables from 'styles/appvariables.scss';

export function useBaseTheme() {
  const appContentHeaderHeight = '37px';
  const appContentDataFramePadding = '10px';
  const appContentPadding = '10px';
  const appConentDataFrameTabHeight = '38px';

  const { layout } = useLayout();
  const { headerHeight, appContentAreaPadding, bottomAppMenuBarHeight } = layout.cssVariables;

  const baseTheme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Primary color
      },
      secondary: {
        main: '#dc004e', // Secondary color
      },
    },
    typography: {
      fontFamily: "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },
    componentCssVars: {
      tabBarHeight: '35px',
    },
    appContentCssVars: {
      appContentPadding: appContentPadding,
      appContentHeaderHeight: appContentHeaderHeight,
      appContentDataFramePadding: appContentDataFramePadding,
      appContentDataFrameHeight: `calc(100% - ${appContentHeaderHeight})`,
      appContentDataframeContainerHeightLg: `calc(100vh - ${headerHeight} - ${appContentAreaPadding} - ${appContentPadding} - ${appContentHeaderHeight} - ${appConentDataFrameTabHeight} - ${appContentDataFramePadding} - ${appContentDataFramePadding})`,
      appContentDataframeContainerHeightSm: `calc(100vh - ${headerHeight} - ${bottomAppMenuBarHeight} - ${appContentAreaPadding} - ${appContentPadding} - ${appContentHeaderHeight} - ${appConentDataFrameTabHeight} - ${appContentDataFramePadding} - ${appContentDataFramePadding})`,
      appContentCardColGap: { xs: '5px', md: '10px' },
      appContentCardRowGap: { xs: '5px', md: '10px' },
      appContentCardGridTemplateColumns: {
        xs: 'repeat(auto-fit, minmax(100%, 1fr))', // Small screens
        md: 'repeat(auto-fit, minmax(280px, 0.5fr))', // Medium and larger screens
      },
    },
    components: {
      // Tabs globally
      MuiTabs: {
        styleOverrides: {
          root: {
            p: 1,
            minHeight: styleVariables.muiTabHeight,
          },
          flexContainer: {
            minHeight: styleVariables.muiTabHeight,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: '35px',
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
    },
  });

  return baseTheme;
}
