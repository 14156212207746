import StringUtil from "utils/StringUtil";
import items from 'assets/nav/default'
/**
 * Types of panel supported by the application.
 */
export default class PanelTypeEnum {
  static DEFAULT = new PanelTypeEnum(
    'C',
    'DEFAULT',
    '',
    items
  );
  static COMMERCIAL = new PanelTypeEnum(
    'C',
    'COMMERCIAL',
    'commercial.json'
  );
  static CORPORATE = new PanelTypeEnum(
    'A',
    'CORPORATE',
    'corporate.json'
  );
  static LOCATION = new PanelTypeEnum(
    'L',
    'LOCATION',
    'location.json'
  );
  static CONTROL = new PanelTypeEnum(
    'N',
    'CONTROL',
    'marketing.json'
  );
  static STAFF = new PanelTypeEnum(
    'S',
    'STAFF',
    'staff.json'
  );
  static  TEAM = new PanelTypeEnum(
    'T',
    'TEAM',
    'team.json'
  );
  static  PLATFORM = new PanelTypeEnum(
    'P',
    'PLATFORM',
    'platform.json'
  );

  static  map = new Map();
  static  list = [
    this.COMMERCIAL,
    this.CORPORATE,
    this.LOCATION,
    this.CONTROL,
    this.STAFF,
    this.TEAM,
    this.PLATFORM,
  ];

  identifier;
  name;
  fileName;
  items;
  constructor( identifier, name, fileName, items) {
    this.identifier = identifier;
    this.name = name;
    this.fileName = fileName;
    this.items = items;
  }

  static getPanel(name) {
    return this.list.filter(panel => StringUtil.equalsIgnoreCase(panel.name,name))[0];
  }

  static getPanelByInitialis(identifier) {
    return this.list.filter(panel => StringUtil.equalsIgnoreCase(panel.identifier, identifier))[0];
  }
}
