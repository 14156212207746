export class NumberUtil {

    /**
     * It returns random intgere between 0 and provided maximum value.
     * If maxValue is not provided then default maxValue will be 10000
     * @param maxValue 
     * @returns 
     */
    static randomInt(maxValue) { 
        if(!maxValue) {
            maxValue = 10000;
        }
        return Math.floor(Math.random() * (maxValue + 1));
    }

    /**
     * It returns random intgere of fixed length specified in input
     * @param outputLength 
     * @returns Random intgere of fixed length specified in input
     */
    static fixedLengthRandomInt(outputLength) { 
        if(!outputLength) {
            outputLength = 5;
        }
        return Math.floor(Math.pow(10, outputLength-1) + Math.random() * (Math.pow(10, outputLength) - Math.pow(10, outputLength-1) - 1));
    }

    static getValidNumber(text) {
        const numbersArray = text ? text.match(/\d+(\.\d+)?/g) : null;

        if (numbersArray) {
            const concatenatedNumbers = numbersArray.map(number => {
                if (number.includes('.')) {
                const decimalParts = number.split('.');
                return decimalParts[0] + '.' + decimalParts.slice(1).join('');
                }
                return number;
            }).join('');
            
            return concatenatedNumbers;
            } else {
                console.log("No numbers found in the input string.");
            }
        }
    }