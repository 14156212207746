import { useEffect, useState } from 'react';
import usePageableDataManager from 'hooks/datamanager/usePageableDataManager';
import SimpleSortingTable from './SimpleSortingTable';

export default function AsyncPageableSimpleSortingTable(props) {
  const { data } = props;
  const { items, loadPage, totalCount } = usePageableDataManager();
  const [pageSize, setPageSize] = useState();

  function handlePageChange(page, pageSize) {
    loadPage({ ...data, pageSize: pageSize, rows: pageSize }, page + 1);
    setPageSize(pageSize);
  }

  useEffect(() => {
    loadPage({ ...data, pageSize: pageSize, rows: pageSize }, 1);
  }, [data]);

  return <SimpleSortingTable {...props} items={items} onPageChange={handlePageChange} totalElements={totalCount} />;
}
