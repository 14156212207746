import { MuiScreenType } from 'utils/ScreenUtil';
import StringUtil from 'utils/StringUtil';

export const sidebarWidthExpanded = '200px';
export const sidebarWidthCollapsed = '60px';
export const navigationPaneWidth = '250px';
export const rightSidebarWidth = '320px';
export const headerColor = '#1976d2';
export const headerHeight = '40px';
export const bottomAppMenuBarHeight = '40px';
export const mainContentNavBoxColor = 'rgba(0, 0, 0, 0.65)';
export const appContentAreaPadding = '10px';
export const applicationBoxHeight = '100vh';
export const applicationMainContentHeight = '100%';

export const mainContentPadding = '10px';
export const mainContentBorderRadius = '5px';
export const mainContentBgColor = '#fff';

export const appContentHeaderHeight = '37px';
export const appContentDataFrameHeight = `calc(100% - ${appContentHeaderHeight})`;
export const appConentDataFrameTabHeight = '38px';
export const appContentDataFramePadding = '10px';
export const appContentDataframeContainerHeightLg = `calc(100vh - ${headerHeight} - ${appContentAreaPadding} - ${mainContentPadding} - ${appContentHeaderHeight} - ${appConentDataFrameTabHeight} - ${appContentDataFramePadding} - ${appContentDataFramePadding})`;
export const appContentDataframeContainerHeightSm = `calc(100vh - ${headerHeight} - ${bottomAppMenuBarHeight} - ${appContentAreaPadding} - ${mainContentPadding} - ${appContentHeaderHeight} - ${appConentDataFrameTabHeight} - ${appContentDataFramePadding} - ${appContentDataFramePadding})`;

export function mainContentBoxHeight() {
  const screenType = MuiScreenType.getScreenType();
  const screenHeight = MuiScreenType.getScreenHeight();
  var mainContentBoxHeight = StringUtil.getPixelToNumber(screenHeight) - StringUtil.getPixelToNumber(headerHeight) - StringUtil.getPixelToNumber(bottomAppMenuBarHeight);
  if (!MuiScreenType.isMobile(screenType)) {
    mainContentBoxHeight = StringUtil.getPixelToNumber(screenHeight) - StringUtil.getPixelToNumber(headerHeight);
  }
  return mainContentBoxHeight + 'px';
}
