import StringUtil from 'utils/StringUtil';
import * as XLSX from 'xlsx';

export class FileUtil {
  static getFileType(file) {
    if (file && StringUtil.isNotBlank(file.name)) {
      const index = file.name.lastIndexOf('.');
      if (index > 0) {
        const fileExt = file.name.substring(index + 1).toUpperCase();
        if (fileExt === 'PDF') {
          return 'PDF';
        } else if (fileExt === 'DOC' || fileExt === 'DOCX') {
          return 'DOC';
        } else if (fileExt === 'XLS' || fileExt === 'XLSX') {
          return 'EXCEL';
        } else if (fileExt === 'PPT' || fileExt === 'PPTX') {
          return 'PPT';
        } else if (fileExt === 'JPG' || fileExt === 'JPEG' || fileExt === 'PNG') {
          return 'IMAGE';
        } else if (fileExt === 'CSV') {
          return 'CSV';
        }
      }
    }
    return 'ATTACHMENT';
  }

  static isTextEditableFile(fileName) {
    const TEXT_EDITABLE_EXTENSIONS = ['txt', 'json', 'html', 'css'];

    if (fileName) {
      const extension = FileUtil.getFileType(fileName);
      return TEXT_EDITABLE_EXTENSIONS.includes(extension);
    }

    return false;
  }

  static getFileType(fileName) {
    return fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();;
  }

  static async readJsonFile(path) {
    try {
      // Fetch the JSON file from the public folder
      const response = await fetch(path);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${path}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching navigation items: ${error.message}`);
      return [];
    }
  }

  static toExcelFile(jsonData, fileName = 'data.xlsx') {
    // 1. Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // 2. Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // 3. Write workbook to a binary string
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // 4. Create a Blob from the binary string
    const blob = new Blob([excelData], { type: 'application/octet-stream' });

    return new File([blob], fileName);
  }

  static readExcelData(excelData) {
    const workbook = XLSX.read(excelData, { type: 'array' });
    // Convert the first sheet to JSON
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const cells = worksheet;
    const formulas = [];
    for (const cell in cells) {
      if (cell[0] === '!') continue; // Skip special properties
      const cellData = cells[cell];
      if (cellData.f) {
        formulas.push({
          address: cell,
          formula: cellData.f,
          value: cellData.v,
        });
      }
    }
    const json = XLSX.utils.sheet_to_json(worksheet);
    return json;
  }

  static getFileNameFromHeader(response) {
    const contentDisposition = response.headers['content-disposition'];
    if (StringUtil.isNotBlank(contentDisposition)) {
      let fileName = 'downloaded-file'; // Fallback filename
      if (contentDisposition) {
        const regesx = /filename=([^"]+)/;
        const fileNameMatch = contentDisposition.match(regesx);
        if (fileNameMatch && fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }
    }
  }
}
