import { Box, Grid, List, ListItem, MenuItem, Select, Slider, TextField, Typography } from '@mui/material';
import { useFilterContext } from 'contexts/data-filter/FilterContext';
import { useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';

const FilterComponent = () => {
  const { filters, updateFilterValue } = useFilterContext();
  const [selectedFilter, setSelectedFilter] = useState(null);

  const renderFilterControl = filter => {
    switch (filter.type) {
      case 'String':
        return <TextField fullWidth variant='outlined' value={filter.value || ''} onChange={e => updateFilterValue(filter.name, e.target.value)} />;
      case 'Numeric':
        return <TextField fullWidth type='number' variant='outlined' value={filter.value || ''} onChange={e => updateFilterValue(filter.name, e.target.value)} />;
      case 'Slider':
        return <SliderFilter filter={filter} />;
      case 'DropDown':
        return (
          <Select fullWidth value={filter.value || ''} onChange={e => updateFilterValue(filter.name, e.target.value)}>
            {filter.options.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      case 'Color':
        return <input type='color' value={filter.value || '#000000'} onChange={e => updateFilterValue(filter.name, e.target.value)} />;
      case 'FieldValue':
        return <Typography variant='body1'>{filter.value}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container sx={{ height: '100%', border: '1px solid #ccc', margin: 0 }}>
        <Grid item xs={3} sx={{ borderRight: '1px solid #ccc', paddingLeft: '0px' }}>
          <List sx={{ pt: '0px', pb: '0px' }}>
            {CollectionUtil.nullSafe(filters).map(filter => (
              <ListItem key={filter.name} onClick={() => setSelectedFilter(filter)} sx={{ borderBottom: '1px solid #ccc' }}>
                <Typography variant='body1'>{filter.name + ' (' + filter.value + ')'}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={9} sx={{ paddingLeft: '10px', paddingTop: '12px' }}>
          {selectedFilter && <Box>{renderFilterControl(selectedFilter)}</Box>}
        </Grid>
      </Grid>
    </Box>
  );
};

function SliderFilter(props) {
  const { filter } = props;
  const { updateFilterValue } = useFilterContext();

  const generateMarks = () => {
    const marks = [];
    for (let i = filter.min; i <= filter.max; i += filter.step) {
      marks.push({ value: i, label: i.toString() });
    }
    return marks;
  };

  return <Slider defaultValue={filter.value} step={filter.step} marks={generateMarks()} valueLabelDisplay='on' min={filter.min} max={filter.max} onChange={(e, newValue) => updateFilterValue(filter.name, newValue)} />;
}

export default FilterComponent;
