import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, MenuItem } from "@mui/material";
import CodeEditor from 'components/code-highlighter/CodeHighlighter';
import CopyToClipboard from 'components/CopyToClipboard';
import { FullWidthSideDrawer } from 'components/Drawer/Drawer';
import { SaveIconButton } from 'components/Icons';
import { DropDownMenu } from "components/Menu/Menu";
import { ContainerButtons } from 'components/TitleBar/Titlebar';
import useFileSystem from "hooks/filesystem/useFileSystem";
import { useEffect, useState } from "react";
import { FileUtil } from 'utils/FileUtil';

export function AttachmentArtifactMenu({item, onRename}) {
    const {downloadObject, deleteObject} = useFileSystem();
    const [showEditor, setShowEditor] = useState();
    
    const downloadFile = async () => {
        try {
          const response = await downloadObject({url : "/attachment/download", systemCode : item.systemCode, type : item.type});

          // Extract the filename from the server's Content-Disposition header
          const contentDisposition = response.headers["content-disposition"];
          let fileName = "downloaded-file"; // Fallback filename
          if (contentDisposition) {
            const regesx = /filename=([^"]+)/;
            const fileNameMatch = contentDisposition.match(regesx);
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
          }
          // Create a link element to trigger the download
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      };
    return <>
            {showEditor && 
            <FullWidthSideDrawer anchor="right" rightToolBarId={item.systemCode} headerTitle={item.name} open={showEditor} onClose={() => setShowEditor(false)}>
                <AttachmentEditor item={item}/>
            </FullWidthSideDrawer>
            }

            <DropDownMenu menuIcon={<MoreVertIcon />}>
                <MenuItem onClick={() => {
                    onRename(item)
                }}>Rename</MenuItem>
                <MenuItem onClick={() => downloadFile()}>
                    Download
                </MenuItem>
                {FileUtil.isTextEditableFile(item.name) 
                && 
                  <MenuItem onClick={() => setShowEditor(true)}>
                    Edit
                  </MenuItem>
                }
                <CopyToClipboard value={item.handle}>
                  <MenuItem>
                      Copy Handle
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem onClick={() => {
                    deleteObject({systemCode : item.systemCode, type : item.type}).then(() => alert("Deletion Successful."))
                }}>Delete</MenuItem>

            </DropDownMenu>
    </>
}


function AttachmentEditor({item}) {
  const {loadObject, editObject} = useFileSystem();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState();

  useEffect(() => {
    if(FileUtil.isTextEditableFile(item.name)) {
      setLoading(true)
      loadObject({url : "/attachment/download", systemCode : item.systemCode, type : item.type})
      .then((res) => {
        try {
          const prettyJSON = JSON.stringify(res, null, 2); // Beautify with 2 spaces indentation
          setContent(prettyJSON);
        }
        catch {
          setContent(res)
        }

        setLoading(false)
      });
    }
  }, [])

  function onSubmit() {
    setLoading(true)
    editObject({url : "attachment/updateContent", systemCode : item.systemCode, type : item.type, content : content}).then(() => setLoading(false))
  }

  return <Box>
          <CodeEditor language={FileUtil.getFileType(item.name)} value={content} onChange={(value) => setContent(value)} />
          <ContainerButtons buttons={[<SaveIconButton  onClick={() => onSubmit()}/>]} containerId={item.systemCode}/>
    </Box>
}