import grapesjs from "grapesjs";
import basicBlocks from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import 'grapesjs/dist/css/grapes.min.css';
import { useEffect, useState } from "react";
import UrlUtil from "utils/UrlUtils";

export function GrapeContainer({onStore, onLoad, onAssetUpload, onLoadAsset}) {
    const [initialized, setInitialized] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [htmlCss, setHtmlCss] = useState('');
    const [editor, setEditor] = useState();
    /**
     * Adds the icon, that opens a modal, and makes code editable.
     * @param {*} e The editor
     */
    function addCodeEditIcon(e) {
      var pfx = e.getConfig().stylePrefix;
      var modal = e.Modal;
      var cmdm = e.Commands;
      var htmlCodeViewer = e.CodeManager.getViewer('CodeMirror').clone();
      var cssCodeViewer = e.CodeManager.getViewer('CodeMirror').clone();
      var pnm = e.Panels;
      var container = document.createElement('div');
      var btnEdit = document.createElement('button');
      
      htmlCodeViewer.set({
          codeName: 'htmlmixed',
          readOnly: 0,
          theme: 'hopscotch',
          autoBeautify: true,
          autoCloseTags: true,
          autoCloseBrackets: true,
          lineWrapping: true,
          styleActiveLine: true,
          smartIndent: true,
          indentWithTabs: true,
          title : 'HTML'
      });

      cssCodeViewer.set({
          codeName: 'css',
          readOnly: 0,
          theme: 'hopscotch',
          autoBeautify: true,
          autoCloseTags: true,
          autoCloseBrackets: true,
          lineWrapping: true,
          styleActiveLine: true,
          smartIndent: true,
          indentWithTabs: true,
          title : 'HTML'
      });
      
      btnEdit.innerHTML = 'Update';
      btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
      btnEdit.onclick = function() {
          var code = htmlCodeViewer.editor.getValue();
          setHtmlContent(code.trim());

          var css = cssCodeViewer.editor.getValue();
          setHtmlCss(css.trim());

          modal.close();
      };
      
      cmdm.add('html-edit', {
          run: function(editor, sender) {
              sender && sender.set('active', 0);
              modal.setTitle('Edit code');
              
              var htmlViewer = htmlCodeViewer.editor;
              if (!htmlViewer) {
                  const htmlContainer = document.createElement('div')
                  htmlContainer.className = "gjs-cm-editor-c"
                  htmlContainer.id = "gjs-cm-htmlmixed"

                  const htmlContainerTitle = document.createElement('div')
                  htmlContainerTitle.id = "gjs-cm-title"
                  htmlContainerTitle.innerHTML = "HTML"

                  const htmlContainerBody = document.createElement('div')
                  htmlContainerBody.id = "gjs-cm-code"

                  var txtarea = document.createElement('textarea');
                  htmlContainerBody.appendChild(txtarea);
                  htmlCodeViewer.init(txtarea);
                  htmlViewer = htmlCodeViewer.editor;

                  htmlContainer.appendChild(htmlContainerTitle)
                  htmlContainer.appendChild(htmlContainerBody)
                  container.appendChild(htmlContainer)
              }

              var cssViewer = cssCodeViewer.editor;
              if (!cssViewer) {
                  const cssContainer = document.createElement('div')
                  cssContainer.className = "gjs-cm-editor-c"
                  cssContainer.id = "gjs-cm-css"

                  const cssContainerTitle = document.createElement('div')
                  cssContainerTitle.id = "gjs-cm-title"
                  cssContainerTitle.innerHTML = "CSS"

                  const cssContainerBody = document.createElement('div')
                  cssContainerBody.id = "gjs-cm-code"
                  
                  var txtarea = document.createElement('textarea');
                  cssContainerBody.appendChild(txtarea);
                  cssCodeViewer.init(txtarea);
                  cssViewer = htmlCodeViewer.editor;

                  cssContainer.appendChild(cssContainerTitle)
                  cssContainer.appendChild(cssContainerBody)
                  container.appendChild(cssContainer)
              }

              container.appendChild(btnEdit);

              var InnerHtml = editor.getHtml();
              var css = editor.getCss();
              modal.setContent('');
              modal.setContent(container);

              htmlCodeViewer.setContent(InnerHtml);
              cssCodeViewer.setContent(css);
              modal.open();
              htmlViewer.refresh();
              cssViewer.refresh();
          }
      });
      
      pnm.addButton('options',
          [
              {
                  id: 'edit',
                  className: 'fa fa-edit',
                  command: 'html-edit',
                  attributes: {
                      title: 'Edit'
                  }
              }
          ]
      );
    }

    useEffect(() => {
        let e = editor
        if(!initialized) {
            e = grapesjs.init({
              // Indicate where to init the editor. You can also pass an HTMLElement
              container: '#gjs',
              plugins: [basicBlocks, gjsForms],
              pluginsOpts : {
                [basicBlocks] : {
                  blocks : ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video']
                }
              },
              storageManager: {
                type: 'remote',
                stepsBeforeSave: 2,
              },
              assetManager: {
                upload: false, // Disable built-in uploader
                assets: [], // Preloaded assets (optional)
                uploadFile: async (event) => {
                  const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

                  const res = await onAssetUpload(Array.from(files));
                  
                  res.files.forEach((file) => {    
                    const uploadedUrl = UrlUtil.getPanelUrl(file.baseUrl + file.fileName)
                    e.AssetManager.add({ src: uploadedUrl });
                  });
                }
              }
            });
            
            e.Storage.add('remote', {
              async load() {
                if(onLoad && typeof(onLoad) === 'function') {
                  onLoad().then(res => {
                    setHtmlContent(res.overview)
                    setHtmlCss(res.css)
                  });
                }
              },

              async store(data) {
                const component = e.Pages.getSelected().getMainComponent();
                const html = e.getHtml({ component })
                const css = e.getCss({ component })
                
                return await onStore({ data,  html : html, css : css})
              }
            });

          // Following is the RnD/POC to add custom component.
            e.BlockManager.add('faq-component', {
              label: 'FAQ',
              content: `<div class="faq-component">
                          <h3>Loading...</h3>
                          <p>Please enter the FAQ code.</p>
                        </div>`,
              attributes: { class: 'fa fa-arrows' },
              category: 'Blocks',
            });
            
            e.Components.addType('faq-component', {
              isComponent: el => el.className === 'faq-component',
              model: {
                defaults: {
                  traits: [
                    'handle', 
                    ],
                  attributes: { type: 'text', required: true },
                },
                init() {
                  this.on('change:attributes:handle', (child) => console.log(this.getAttributes()['handle']));
                },            
              },
          });   
            
            addCodeEditIcon(e)
            setInitialized(true)
            setEditor(e);
            
        }
    }, [])

    useEffect(() => {
      if(initialized) {
        const loadAsset = async() => {
          if(onLoadAsset && typeof(onLoadAsset) === 'function') {
            const res = await onLoadAsset();
  
            const files = res.rows;
            files && files.filter(file => file.type === 'PICTURE_ARTIFACT')
            .forEach(file => {
              const uploadedUrl = UrlUtil.getPanelUrl(file.contextInfo.url)
              editor.AssetManager.add({ src: uploadedUrl });
            })
          }
        }

        loadAsset();
      }
    })
    useEffect(() => {
      if(initialized) {
        editor.setComponents(htmlContent);
      }
    }, [htmlContent])

    useEffect(() => {
      if(initialized) {
        editor.setStyle(htmlCss);
      }
    }, [htmlCss])
    
    return (
            <div id="gjs"></div>
    );
}