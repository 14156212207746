import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const operations = { name : 'Operations',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Inventory Management',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Pools"
                }, {
                    name : "Stock States"
                }, {
                    name : "Bookables"
                }, {
                    name : "Default MDM"
                }
            ]
        }, {
            name : 'Channel Network',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "CSA / C&F"
                }
            ]
        }, {
            name : 'After-sales',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Service Network"
                }, {
                    name : "Warranty Management"
                }, {
                    name : "AMC Management"
                }
            ]
        }, {
            name : 'Purchase',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Portfolios / PIM / Brands"
                }, {
                    name : "Vendor Segmentation"
                }, {
                    name : "Supplier Party Master"
                }, {
                    name : "Spend Analysis"
                }
            ]
        }, {
            name : 'Manufacturing',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Production BOMs"
                }
            ]
        }
    ],
  }