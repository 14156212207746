import { Email, LocationOn, Phone } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { SideDrawer } from 'components/Drawer/Drawer';
import { HorizontalTab, UiTabContext, UiTabPanel } from "components/Tabs";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { SubscriptionListingPage } from '../subscription/SubscriptionList';
import { AccountEdit } from './AccountEdit';
import UserProfileCard from 'components/user-profile/UserProfileCard';

const ACCOUNT_REVIEW="/cloud/account/review?ac="

export function AccountReview({systemCode}) {
    const [accountDetails, setAccountDetails]= useState();
    const {get} = useHttp()
    useEffect(() => {
        get(ACCOUNT_REVIEW + systemCode).then(res => {
            setAccountDetails(res);
        })
    }, [])
    const tabs = [
        <HorizontalTab label="Profile" value="profile" key="profile"/>,
        <HorizontalTab label="Entitlements" value="entitlements" key="entitlements"/>,
    ]


    return accountDetails && (
        <Box>
            <UiTabContext tabs={tabs} defaultTab={"profile"}>
                <UiTabPanel value="profile" >
                    <Profile accountDetails={accountDetails}/>
                </UiTabPanel>
                <UiTabPanel value="entitlements" >
                    <SubscriptionListingPage accountCode={accountDetails.systemCode}/>
                </UiTabPanel>
            </UiTabContext>      
        </Box>
      );
};
    

function Profile({accountDetails}) {
    const [editable, setEditable] = useState();
    return <Grid container spacing={2} sx={{ mt: 2 }}>
    {/* Sidebar Card */}
    <Grid item xs={12} sm={4}>
      <Card>
        <CardContent>
          <Grid container direction="column" alignItems="center">
            <Avatar sx={{ width: 80, height: 80, mb: 1 }} src="https://via.placeholder.com/80" />
            <Typography variant="h6">{accountDetails.name}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              @{accountDetails.virtualId}
            </Typography>
            <Box
              sx={{
                bgcolor: '#1976d2',
                color: '#fff',
                borderRadius: '15px',
                px: 2,
                py: 0.5,
                mt: 1,
                fontSize: '0.8rem',
              }}
            >
              {accountDetails.state}
            </Box>
            
            <List dense sx={{ width: '100%', mt: 2 }}>
              <ListItem>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText primary="Email" secondary={accountDetails.legalEmailAddress} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary="Phone" secondary={accountDetails.phone1} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationOn />
                </ListItemIcon>
                <ListItemText primary="Location" secondary={accountDetails.registeredAddress.singleLine} />
              </ListItem>
            </List>

            {/* <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Typography variant="h6" align="center">37</Typography>
                <Typography variant="body2" align="center">Mails</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" align="center">2749</Typography>
                <Typography variant="body2" align="center">Followers</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" align="center">678</Typography>
                <Typography variant="body2" align="center">Following</Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </Grid>

    {/* Main Profile Details */}
    <Grid item xs={12} sm={8}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Company Details</Typography>
            <IconButton onClick={() => setEditable(true)}>
                <EditIcon fontSize="small" />
            </IconButton>
          {editable && <SideDrawer anchor="right" headerTitle={accountDetails.name} open={editable} onClose={() => setEditable(false)}>
                <AccountEdit details={accountDetails}/>
            </SideDrawer>
            }
          </Box>
          <Divider sx={{ my: 2 }} />          
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Short code</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{accountDetails.shortCode ? accountDetails.shortCode : "-"}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Reference code</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{accountDetails.referenceCode}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Legal Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{accountDetails.legalName}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Inception Date</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{accountDetails.inceptionDate}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Handle</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>@{accountDetails.virtualId}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Phone</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                    {accountDetails.phone1 ? accountDetails.phone1 : ""}
                    {accountDetails.phone2 ? ", " + accountDetails.phone2 : ""}
                    {accountDetails.phone3 ? ", " + accountDetails.phone3 : ""}
                    {accountDetails.phone4 ? ", " + accountDetails.phone4 : ""}
                </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Website</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{accountDetails.website1}</Typography>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
      
      {/* Administrators */}
      <Card sx={{ mb: 2 }}>
        <CardContent>          
            <Typography variant="h6">Administrators</Typography>
            <Divider sx={{ my: 2 }} />          
            {accountDetails.administrators.map((admin, index) => {
                return <UserProfileCard user={admin} key={index}/>
                })}
        </CardContent>
      </Card>
      {/* Authorizers */}
      <Card sx={{ mb: 2 }}>
        <CardContent>          
            <Typography variant="h6">Authorizers</Typography>
            <Divider sx={{ my: 2 }} />          
            {accountDetails.authorizers.map((admin, index) => {
                return <UserProfileCard user={admin} key={index}/>
                })}
        </CardContent>
      </Card>
    </Grid>
    
 </Grid>
}