import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const networks = { name : 'Networks',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Planning Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }