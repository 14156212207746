import React from "react";

export default function CopyToClipboard(props) {
    const {value} = props;

    function copy(e) {
        const onClick = props.children.props.onClick;
        navigator.clipboard.writeText(value)

        if(onClick && typeof(onClick) === 'function') {
            onClick(e);
        }
    }
    const children = React.cloneElement(props.children, {
        onClick : copy
    });

    return children
}