import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StorageIcon from '@mui/icons-material/Storage';
import ShieldIcon from '@mui/icons-material/Shield';
import UserDrive from 'views/drive/UserDrive';
const items = [
    {
      "name": "Admin Centre",
      "icon": <AdminPanelSettingsIcon />,
      "menu" : "corporate/admin.json",
      "permission" : "AdministerAccount"
    },
    {
      "name": "Drives",
      "icon": <StorageIcon />,
      "loadFromServer" : true,
      "component" : <UserDrive />,
      "permission" : "User",
    },
    {
      "name": "System Administration",
      "icon": <ShieldIcon />,
      "menu" : "platform/admin.json",
      "permission" : "AdministerSystem"
    }
  ]

  export default items;