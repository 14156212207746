import { Box } from '@mui/system';
import { ScreenUtil } from 'utils/ScreenUtil';

export function HotGrid280(props) {
  const { sx, children, ...other } = props;
  return (
    <Box component='div' {...other} sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: { sm: 'repeat(auto-fit, minmax(280px, 1fr))', md: 'repeat(auto-fit, minmax(280px, 0.5fr))' }, ...sx }}>
      {props.children}
    </Box>
  );
}

export function HotGridMin280Max50Pc(props) {
  const { sx, children, ...other } = props;
  const maxGridWidth = ScreenUtil.getListNuggetMaxWidthPc();
  return (
    <Box component='div' {...other} sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 49%))', ...sx }}>
      {props.children}
    </Box>
  );
}

export function HotGrid150(props) {
  const { children, sx, ...other } = props;
  return (
    <Box component='div' {...other} sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 150px))', ...sx }}>
      {props.children}
    </Box>
  );
}

export function HotGrid180(props) {
  const { children, sx, ...other } = props;
  return (
    <Box component='div' {...other} sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))', ...sx }}>
      {props.children}
    </Box>
  );
}

export function HotGrid140(props) {
  const { children, sx, ...other } = props;
  return (
    <Box component='div' {...other} sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))', ...sx }}>
      {props.children}
    </Box>
  );
}
