import { FormBox } from "components/Box/Box";
import { HotGrid180 } from "components/Containers/Grid";
import { FormInput, FormRadio } from "components/Form/FormInputs";
import useHttp from "hooks/useHttp";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Divider, RadioGroup, TextField } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const ACCOUNT_REVIEW="/cloud/account/review/submit?ac="

const validationSchema = Yup.object().shape({
  shortCode: Yup.string()
  .max(4, "Short code cannot exceed 4 characters")
  .required("Short code is required!"),
  name: Yup.string().required("Name is required!"),
  legalName: Yup.string().required("Legal Name is required!"),
  virtualId: Yup.string().required("Handle is required!"),
});

export function AccountEdit({details}) {
    const [accountDetails, setAccountDetails]= useState();
    const [loading, setLoading] = useState();
    const {post} = useHttp();

    useEffect(() => {
        setAccountDetails(details);
    }, [])

    function handleFormSubmit(values) {
      setLoading(true)
      post(ACCOUNT_REVIEW + accountDetails.systemCode, values).then(() => setLoading(false))
    }

    return accountDetails && (
      <Formik
      onSubmit={handleFormSubmit}
      initialValues={accountDetails}
      validationSchema={validationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormBox>
            <Card>
              <CardContent>
                <HotGrid180>
                  <FormInput 
                    name="shortCode"
                    label="Short Code"
                    onBlur={handleBlur}
                    value={values.shortCode}
                    onValueChange={handleChange}
                    helperText={touched.shortCode && errors.shortCode}
                    error={Boolean(errors.shortCode && touched.shortCode)}
                  />
                  <FormInput 
                    name="name"
                    label="Name"
                    onBlur={handleBlur}
                    value={values.name}
                    onValueChange={handleChange}
                    helperText={touched.name && errors.name}
                    error={Boolean(errors.name && touched.name)}
                  />
                  <FormInput 
                    name="legalName"
                    label="Legal Name"
                    onBlur={handleBlur}
                    value={values.legalName}
                    onValueChange={handleChange}
                    helperText={touched.legalName && errors.legalName}
                    error={Boolean(errors.legalName && touched.legalName)}
                  />
                  <FormInput 
                    name="virtualId"
                    label="Handle"
                    onBlur={handleBlur}
                    value={values.virtualId}
                    onValueChange={handleChange}
                    helperText={touched.virtualId && errors.virtualId}
                    error={Boolean(errors.virtualId && touched.virtualId)}
                  />
                </HotGrid180>
                <Divider />
                  <RadioGroup row value={values.state}>
                      <FormRadio value={'ACTIVE'} label="Accept" />
                      <FormRadio value={'REJECTED'} label="Reject"/>
                  </RadioGroup>
                  <TextField 
                    fullWidth
                    name="comment"
                    label="Comment"
                    onBlur={handleBlur}
                    value={values.comment}
                    onChange={handleChange}
                  />
                <LoadingButton
                    type="submit"
                    color="primary"
                    loading={loading}
                    variant="contained"
                    sx={{ my: 2 }}>
                    Save
                </LoadingButton>   
              </CardContent>
            </Card>
          </FormBox>
        </form>
      )}     
      </Formik>
      );
};
  