import _ from "lodash"

/**
 * This is utility class object related common functions
 */
export class ObjectUtil {

    /**
     * It will set the give value for given property of the given object.
     * @param {*} object Object whose prrperty has to be changed
     * @param {*} propertyName Name of property
     * @param {*} value Value of property
     */
    static setProperty(object, propertyName, value) {
        if(!propertyName.includes(".")) {
            object[propertyName] = value;
        } else {
            var schema = object;
            var pathsList = propertyName.split(".")
            var len = pathsList.length;
            for(var i = 0; i < len-1; i++) {
                var path = pathsList[i];
                if( !schema[path] ) {
                    schema[path] = {}
                } else {
                    schema = schema[path];
                }
            }
            schema[pathsList[len-1]] = value;
        }
    }

    /**
     * It gets the value of given property of given object
     * @param {*} object Object whose prrperty has to be read
     * @param {*} propertyName Name of property to read
     * @returns Value of specified property from given object
     */
    static getProperty(object, propertyName) {
        if(!propertyName.includes(".")) {
            return object[propertyName];
        } else {
            var temp = object;
            var pathsList = propertyName.split(".")
            var len = pathsList.length;
            for(var i = 0; i < len; i++) {
                var path = pathsList[i];
                temp = temp[path];
                if(!temp) {
                    break;
                }
            }
            return temp;
        }
    }

    /**
     * It will do deep merge of given objects
     * @param {*} obj1 
     * @param {*} obj2 
     * @returns 
     */
    static deepMerge(obj1, obj2) {
        return _.merge(obj1, obj2);
    }

    static createNewWithDeepMerge(obj1, obj2) {
        return _.merge({},_.merge(obj1, obj2));
    }

    static updateArrayValueAtField(obj, fieldName, arrayIndex, newValue) {
        if (typeof obj !== 'object' || obj === null) {
          throw new Error('Invalid input: The first parameter should be a valid object.');
        }
      
        // Check if the field exists and is an array
        if (obj.hasOwnProperty(fieldName) && Array.isArray(obj[fieldName])) {
          // Create a copy of the array to maintain immutability
          const updatedArray = [...obj[fieldName]];
      
          // Replace the value at the specified index
          updatedArray[arrayIndex] = newValue;
      
          // Create a new object with the updated field value
          return { ...obj, [fieldName]: updatedArray };
        }
      
        // If the field doesn't exist or is not an array, return the original object
        return obj;
    }

    static addToArrayAtField(obj, fieldName, newValue) {
        if (typeof obj !== 'object' || obj === null) {
          throw new Error('Invalid input: The first parameter should be a valid object.');
        }
      
        // Check if the field exists and is an array
        if (obj.hasOwnProperty(fieldName) && Array.isArray(obj[fieldName])) {
          // Create a copy of the array to maintain immutability
          const updatedArray = [...obj[fieldName]];
      
          // Add the new value to the end of the array
          updatedArray.push(newValue);
      
          // Create a new object with the updated field value
          return { ...obj, [fieldName]: updatedArray };
        }
      
        // If the field doesn't exist or is not an array, return the original object
        return obj;
    }

    static insertOrUpdateValueAt(inputArr, index, newValue) {
        if (inputArr === null || inputArr === undefined) {
          throw new Error("inputArr must not be null or undefined.");
        }
      
        if (!Array.isArray(inputArr)) {
          throw new Error("inputArr must be an array.");
        }
      
        if (index >= 0 && index < inputArr.length) {
            // Update value at the existing index
            const updatedArray = [...inputArr];
            updatedArray[index] = newValue;
            return updatedArray;
        } else if (index === inputArr.length) {
            // Insert value at the end of the array
            return [...inputArr, newValue];
        } else {
            // Invalid index for updating or inserting
            throw new Error("Invalid index.");
        }
      }

    static toQueryParam(filters) {
        return Object.keys(filters).map(key=> key + "=" + filters[key]).join("&");;
    }
    
    /**
     * It return the sorting function that can be used to sort an json object based on its property
     * https://www.c-sharpcorner.com/UploadFile/fc34aa/sort-json-object-array-based-on-a-key-attribute-in-javascrip/
     * @param {*} prop 
     * @returns 
     */
    static getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    static updateStateObjectValue(object, fieldName, fieldValue) {
        var existingObject = { ...object }
        ObjectUtil.setProperty(existingObject, fieldName, fieldValue);
        return existingObject;
    }

    static addElementInStateObjectField(object, arrayFieldName, valueToAddInArray) {
        var existingArr = object[arrayFieldName];
        if(!existingArr) {
            existingArr = []
        };
        const flattenedArray = [...existingArr]
        flattenedArray.push(valueToAddInArray)
        return ObjectUtil.updateStateObjectValue(object, arrayFieldName, flattenedArray);
    }

    static nullSafe(val, defaultVal) {
        return val ? val : defaultVal;
    }

    /**
     * Checks if a variable contains valid JSON.
     * @param {string} jsonString - The variable to check.
     * @returns {boolean} - True if the variable contains valid JSON, otherwise false.
     */
    static isValidJSON(jsonString) {
        try {
        JSON.parse(jsonString);
        return true; // If parsing succeeds, it's valid JSON
        } catch (error) {
        return false; // If an error is thrown, it's not valid JSON
        }
    }

    static isReactComponent(component) {
        return (
            ObjectUtil.isClassComponent(component) || ObjectUtil.isFunctionComponent(component)
          );
    }
    
    static isClassComponent(component) {
        return (
            typeof component === 'function' &&
            component.prototype &&
            component.prototype.isReactComponent
          );
    }
    
    static isFunctionComponent(component) {
        return (
            typeof component === 'function' &&
            String(component).includes('return React.createElement')
          );
    }
}