import { ListItemButton, ListItemText } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from 'react-i18next';
import ObjectList from "components/object-list/ObjectList";
import { PageableDataManagerProvider } from "contexts/datamanager/PageableDataManagerContext";
import { useNavigate } from "react-router-dom";

const PAGE_URL = "/cloud/drive/page"
export default function UserDrive() {
    const { t } = useTranslation();
    const router = useNavigate();

    function showDetails(item) {
        router('/folders/' + item.systemCode + "/" + item.name)
    }


    function nuggetTemplate(item) {
        return <ListItemButton onClick={() => showDetails(item)} key={item.systemCode}>
                    <ListItemText primary={item.name} />
                </ListItemButton>
    }

    const containerRef= useRef();
    return (
        <>
            <div ref={containerRef}>
                <PageableDataManagerProvider url={PAGE_URL} eventName={'DRIVE__CREATED'}>
                    <ObjectList
                        hideSkeleton
                        ref={containerRef}
                        nuggetTemplate={nuggetTemplate}
                        onRowClick={(item) => showDetails(item)}
                        headCells={[
                            {    
                                id: 'systemCode',
                                numeric: false,
                                label: '',
                                formatter : (item) => <i className="fa fa-hdd" />,
                                nuggetInfo : {
                                    order : 6,
                                    type : 'subtitle'
                                }
                            }, 
                            {    
                                id: 'referenceCode',
                                numeric: false,
                                label: 'Reference code',
                                nuggetInfo : {
                                    order : 3,
                                    type : 'subtitle'
                                }
                            },
                            {    
                                id: 'name',
                                numeric: false,
                                label: 'Name',
                                nuggetInfo : {
                                    order : 1,
                                    type : 'title'
                                }
                            },
                            {    
                                id: 'createdAt',
                                numeric: false,
                                label: 'Created At',
                                nuggetInfo : {
                                    order : 4,
                                    type : 'date'
                                }
                            },
                            {    
                                id: 'state',
                                numeric: false,
                                label: 'Status',
                                nuggetInfo : {
                                    order : 5,
                                    type : 'subtitle'
                                }
                            }
                            ]} />
                </PageableDataManagerProvider>
            </div>
        </>
    )
}
