import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Card, Grid, styled, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as Yup from 'yup';
import useHttp from "hooks/useHttp";
import { LoadingButton } from "@mui/lab";
import { Paragraph } from "components/Typography";

// initial login credentials
const initialValues = {
  principal: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  principal: Yup.string().email("Invalid Email address").required("Email is required!")
});

// STYLED COMPONENTS
const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1A2038",
  minHeight: "100vh !important",

  "& .card": {
    maxWidth: 800,
    margin: "1rem",
    borderRadius: 12
  },

  ".img-wrapper": {
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default
}));

export default function ForgotPasswordRequest() {
  const navigate = useNavigate();
  const {post} = useHttp();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  const handleFormSubmit = (values) => {
    setLoading(true)
    post("/credential/recover", values).then(res => {
      setLoading(false)

      if(res && res.data && res.data.errors?.length > 0) {

      }
      else {
        setSuccess(true)
      }
    })
  };

  return (
    <StyledRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <ContentBox>
              {success 
                ?  <Paragraph>
                  We have sent you a link on your email id, Please click on the link to reset your credentials.
                  <NavLink to='/session/signin' style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                    Login
                  </NavLink>
                </Paragraph>
              : <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    size="small"
                    type="email"
                    name="principal"
                    label="Email"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.principal}
                    onChange={handleChange}
                    helperText={touched.principal && errors.principal}
                    error={Boolean(errors.principal && touched.principal)}
                    sx={{ mb: 3 }}
                  />
                  <LoadingButton fullWidth type='submit' color='primary' loading={loading} variant='contained' >
                    Reset Password
                  </LoadingButton>

                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    sx={{ mt: 2 }}>
                    Go Back
                  </Button>
                </form>
              )}
          </Formik>}
              

            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
  );
}
