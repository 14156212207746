import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const commercial = { name : 'Commercial',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Default Pricing',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Domestic"
                }, {
                    name : "International"
                }
            ]
        }, {
            name : 'Default Tax Rates',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "By HSN / By Brand / By SKU"
                }
            ]
        }, {
            name : 'Default Terms of Trade',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Return Days"
                }, {
                    name : "Replacement"
                }, {
                    name : "Terms"
                }
            ]
        }, {
            name : 'Distribution Network',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Customers',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Party Master"
                }, {
                    name : "Credit Control"
                }, {
                    name : "Customer Segmentation"
                }
            ]
        }, {
            name : 'Customers (retail)',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : "Default Trade Settings"
                }, {
                    name : "Generate list from past order history"
                }
            ]
        }
    ],
  }