import { Box, Stack } from "@mui/material";
import { SideDrawer } from "components/Drawer/Drawer";
import { MenuItem } from "components/properties/menu-item";
import useFileSystem from "hooks/filesystem/useFileSystem";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { HtmlBlockPreview } from "views/block/html/HtmlBlockPreview";

export function RenderHtml({html, data, meta}) {
    const [indexes, setIndexes] = useState();
    const [processed, setProcessed] = useState();
    const [domChanged, setDomChanged] = useState();
    const [drawers, setDrawers] = useState([]);

    // Process the template
    const renderHtml = () => {
        if(html) {
            // Replace placeholders in the format {{key}} with corresponding values from the data object
            return html.replace(/{{\s*([\w.]+)\s*}}/g, (match, key) => {
                // Support nested keys (e.g., "user.name")
                const value = key.split('.').reduce((acc, curr) => acc && acc[curr], data);
                return value !== undefined ? value : match; // If key not found, keep the placeholder
            });
        }
    };
    // Process the template
    const processedHtml = renderHtml();

    function createIndexes() {
        // Find all target div elements
        const indexes = [];
        const heading1 = document.querySelectorAll('.heading-1');
        heading1.forEach((h1) => {
            // Replace the div content with the React component
            const index = {}
            
            index.name = h1.getAttribute("data-title");
            index.e = h1
            
            const heading2 = h1.querySelectorAll('.heading-2')

            const children = []
            heading2.forEach((h2) => {
                const index2 = {}
                
                index2.name = h2.getAttribute("data-title");
                index2.e = h2
                
                children.push(index2)
            })

            if(children && children.length > 0) {
                index.children = children
            }

            indexes.push(index);
        });

        setIndexes(indexes)
    }

    function processSideDrawer() {
        const sideDrawers = document.querySelectorAll('h-drawer');
        sideDrawers.forEach(element => {
            const e = document.createElement('span');
            e.setAttribute("class", "side-drawer")
            e.setAttribute("data-handle", element.getAttribute("handle"))
            e.innerHTML = element.innerHTML
            
            element.replaceWith(e);
        })

        if(sideDrawers && sideDrawers.length > 0) {
            setDomChanged(true)
        }
    }

    useEffect(() => {
        if(domChanged) {
            const sideDrawers = document.querySelectorAll('span.side-drawer');
            const buffer = []
            sideDrawers.forEach(e => buffer.push(e))
            setDrawers(buffer);
            setDomChanged(false);
        }
    }, [domChanged])

    useEffect(() => {
        if(!processed) {
            if(meta?.createIndex) {
                createIndexes()
            }

            processSideDrawer()

            setProcessed(true)
        }
    }, [processed]);

    return <Box sx={{
        display : 'flex'
    }}>
        {html && <div dangerouslySetInnerHTML={{ __html: processedHtml }} style={{ flexGrow : 1 }}></div>}
        {indexes && <Box sx={{
            width : "200px"
        }}>
            {indexes.map((e, i) => <Index index={e} key={i} />)}

        {
            drawers && drawers.map((element, index) => createPortal(<ConvertToDrawer element={element} key={index}/>, element))
        }
    
        </Box>}
    </Box>
}

function Index({index}) {
    function bringToView(element) {
        element.e.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    return index && <Stack>
        <MenuItem item={index} onClick={(element) => bringToView(element)} />
        {/* {index.children && index.children.map((child, index) => <ListItemButton key={index} sx={{pl : 3}}>
            {child.title}
        </ListItemButton>)} */}
    </Stack>
}

function ConvertToDrawer({element}) {
    const [showDrawer, setShowDrawer] = useState(); 
    const {loadMeta} = useFileSystem();
    const [meta, setMeta] = useState();

    useEffect(() => {
        // Attach an onClick event handler to the span
        element.addEventListener('click', () => {
            setShowDrawer(true)
        });

        const handle = element.getAttribute("data-handle")

        if(handle) {
            loadMeta({url : "file-object/meta", handle : handle}).then(res => setMeta(res));
        }

    }, [element])

    return <SideDrawer anchor="right" open={showDrawer} onClose={() => setShowDrawer(false)}>
        {meta && <DynamicComponentByType item={meta}/>}
    </SideDrawer>;
}

function DynamicComponentByType({item}) {
    switch(item.type) {
        case 'HTML_BLOCK' : return <HtmlBlockPreview item={item} />
        default : return <LoadDataAndRender item={item} />
    }
}

function LoadDataAndRender({item}) {
    const [html, setHtml] = useState();
    const {loadObject} = useFileSystem();
    useEffect(() => {
        loadObject({url : "/attachment/download", systemCode : item.systemCode, type : item.type})
        .then(res => setHtml(res))
    }, [])
    
    return html && <RenderHtml html={html}/>
}