import { Grid } from '@mui/material';
import { NuggetSkeleton } from 'components/object-list/Nuggets/Skeleton';
import usePageableDataManager from 'hooks/datamanager/usePageableDataManager';
import { useEffect, useState } from 'react';
import HtmlUtil from 'utils/HtmlUtil';

/**
 * A scrollable list that loads a new page, when reaches the bottom of the scrollable area.
 * @param props The dependencies for this scrollable list to work.
 */
export function PageableOnScrollList(props) {
  return <PageableOnScroll containerClass={'applist-container'} {...props} />;
}

export function PageableOnScroll(props) {
  const { containerClass, nuggetTemplate, onPageRender, children, scrollableContainer, hideSkeleton, cardView } = props;
  const { loadFirstPage, nextPage, items, loading } = usePageableDataManager();
  const pageSize = 10;
  const staticSkeletons = [];
  for (var i = 0; i < pageSize; i++) {
    staticSkeletons.push(<NuggetSkeleton key={i} />);
  }

  const [previousData, setPreviousData] = useState(null);

  var data = { ...props.data };
  const dataString = JSON.stringify(props.data);

  if (previousData) {
    if (dataString !== previousData) {
      setPreviousData(dataString);
      // Reset page index, if data parameter has been changed.
      loadFirstPage(data);
    }
  } else {
    if (dataString !== previousData) {
      setPreviousData(dataString);
    }
  }

  /**
   * Handles scroll for the container.
   * @param {*} event The scroll event.
   */
  function handleScroll(event) {
    if (HtmlUtil.isScrollAtEnd(event.target)) {
      nextPage(data);
    }
  }

  useEffect(() => {
    loadFirstPage(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (scrollableContainer && scrollableContainer.current) {
      const container = scrollableContainer.current;
      container.addEventListener('scroll', handleScroll);
    }
  }, [previousData]);

  function getNormalNugget() {
    return getChildren();
  }

  function getChildren() {
    return (
      <>
        {items.map((item, index) => {
          return nuggetTemplate(item, index);
        })}
        {loading && !hideSkeleton && staticSkeletons}
      </>
    );
  }

  const cards = Array.from({ length: 20 }, (_, i) => ({
    id: i + 1,
    title: `Card ${i + 1}`,
    content: `This is card number ${i + 1}.`,
  }));

  if (cardView) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)', // Mobile
            sm: 'repeat(2, 1fr)', // Tablet
            md: 'repeat(3, 1fr)', // Desktop
            lg: 'repeat(4, 1fr)', // Large Desktop
            xl: 'repeat(5, 1fr)', // TV or Extra Large Screens
          },
        }}>
        {children && children}
      </Grid>
    );
  } else {
    return (
      <div className={containerClass} onScroll={handleScroll}>
        {nuggetTemplate && typeof nuggetTemplate === 'function' && (
          <div className='rootnugget-container'>{onPageRender && typeof onPageRender === 'function' ? onPageRender(getChildren()) : getNormalNugget()}</div>
        )}
        {children && children}
      </div>
    );
  }
}
