import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DataForm } from "components/Form/Form";
import { FormAsyncSingleSelect, SmartFormInput } from "components/Form/FormInputs";
import useHttp from "hooks/useHttp";
import { HotGrid180 } from "./Containers/Grid";
import StringUtil from "utils/StringUtil";

export const FormFieldDialogBox = styled(Dialog)(({ theme }) => ({
    ' .MuiInputBase-root': {
      minWidth : '300px',
    },
    '.MuiFormControl-root': { marginTop: 10},
    ' .MuiDialogContent-root': {
        paddingTop : '1em'
    },
}));

export default function DialogBox(props) {

    const description = props.description;
    const open = props.open;
    const title = props.title;
    const handleClose = props.handleClose;
    const buttons = props.buttons;
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            {
                buttons.map(button => {
                    return button
                })
            }
        </DialogActions>
    </Dialog>
}

export function DeleteDialogBox(props) {
    const {t} = useTranslation();
    const title = props.title  ? props.title : t("default-deletion-title")
    const description = props.description  ? props.description  : t("default-deletion-description")

    return <DialogBox title={title} description={description} {...props}/>
}

export function PermissionErrorDialogBox(props) {

    const { t } = useTranslation();
    const description = t('insufficient-permission-description');
    const title = t('insufficient-permission')
    const [open, setOpen] = useState(true);
    //const navigate = useNavigate();

    function handleClose() {
        //navigate("/")
        setOpen(false);
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{padding : 0}}>
            <Alert severity="error" sx={{display : 'flex', fontSize:"20px", fontStyle:"bold"}}>
                {title}
            </Alert>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{marginTop : "10px"}}>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button key={"Ok"} onClick={handleClose}>{"Ok"}</Button>
        </DialogActions>
    </Dialog>
}

export function DataSyncDialogBox(props) {
    const {open, title, actionUrl, accountSearchUrl, handleClose} = props;
    const [sourceAccount, setSourceAccount] = useState({});
    const [editSoruceAccount, setEditSourceAccount] = useState(false);
    const {postJsonData} = useHttp();

    function onSubmit() {
        const data = {
            sourceAccountCode: sourceAccount.systemCode,
        }
        postJsonData(actionUrl, data);
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DataForm action={actionUrl}>
            <DialogTitle id="alert-dialog-title" sx={{padding : "10px"}}>
                    {StringUtil.isNotBlank(title) ? title : "Data Sync" }
            </DialogTitle>
            <DialogContent>
                    <HotGrid180>
                        <SmartFormInput label="Search Account" value={sourceAccount.systemCode}
                            showEditMode={editSoruceAccount} onEditClick={() => setEditSourceAccount(true)}
                            hiddenFields={
                                [
                                    { name: "sourceAccountCode", value: sourceAccount.systemCode },
                                ]
                            }
                            >
                            <FormAsyncSingleSelect 
                                label="Search Account"
                                url={StringUtil.isNotBlank(accountSearchUrl) ? accountSearchUrl : "/handshake/search/account" }
                                queryParams="keywords"
                                minKeywords={2}
                                onValueChange={(e, value) => setSourceAccount(value)}
                                responseTransformer={(res) => res && res.rows ? res.rows : []}
                                displayField="name"
                                isOptionEqualToValue={(option, value) => option.systemCode === value.systemCode} 
                            />
                        </SmartFormInput>
                    </HotGrid180>
                    <DialogActions>
                        <Button key={"Ok"} type="submit">Ok</Button>
                        <Button key={"Ok"} onClick={handleClose}>Cancel</Button>
                    </DialogActions>
            </DialogContent>
        </DataForm>
    </Dialog>
}

export class DialogManager {

    static showDialogBox(dialogBoxName ) {
        if("DATA_SYNC" === dialogBoxName) {
            return <DataSyncDialogBox />
        }
    }
}