import Mock from '../mock';

const locationList = [
  {
    id: 1,
    name: 'Delhi',
    shortCode: 'DEL',
  },
];

Mock.onGet('/locations').reply(() => {
  try {
    const payload = { locations: locationList };
    return [200, payload];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

Mock.onPost('/location/save').reply(() => {
  try {
    const payload = { locations: locationList };
    return [200, payload];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

Mock.onGet('/sublocations').reply(() => {
  const generateRandomNodes = () => {
    const nodeCount = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
    const nodes = [];
    for (let i = 0; i < nodeCount; i++) {
      nodes.push({
        nodeId: i + 1,
        name: `Node ${i + 1}`,
      });
    }
    return nodes;
  };

  try {
    const payload = generateRandomNodes();
    return [200, payload];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});
