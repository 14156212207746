import { Button, Typography } from "@mui/material";
import axios from "axios";
import DialogBox from "components/Dialog";
import useFileSystem from "hooks/filesystem/useFileSystem";
import useHttp from "hooks/useHttp";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { domain } from "utils/constant";
import UrlUtil from "utils/UrlUtils";

export const FileUploadSystemContext = createContext({
});

const CHUNK_SIZE = .5 * 1024 * 1024; // 5 MB

export const FileUploadProvider = ({ children }) => {
    const [files, setFiles] = useState([]);
    const { post } = useHttp();
    const { getMandatoryRequestParams } = useFileSystem();
    const [fileUploadProgress, setFileUploadProgress] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState();
    const [parent, setParent] = useState()
    const [mandatoryRequestParams, setMandatoryRequestParams] = useState({})
    useEffect(() => {
        if(files && files.length > 0) {
            setShowConfirmationDialog(true)
        }
    }, [files])

    useEffect(() => {
        const params = {...getMandatoryRequestParams()}
        
        if(parent) {
            params["parentObjectCode"] = parent.systemCode
            params["parentObjectType"] = parent.type

            if(!params["familyHeadCode"]) {
                params["familyHeadCode"]= parent.systemCode;
                params["familyHeadType"]=  parent.type;
            }
        }
        
        setMandatoryRequestParams(params)
    }, [parent])

    const uploadFolderAndFiles = async (item, event) => {
        event.preventDefault();
        const files = []
        const items = event.dataTransfer.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry();
            if (item) {
                await traverseFileTree(item, files);
            }
        }

        setFiles(files)
        setParent(item)
    }


    const traverseFileTree = async (entry, fileList) => {
        if (entry.isFile) {
            await new Promise((resolve) => {
                entry.file((file) => {
                    fileList.push({ name: file.name, path: entry.fullPath, size: file.size , file : file});
                    resolve();
                });
            });
        } else if (entry.isDirectory) {
            const reader = entry.createReader();
            await new Promise((resolve) => {
                reader.readEntries(async (entries) => {
                    for (const subEntry of entries) {
                        await traverseFileTree(subEntry, fileList);
                    }
                    resolve();
                });
            });
        }
    };

    function createPlaceHolders() {
        const paths = files.map(fileObj => getCleanedPath(fileObj.path));

        post("/file-object/bulk", { ...mandatoryRequestParams, paths: paths })
            .then(({ data }) => {
                uploadFiles(data.objectCodesByPath)
            })
    }


    const uploadFiles = async (objectCodesByPath) => {
        if (objectCodesByPath) {
            setUploadProgress(0);

            var totalFileSize = 0;
            const buffer = {}
            files.forEach((obj) => {
                totalFileSize += obj.file.size
                const path = getCleanedPath(obj.path)
                const objectSummary = objectCodesByPath[path];

                if (!objectSummary) {
                    throw new Error("Unable to find UID for path [" + path + "].")
                }

                buffer[obj.file.name] = 0
            });

            setFileUploadProgress(buffer);
            const uploadPromises = files.map((fileObj) => uploadFile(fileObj, totalFileSize, objectCodesByPath[getCleanedPath(fileObj.path)]));
            try {
                await Promise.all(uploadPromises);
                alert("All files uploaded successfully!");
            } catch (err) {
                console.error("Error uploading files:", err);
                alert("Upload failed. Please try again.");
            }
        }
        else {
            alert("An unexpected error encountered while preparing the files for upload..");
        }
    };


    const uploadFile = async ({ file, path }, totalFilesSize, objectSummary) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let totalUploadedBytes = 0;
        for (let i = 0; i < totalChunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(file.size, start + CHUNK_SIZE);
            const chunk = file.slice(start, end);
            totalUploadedBytes = await uploadChunk(chunk, path, file.name, file.size, totalChunks, i, totalFilesSize, totalUploadedBytes, objectSummary);

            setFileUploadProgress((prev) => {
                return { ...prev, [file.name]: Math.round((Math.min(totalUploadedBytes, file.size) / file.size) * 100) }
            })
        }
    };


    const uploadChunk = async (chunk, path, fileName, fileSize, totalChunks, chunkIndex, totalFileSize, totalUploadedBytes, objectSummary) => {
        const formData = new FormData();
        formData.append("chunk", chunk);
        formData.append("path", path);
        formData.append("fileName", fileName);
        formData.append("totalChunks", totalChunks);
        formData.append("chunkIndex", chunkIndex);
        formData.append("systemCode", objectSummary.systemCode);
        formData.append("familyHeadCode", objectSummary.familyHeadCode);
        formData.append("familyHeadType", objectSummary.familyHeadType);
        formData.append("type", "ATTACHMENT_ARTIFACT");
        Object.keys(mandatoryRequestParams).forEach(key => {
            if (mandatoryRequestParams[key] && !formData.has(key)) {
                formData.append(key, mandatoryRequestParams[key])
            }
        })


        await axios.post(UrlUtil.getPanelUrl("/attachment/uploadChunk"), formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
            onUploadProgress: (progressEvent) => {
                const loadedBytes = progressEvent.loaded;
                const percentCompleted = Math.round(((totalUploadedBytes + loadedBytes) / fileSize) * 100);

                totalUploadedBytes += loadedBytes

                console.log(fileName + " :: " + totalUploadedBytes + " / " + fileSize)
                // Update cumulative progress
                setUploadProgress(percentCompleted);

                console.log(`Chunk ${chunkIndex + 1} Progress: ${Math.round((loadedBytes / chunk.size) * 100)}%`);
                console.log(`Overall Progress: ${percentCompleted}%`);
            },
        });

        // Update total uploaded bytes
        return totalUploadedBytes;
    };

    function getCleanedPath(path) {
        const p = path.startsWith('.') ? path.replace('.', '') : path
        return p
    }


    return (
        <FileUploadSystemContext.Provider value={{ uploadFolderAndFiles, setFiles, createPlaceHolders, uploadProgress}}>
            {showConfirmationDialog && <FileUploadConfirmationDialog onClose={() => setShowConfirmationDialog(false)} onConfirmation={() => {
                createPlaceHolders()
                setShowConfirmationDialog(false)
            }}/>}
            {children}
        </FileUploadSystemContext.Provider>
    );
}


function FileUploadConfirmationDialog({onClose, onConfirmation}) {
    
    const {t} = useTranslation();

    return <DialogBox
            open={true}
            title={'Confirm Upload'}
            description={
              <Typography>Are you sure, You want to upload the given directory and files.</Typography>
            }
            handleClose={() => onClose()}
            buttons={[
              <Button key={'Yes'} onClick={() => onConfirmation()}>
                {t('yes')}
              </Button>,
              <Button key={'No'} onClick={() => onClose()}>
                {t('no')}
              </Button>,
            ]}
          />
}