import SaveIcon from '@mui/icons-material/Save';
import { Collapse, IconButton, styled } from '@mui/material';
import { Box } from '@mui/system';
import { FormEditor } from 'components/Form/FormInputs';
import { HeaderWithLabelMenu } from 'components/Header/Header';
import { ToolBar } from 'components/ToolBar/Toolbar';
import { DeleteButtonNuggetAfter } from 'components/object-list/Nuggets/Nugget';
import useTheme from 'contexts/Theme/ThemeContext';
import { t } from 'i18next';
import useLayout from 'layout/frame/FrameLayoutContext';
import React, { useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';
import StringUtil from 'utils/StringUtil';
import './box.scss';

export function HotBox(props) {
  const { sx, ...others } = props;
  const componentVal = StringUtil.isNotBlank(props.component) ? props.component : 'fieldset';
  return (
    <Box
      component={componentVal}
      sx={{
        p: '5px',
        borderTop: '1px solid #ccc',
        ...sx,
      }}
      {...others}>
      {others.children}
    </Box>
  );
}

export function BorderLessHotBox(props) {
  const { sx, ...others } = props;
  const componentVal = StringUtil.isNotBlank(props.component) ? props.component : 'fieldset';
  return (
    <Box
      component={componentVal}
      sx={{
        p: '5px',
        ...sx,
      }}
      {...others}>
      {others.children}
    </Box>
  );
}

export function ScrollableBorderedBox(props) {
  const { sx, ...others } = props;
  return <ScrollabledBox sx={{ border: '1px solid #ccc', borderRadius: 1, ...sx }} {...others} />;
}

export function ScrollabledBox(props) {
  const { sx, ...others } = props;
  return (
    <Box component='div' sx={{ maxHeight: '180px', overflowY: 'auto', overflowX: 'hidden', ...sx }} {...others}>
      {others.children}
    </Box>
  );
}

export function BordlessBox(props) {
  const { sx, ...others } = props;
  return (
    <Box component='div' sx={{ padding: '5px', ...sx }} {...others}>
      {others.children}
    </Box>
  );
}

export function BorderedBox(props) {
  const { sx, ...others } = props;
  return (
    <Box component='div' sx={{ border: '1px solid #ccc', borderRadius: 1, padding: '8px', ...sx }} {...others}>
      {others.children}
    </Box>
  );
}

export function BoxWithInternalLabel(props) {
  const { label, sx, ...others } = props;
  return (
    <Box component='div' sx={{ padding: '8px', backgroundColor: '#f2f3f4', ...sx }} {...others}>
      <div style={{ color: '#936', fontWeight: '600', marginBottom: '5px' }}>{label}</div>
      {others.children}
    </Box>
  );
}

export function BoxWithLabelAndMenu(props) {
  const { label, menuItems, showMenuSeperately, collapsed, onToggle, classes, sx, ...others } = props;
  const [closed, setClosed] = useState(collapsed ? collapsed : false);

  function handleOnClick(closed) {
    setClosed(closed);
    if (onToggle) {
      onToggle(closed);
    }
  }

  function getRightToolSet(menuItems) {
    menuItems = CollectionUtil.nullSafe(menuItems);
    return menuItems.map((menuItem, index) => <div key={index}>{menuItem}</div>);
  }
  const labelSpan = (
    <legend>
      <span
        onClick={e => {
          handleOnClick(!closed);
        }}>
        &nbsp;{label}&nbsp;
      </span>
    </legend>
  );
  const headerStyle = { borderBottom: '1px solid #555' };
  return (
    <Box component='div' className={classes ? classes : 'section'} sx={{ marginBottom: '10px', ...sx }} {...others}>
      {showMenuSeperately ? <HeaderWithLabelMenu leftToolSet={labelSpan} rightToolSet={getRightToolSet(menuItems)} sx={{ ...headerStyle }} /> : <HeaderWithLabelMenu leftToolSet={labelSpan} menuItems={menuItems} sx={{ ...headerStyle }} />}
      <Collapse in={!closed}>{others.children}</Collapse>
    </Box>
  );
}

export function LabelledBox(props) {
  const { isx, classes, collapsed, externallyControlled, onToggle, postLabelButton, editable, editableField, label, labelStyles, sx, ...others } = props;
  const [closed, setClosed] = useState(collapsed ? collapsed : false);
  var closeBox = externallyControlled === undefined ? closed : collapsed;

  function handleOnClick(closed) {
    if (!externallyControlled) {
      setClosed(closed);
    }

    if (onToggle) {
      onToggle(!closed);
    }
  }

  return (
    <HotBox className={classes ? classes : 'section'} {...others} sx={{ marginTop: '15px', ...sx }}>
      <legend className='section-legend' style={{ marginBottom: '10px', ...labelStyles }}>
        {editable ? (
          editableField
        ) : (
          <span
            onClick={e => {
              handleOnClick(!closed);
            }}>
            &nbsp;{label}&nbsp;
          </span>
        )}
        {postLabelButton && postLabelButton}
      </legend>
      <Collapse in={!closeBox}>{others.children}</Collapse>
    </HotBox>
  );
}

export function LabelledBoxWithHidableContent(props) {
  const { isx, classes, collapsed, postLabelButton, editable, editableField, label, labelStyles, ...others } = props;
  const [closed, setClosed] = useState(collapsed ? collapsed : false);

  return (
    <HotBox className={classes ? classes : 'section'} {...others} sx={{ marginTop: '15px' }}>
      <legend className='section-legend' style={{ marginBottom: '10px', ...labelStyles }}>
        {editable ? (
          editableField
        ) : (
          <span
            onClick={e => {
              setClosed(!closed);
            }}>
            &nbsp;{label}&nbsp;
          </span>
        )}
        {postLabelButton && postLabelButton}
      </legend>
      <div style={closed ? { position: 'absolute', left: 9999 } : {}}>{others.children}</div>
    </HotBox>
  );
}

export function LabelledSubsection(props) {
  const { ...others } = props;
  return <LabelledBox classes='subsection' {...others} />;
}

export function LabelledBoxUniformInputs(props) {
  const { sx, ...others } = props;
  return (
    <LabelledBox
      sx={{
        '& .MuiAutocomplete-root': { mr: 2, display: 'inline-block', width: '25ch' },
        '& .MuiFormControl-fullWidth': { width: '25ch' },
        ...sx,
      }}
      {...others}
    />
  );
}

export function LabelledScrollableBox(props) {
  const { ocsx, lsx, icsx, label, labelOnClick, postLabelButton, ...others } = props;
  return (
    <div style={{ border: 'none', overflowY: 'clip', ...ocsx }} {...others}>
      <div style={{ width: '100%', ...lsx }}>
        <label onClick={() => labelOnClick()}>
          <span style={{ fontSize: 'small', fontWeight: '500', color: '#936' }}>{label}</span>{' '}
        </label>{' '}
        {postLabelButton}
      </div>
      <ScrollableBorderedBox sx={{ width: '100%', ...icsx }} {...others}>
        {others.children}
      </ScrollableBorderedBox>
    </div>
  );
}

export function LabelledBox2ndLevel(props) {
  const { ocsx, lsx, icsx, label, labelOnClick, postLabelButton, ...others } = props;
  const [internalBoxDisplay, setInternalBoxDisplay] = useState('');
  const labelStyle = {
    width: '100%',
    cursor: 'pointer',
    height: '25px',
    marginBottom: '25px',
    backgroundColor: '#1E64B4',
    textAlign: 'center',
    fontColor: 'white',
  };
  function toggleBox() {
    if (internalBoxDisplay === '') {
      setInternalBoxDisplay('none');
    } else {
      setInternalBoxDisplay('');
    }
  }
  return (
    <div style={{ border: 'none', overflowY: 'clip', ...ocsx }} {...others}>
      <div style={{ ...labelStyle, ...lsx }}>
        <label onClick={() => toggleBox()}>
          <span style={{ fontSize: 'small', fontWeight: '500', color: '#936' }}>{label}</span>{' '}
        </label>{' '}
        {postLabelButton}
      </div>
      <Box component='div' sx={{ display: internalBoxDisplay, ...icsx }} {...others}>
        {others.children}
      </Box>
    </div>
  );
}

export function BorderLessNoLabeldBox(props) {
  return <HotBox component='div' sx={{ borderTop: 'none' }} {...props} />;
}

export function BorderLessLabelledBox(props) {
  const { sx, ...others } = props;

  return <LabelledBox component='div' sx={{ ...sx, borderTop: '1px' }} {...others} />;
}

export function DeletableBorderLessCollapsibleNoIndentBox(props) {
  const { ocsx, lsx, icsx, collapsed, label, name, onDelete, ...others } = props;
  const [closeBox, setCloseBox] = useState(collapsed);

  return (
    <div style={{ border: 'none', overflowY: 'clip', ...ocsx }} {...others}>
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        <div style={{ cusrsor: 'pointer' }} onClick={e => setCloseBox(!closeBox)}>
          <label>
            <span style={{ fontSize: 'small', fontWeight: '500', color: '#936', ...lsx }}>{label}</span>{' '}
          </label>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <DeleteButtonNuggetAfter
            title={t('delete')}
            onClick={e => {
              onDelete();
              e.preventDefault();
            }}
          />
        </div>
      </div>
      {!closeBox && (
        <Box component='div' name={name} sx={{ border: 'none', overflowX: 'hidden', paddingLeft: '0px', ...icsx }} {...others}>
          {others.children}
        </Box>
      )}
    </div>
  );
}

export function BorderLessHidableNoIndentBox(props) {
  const { ocsx, lsx, icsx, hidden, label, name, ...others } = props;
  const [hideBox, setHideBox] = useState(hidden);

  return (
    <div style={{ border: 'none', overflowY: 'clip', ...ocsx }} {...others}>
      <div style={{ width: '100%', cusrsor: 'pointer' }} onClick={e => setHideBox(!hideBox)}>
        <label>
          <span style={{ fontSize: 'small', fontWeight: '500', color: '#936', ...lsx }}>{label}</span>{' '}
        </label>
      </div>
      {
        <div style={{ display: hideBox ? 'none' : 'block' }}>
          <Box component='div' name={name} sx={{ border: 'none', overflowX: 'hidden', paddingLeft: '0px', ...icsx }} {...others}>
            {others.children}
          </Box>
        </div>
      }
    </div>
  );
}

export function BorderLessCollapsibleNoIndentBox(props) {
  const { ocsx, lsx, icsx, collapsed, label, name, ...others } = props;
  const [closeBox, setCloseBox] = useState(collapsed);

  return (
    <div style={{ border: 'none', overflowY: 'clip', ...ocsx }} {...others}>
      <div style={{ width: '100%', cusrsor: 'pointer' }} onClick={e => setCloseBox(!closeBox)}>
        <label>
          <span style={{ fontSize: 'small', fontWeight: '500', color: '#936', ...lsx }}>{label}</span>{' '}
        </label>
      </div>
      {!closeBox && (
        <Box component='div' name={name} sx={{ border: 'none', overflowX: 'hidden', paddingLeft: '0px', ...icsx }} {...others}>
          {others.children}
        </Box>
      )}
    </div>
  );
}

export function MobileFullWidthNotesBox(props) {
  return <NotesBox {...props} editorStyle={{ width: '100%', maxWidth: '500px' }} />;
}

export function AdvancedHideableSettingBox(props) {
  const { label, hidden, ...others } = props;

  return (
    <BorderLessHidableNoIndentBox hidden={hidden} label={'Advanced'}>
      {others.children}
    </BorderLessHidableNoIndentBox>
  );
}

export function NotesBox(props) {
  const { name, value, onChange, ocsx, editorStyle, collapsed } = props;

  return (
    <BorderLessCollapsibleNoIndentBox label={t('generic-label-notes')} collapsed={collapsed ? collapsed : true} ocsx={ocsx}>
      <FormEditor label={t('generic-label-notes.placeholder')} name={StringUtil.isNotBlank(name) ? name : 'notes'} value={value} onValueChange={e => onChange(e)} rows={4} inputProps={{ maxLength: 150 }} style={{ ...editorStyle }} />
    </BorderLessCollapsibleNoIndentBox>
  );
}

export function SlidingBox(props) {
  const { slidingDirection, style, ...others } = props;

  function getSlidingClass(slidingDirection) {
    if (slidingDirection === 'Left') {
      return 'slide-left';
    } else if (slidingDirection === 'Right') {
      return 'slide-right';
    }
    console.log('Invalid class name for Sliding Box ' + slidingDirection);
  }

  return (
    <div className={'sliding-box ' + getSlidingClass(slidingDirection)} style={{ ...style }}>
      {others.children}
    </div>
  );
}

export function BoxLegendToolBar(props) {
  const { leftTooSet, rightToolSet, ...others } = props;
  const leftLegendTools = <div className='box-legend box-legend-right'> {leftTooSet} </div>;
  const rightLegendTools = <div className='box-legend box-legend-right'> {rightToolSet} </div>;
  return (
    <div>
      <ToolBar leftTooSet={leftLegendTools} rightToolSet={rightLegendTools} {...others} />
    </div>
  );
}

export function NuggetHyperLink(props) {
  return (
    <SmartSpan sx={{ cursor: 'pointer', color: '#1976d2', fontWeight: '600' }} {...props}>
      {props.children}
    </SmartSpan>
  );
}

export function SmartSpan(props) {
  const { title, maxLength, showFullWordsOnly, sx, ...others } = props;
  const allowedLength = StringUtil.isNotBlank(maxLength) && parseInt(maxLength) > 0 ? parseInt(maxLength) : 50;
  const textValue = others.children;
  const titleValue = StringUtil.isNotBlank(title) ? title : others.children;
  const takefullWordsOnly = typeof showFullWordsOnly !== 'undefined' ? showFullWordsOnly : true;
  return (
    <span title={titleValue} style={{ overflowWrap: 'anywhere', ...sx }} {...others}>
      {' '}
      {StringUtil.curtailWithEllipses(textValue, allowedLength, takefullWordsOnly)}{' '}
    </span>
  );
}

export function SmartLabel(props) {
  const { title, maxLength, trimAnywhere, postIcon, sx, ...others } = props;
  const allowedLength = StringUtil.isNotBlank(maxLength) && parseInt(maxLength) > 0 ? parseInt(maxLength) : 50;
  const textValue = others.children;
  const takefullWordsOnly = trimAnywhere === undefined ? false : true;
  const titleValue = StringUtil.isNotBlank(title) ? title : others.children;
  return (
    <label title={titleValue} {...others} style={{ overflowWrap: 'anywhere', ...sx }}>
      {StringUtil.curtailWithEllipses(textValue, allowedLength, takefullWordsOnly)}
      {StringUtil.isNotBlank(titleValue) && postIcon && postIcon}
    </label>
  );
}

export function LabelValueField(props) {
  const { title, value, maxLength, ...others } = props;
  return (
    <Box>
      <SmartLabel title={title} {...others} />
      <SmartLabel title={value} {...others} />
    </Box>
  );
}

export const FormBox = React.forwardRef(({ removeDefaultStyles, sx, ...props }, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      sx={
        !removeDefaultStyles && {
          '& .MuiFormControlLabel-root': { mt: 1, mr: 1, mb: 1, ml: 0 },
          '& .MuiTextField-root': { mt: 1, mr: 1, mb: 1, width: '25ch' },
          '& .MuiFormControl-root': { mt: 1, mr: 1, mb: 1, width: '25ch' },
          '& .MuiAutocomplete-root': { width: '25ch' },
          '& .MuiFormControl-fullWidth': { mt: 1, mr: 1, mb: 1, width: 'max(100%, 52ch)', maxWidth: '100%' },
          '& .editor': { width: '90%' },
          ...sx,
        }
      }>
      {props.children}
    </Box>
  );
});

export function SideBarMainContainer(props) {
  return <div style={{ marginTop: '10px', marginRight: '10px', paddingLeft: '10px' }}>{props.children}</div>;
}

export function VerticalItemContainer(props) {
  const { items } = props;
  const parentStyle = {
    display: 'grid',
    height: 'auto',
  };

  const childStyle = {};

  return (
    <div style={parentStyle}>
      {items.map((child, index) => (
        <div style={childStyle} key={index}>
          {child}
        </div>
      ))}
    </div>
  );
}

export function StyledBox(props) {
  const { sx, ...others } = props;
  return styled(Box)(({ theme }) => ({
    ...sx,
  }))(others);
}

export function AppContentDataContainerBoxWithTabs(props) {
  const { children } = props;
  const { theme } = useTheme();

  return (
    <Box
      className='app-content-main-data-container'
      sx={{
        height: {
          xs: theme.appContentCssVars.appContentDataframeContainerHeightSm,
          md: theme.appContentCssVars.appContentDataframeContainerHeightLg,
        },
        pt: theme.appContentCssVars.appContentDataFramePadding,
        overflowY: 'auto',
        scrollbarWidth: 'none',
      }}>
      {children}
    </Box>
  );
}

export function AppContentInnerBox(props) {
  const { children } = props;
  const { layout } = useLayout();
  const { mainContentBgColor, mainContentBorderRadius, mainContentPadding, applicationMainContentHeight } = layout.cssVariables;
  return (
    <Box
      className='app-content-box-inner'
      sx={{
        backgroundColor: mainContentBgColor,
        borderRadius: {
          xs: 0,
          md: mainContentBorderRadius,
        },
        padding: mainContentPadding,
        height: applicationMainContentHeight,
      }}>
      {children}
    </Box>
  );
}
