import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const marketing = { name : 'Marketing',
    icon : <PeopleAltIcon />,
    nextLevel : [, 
        {
            name : 'PIM',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Product Groupings',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Marketing / Product Targets',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Communication',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : 'Company Page',
                },{
                    name : 'Brand Page / Brand Management',
                }
            ]
        }, {
            name : 'Company Store',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ]
  }