import { Button, Card, CardContent, Grid, Typography, MenuItem } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { DefaultSelectorIcon } from 'components/Icons';
import { MoreVertMenu } from 'components/Menu/Menu';
import { PagebaleCardGrid280 } from 'components/card-grid/PagebaleCardGrid';
import { useEffect, useState } from 'react';
import StringUtil from 'utils/StringUtil';
import { Box } from '../../../../node_modules/@mui/joy/index';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import useHttp from 'hooks/useHttp';
import { LocationListingProvider, useLocationListingContext } from 'views/account/location/LocationList';

export default function AddressCardGrid(props) {
  const { allTabData, showDetails } = props;

  function getCard(address, index) {
    return <AddressCard address={address} onEdit={showDetails} key={address.systemCode} />;
  }

  return (
    <div className='item-card-list address-card-list'>
      <LocationListingProvider>
        <PagebaleCardGrid280 getCard={getCard} data={allTabData} />
      </LocationListingProvider>
    </div>
  );
}

function AddressCard(props) {
  const { address, onEdit } = props;
  const [blankLines, setBlankLines] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [hideCard, setHideCard] = useState(false);
  const { t } = useTranslation();
  const { deleteResource } = useHttp();

  const { setAddressSystemCode } = useLocationListingContext();

  useEffect(() => {
    var blankLineCount = 0;
    if (StringUtil.isBlank(address.line1)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line2)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line3)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line4)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.town)) {
      blankLineCount++;
    }
    setBlankLines(blankLineCount);
    setInitialized(true);
  }, []);

  function getTrimmedAddressText(line) {
    return StringUtil.curtailWithEllipses(line, 30, true);
  }

  const navigate = useNavigate();
  function showLocations(address) {
    setAddressSystemCode(address.systemCode);
    navigate('/location');
  }

  function deleteAddress(address) {
    const ADDRESS_DELETE_URL = '/account/address';
    deleteResource(ADDRESS_DELETE_URL, { systemCode: address.systemCode }).then(response => {
      setHideCard(true);
    });
  }

  return (
    initialized &&
    !hideCard && (
      <Card key={address.systemCode} variant='outlined' sx={{ borderRadius: '10px', boxShadow: '0 2px 1px 0 rgba(0,0,0,.16)' }}>
        <CardContent>
          <div style={{ display: 'flex' }}>
            <AddressHeader>{getTrimmedAddressText(address.name + ' (' + address.shortCode + ')')} </AddressHeader>
            {address.defaultAddress && (
              <>
                &nbsp; <DefaultSelectorIcon fontSize='small' />
              </>
            )}
          </div>
          <AddressLine>{address.line1}</AddressLine>
          {address.line2 && <AddressLine>{getTrimmedAddressText(address.line2)}</AddressLine>}
          {address.line3 && <AddressLine>{getTrimmedAddressText(address.line3)}</AddressLine>}
          {address.line4 && <AddressLine>{getTrimmedAddressText(address.line4)}</AddressLine>}
          {address.town && <AddressLine>{getTrimmedAddressText(address.town)}</AddressLine>}
          <AddressLine>{getTrimmedAddressText(address.districtName + ' ' + address.provinceName + ' ' + address.postCode)}</AddressLine>
          <AddressLine>{getTrimmedAddressText(address.countryName)}</AddressLine>
          {/* Add blank lines as <p> tags */}
          {Array.from({ length: blankLines }).map((_, index) => (
            <AddressLine key={index}>&nbsp;</AddressLine>
          ))}
        </CardContent>
        <CardActions>
          <Box sx={{ display: 'flex' }}>
            <AddressCardButton onClick={() => onEdit(address)} label='Edit' />
            <AddressCardButton label='Remove' onClick={() => deleteAddress(address)} />
            {!address.defaultAddress && <AddressCardButton label='Set as Default' />}
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <MoreVertMenu>
              <MenuItem onClick={() => showLocations(address)}>{t('locations')}</MenuItem>
            </MoreVertMenu>
          </Box>
        </CardActions>
      </Card>
    )
  );
}

function AddressHeader(props) {
  const { children, ...other } = props;
  return (
    <AddressLine color='textPrimary' variant='h6' {...other}>
      {children}
    </AddressLine>
  );
}

function AddressLine(props) {
  const { children, ...other } = props;
  return (
    <div>
      <Typography variant='body1' sx={{ fontSize: '1rem', lineHeight: '1.2', color: '#0f1111' }} {...other}>
        {children}
      </Typography>
    </div>
  );
}

function AddressCardButton(props) {
  const { label, onClick } = props;

  return (
    <Button variant='text' size='small' onClick={onClick} sx={{ minWidth: '0px', textTransform: 'none', fontSize: '13px', fontWeight: '400' }}>
      {label}
    </Button>
  );
}
