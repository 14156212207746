import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionBoundary from 'components/security/PermissionBoundary';
import { PageableOnScrollList } from 'components/nugget-list/PageableOnScrollList';
import { ClickableNuggetAfter, ClickableNuggetPart, Initials, NonClickableNuggetAfter, NuggetPicture } from 'components/object-list/Nuggets/Nugget';
import Search from 'components/object-list/Search/Search';

const USER_CONTACT = '/cloud/person/contact?pp=';
const USER_DETAIL = '/cloud/person/detail?pp=';
const USER_PAGE = '/cloud/person/page';

export default function PlatformUserList() {
  const { t } = useTranslation();
  // const [dataManager] = useState(new PageableDataManager(USER_PAGE));
  const [dataManager] = useState();
  const [keywords, setKeywords] = useState(null);

  function nuggetTemplate(item) {
    let icon;

    if (item.logoLocator) {
      icon = <NuggetPicture pictureLocator={item.logoLocator} pictureType='jpeg' alt={item.name} />;
    } else {
      icon = <Initials text={item.name} id={item.systemCode} />;
    }

    return (
      <div className={item.focused ? 'rootnugget appactive' : 'rootnugget'} key={item.systemCode}>
        <div className='nugget-main'>
          <ClickableNuggetPart item={item} url={USER_DETAIL + item.systemCode}>
            {icon}
            <div className='nugget__rootclick-appmain'>
              <div className='nugget-title'>
                {item.salutation} {item.name}
              </div>
              <div className='nugget-subtitle'>{item.principal}</div>
              <div className='nugget-subtitle'>{item.systemCode}</div>
            </div>
          </ClickableNuggetPart>
        </div>
        <NonClickableNuggetAfter>
          <i className={item.signedIn ? 'live fa fa-circle' : 'dead fa fa-circle'}></i>
        </NonClickableNuggetAfter>
        <ClickableNuggetAfter url={USER_CONTACT + item.systemCode}>
          <div className='side-icon'>
            <i className='live fa fa-envelope'></i>
          </div>
        </ClickableNuggetAfter>
      </div>
    );
  }

  const data = { name: keywords };

  return (
    <PermissionBoundary permissions={['AdministerSystem']}>
      <div className='application-main-content'>
        <div className='applistheader application-main-content-header'>
          <Search title={t('all-users')} setKeywords={setKeywords} />
        </div>
        <div className='applistframe application-main-data-frame'>
          <PageableOnScrollList dataManager={dataManager} data={data} nuggetTemplate={nuggetTemplate} />
        </div>
      </div>
    </PermissionBoundary>
  );
}
