import useHttp from 'hooks/useHttp';
import { createContext, useEffect, useState } from 'react';
import StringUtil from 'utils/StringUtil';

const BASIC_DETAILS = '/cloud/roles/edit/';

const initialState = {
  permissionsMap: new Map(),
  permissions: [],
};

export const RoleDetailsContext = createContext({
  ...initialState,
  updateRoleDetails: () => {},
  getAssignedPermissions: () => {},
});

export function RoleDetailsProvider(props) {
  const { systemCode } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [roleDetails, setRoleDetails] = useState(initialState);
  const url = systemCode ? BASIC_DETAILS + systemCode : null;
  const { get } = useHttp();

  function assignPermissions(response) {
    const map = new Map(roleDetails.permissionsMap);
    response.permissions.forEach(permission => {
      map.set(permission.name, permission);
    });

    updateRoleDetails({ type: 'UPDATE', key: 'permissionsMap', value: map });
  }

  useEffect(() => {
    if (StringUtil.isNotBlank(systemCode)) {
      get(url).then(res => {
        setRoleDetails(res);
        assignPermissions(res);
        setDataLoaded(true);
      });
    } else {
      setDataLoaded(true);
    }
  }, [systemCode]);

  const updateRoleDetails = action => {
    setRoleDetails(prevState => {
      return { ...prevState, [action.key]: action.value };
    });
  };

  const getAssignedPermissions = () => {
    const map = new Map(roleDetails.permissionsMap);
    return Array.from(map.values()).filter(permission => permission.assigned && !permission.systemPermission);
  };

  return dataLoaded && <RoleDetailsContext.Provider value={{ ...roleDetails, updateRoleDetails, getAssignedPermissions }}>{props.children}</RoleDetailsContext.Provider>;
}
