import { styled } from "@mui/material";

export const SessionRootContainer = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#014",
    minHeight: "100vh !important",
    "& .card": {
      maxWidth: 800,
      minHeight: 400,
      margin: "1rem",
      display: "flex",
      borderRadius: 12,
      alignItems: "center"
    },
  
    ".img-wrapper": {
      height: "100%",
      minWidth: 320,
      display: "flex",
      padding: "2rem",
      alignItems: "center",
      justifyContent: "center"
    },

    "img" : {
        width : "250px"
    }
  }));
  
export const SessionContentBox = styled("div")(() => ({
    height: "100%",
    padding: "32px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.01)"
}));
  