import styleVariables from 'styles/appvariables.scss';

export class ScreenUtil {
  static getScreenType(element) {
    const largeScreenCutoff = styleVariables.largeBreakpoint.replace('px', '');
    const tabletScreenCutoff = styleVariables.tabletBreakpoint.replace('px', '');
    const laptopScreenCutoff = styleVariables.laptopBreakpoint.replace('px', '');
    const mobileScreenCutoff = styleVariables.mobileBreakpoint.replace('px', '');

    const currentScreenSize = element ? element.offsetWidth : window.innerWidth;
    if (currentScreenSize < mobileScreenCutoff) {
      return ScreenTypeEnum.MOBILE;
    }

    if (currentScreenSize < tabletScreenCutoff) {
      return ScreenTypeEnum.TABLET;
    }

    if (currentScreenSize < laptopScreenCutoff) {
      return ScreenTypeEnum.LAPTOP;
    }

    return ScreenTypeEnum.DESKTOP;
  }

  static isMobile(element) {
    return ScreenUtil.getScreenType(element) === ScreenTypeEnum.MOBILE;
  }

  static isTablet(element) {
    return ScreenUtil.getScreenType(element) === ScreenTypeEnum.TABLET;
  }

  static isLaptop(element) {
    return ScreenUtil.getScreenType(element) === ScreenTypeEnum.LAPTOP;
  }

  static isDesktop(element) {
    return ScreenUtil.getScreenType(element) === ScreenTypeEnum.DESKTOP;
  }

  static getListNuggetMaxWidthPc() {
    if (!ScreenUtil.isMobile()) {
      return '50%';
    } else {
      return '100%';
    }
  }
}

export class ScreenTypeEnum {
  static DESKTOP = 'Desktop';
  static LAPTOP = 'Laptop';
  static MOBILE = 'Mobile';
  static TABLET = 'Tablet';
}

export class MuiScreenType {
  static EXTRA_SMALL = 'xs'; // < 600
  static SMALL = 'sm'; //< 900
  static MEDIUM = 'md'; //< 1200
  static LARGE = 'lg'; // < 1536
  static EXTRA_LARGE = 'xl'; // >= 1536

  static getScreenType() {
    const width = window.innerWidth;

    if (width < 600) {
      return 'xs'; // Extra Small
    } else if (width >= 600 && width < 900) {
      return 'sm'; // Small
    } else if (width >= 900 && width < 1200) {
      return 'md'; // Medium
    } else if (width >= 1200 && width < 1536) {
      return 'lg'; // Large
    } else {
      return 'xl'; // Extra Large
    }
  }

  static isMobile(screenType) {
    if (!screenType) {
      screenType = MuiScreenType.getScreenType();
    }
    return screenType === MuiScreenType.EXTRA_SMALL || screenType === MuiScreenType.SMALL;
  }

  static getScreenHeight() {
    return window.innerHeight;
  }
}
