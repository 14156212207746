import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';
import { AppMainContainer } from 'components/AppRoot/AppMain';
import { DataForm } from 'components/Form/Form';
import { FormAsyncMultiSelect, FormLabelledSwitch, FormNumericInput, MandatoryInputField } from 'components/Form/FormInputs';
import { AppMainTitleBar } from 'components/TitleBar/Titlebar';
import { AppMainBody } from 'components/ToolBar/Toolbar';
import { VerticalTabContainer } from 'components/object-list/vertical-tab/VerticalTabContainer';
import useHttp from 'hooks/useHttp';
import useRoleDetails from 'hooks/useRoleDetails';
import { CollectionUtil } from 'utils/CollectionUtil';
import { RolePermissions } from './RolePermissions';
import { RoleDetailsProvider } from './context/RoleDetailContext';

let STATE_ID;
const BASIC_DETAILS = '/cloud/roles/edit/';
const SAVE_URL = '/cloud/roles/edit';
const ROLE_PAGE_URL = '/cloud/roles/page';

export function RoleDetails(props) {
  const { t } = useTranslation();
  const { showTitleBar, systemCode } = props;

  return (
    <AppMainContainer showAppContainer>
      {showTitleBar && <AppMainTitleBar title={t('role') + ' :: ' + (systemCode ? systemCode : t('new'))} />}
      <RoleDetailsProvider systemCode={systemCode}>
        <AppMainBody classes={'no-tool-bar'}>
          <MainTab {...props} />
        </AppMainBody>
      </RoleDetailsProvider>
    </AppMainContainer>
  );
}

function MainTab(props) {
  const { t } = useTranslation();
  const { name, priorityRanking, frequent, systemCode, updateRoleDetails, getAssignedPermissions } = useRoleDetails();
  const { post } = useHttp();

  const items = [
    {
      legendTitle: t('basic'),
      legendIcon: <KeyboardArrowRightIcon />,
      itemHeaderLeftToolSet: t('basic'),
      itemHeaderRightToolSet: (
        <IconButton type='submit'>
          <SaveIcon />
        </IconButton>
      ),
      detail: (
        <>
          <MandatoryInputField label='Name' name='name' value={name} hideIcon onChange={e => updateRoleDetails({ type: 'UPDATE', key: 'name', value: e.target.value })} />
          <FormNumericInput
            label={t('opr')}
            value={priorityRanking}
            name={'priorityRanking'}
            onChange={e => updateRoleDetails({ type: 'UPDATE', key: 'priorityRanking', value: e.target.value })}
          />
          <FormLabelledSwitch
            label={t('main')}
            checked={frequent}
            name='frequent'
            onChange={e => updateRoleDetails({ type: 'UPDATE', key: 'frequent', value: e.target.checked })}
          />
          {!systemCode && <TemplateSelection {...props} />}
        </>
      ),
    },
    {
      legendTitle: t('permissions'),
      legendIcon: <KeyboardArrowRightIcon />,
      itemHeaderLeftToolSet: t('permissions'),
      itemHeaderRightToolSet: (
        <IconButton type='submit'>
          <SaveIcon />
        </IconButton>
      ),
      detail: <RolePermissions {...props} displayCheckBox />,
    },
  ];

  return (
    <DataForm
      state={STATE_ID}
      action={SAVE_URL}
      eventName={'ROLE__CREATED'}
      onSubmit={() => {
        post(SAVE_URL, {
          name: name,
          frequent: frequent,
          priorityRanking: priorityRanking,
          systemCode: systemCode,
          permissions: getAssignedPermissions(),
        });
      }}>
      <input type={'hidden'} name='systemCode' value={systemCode} />
      <VerticalTabContainer classes='full-height' items={items} />
    </DataForm>
  );
}

function TemplateSelection(props) {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [permissionCountMap] = useState(new Map());
  const { updateRoleDetails, permissionsMap } = useRoleDetails();
  const { get } = useHttp();

  function assignPermissions(response) {
    const map = new Map(permissionsMap);
    // Unassign all existing permissions.
    response.permissions.forEach(permission => {
      let count = 1;
      if (permissionCountMap.has(permission.name)) {
        count = permissionCountMap.get(permission.name) + 1;
      }

      permissionCountMap.set(permission.name, count);

      map.set(permission.name, permission);
      updateRoleDetails({ type: 'UPDATE', key: 'permissionsMap', value: map });
    });
  }

  function unassignPermissions(response) {
    const map = new Map(permissionsMap);
    // Unassign all existing permissions.
    response.permissions.forEach(permission => {
      const count = permissionCountMap.get(permission.name) - 1;
      permissionCountMap.set(permission.name, count);

      if (count === 0) {
        permission.assigned = false;
        map.set(permission.name, permission);
        updateRoleDetails({ type: 'UPDATE', key: 'permissionsMap', value: map });
      }
    });
  }

  return (
    <FormAsyncMultiSelect
      label='Choose Template'
      url={ROLE_PAGE_URL}
      minKeywords={0}
      data={{
        createdRoles: true,
        defaultRoles: true,
      }}
      queryParams='keywords'
      onValueChange={(e, value) => {
        const assignedTemplates = value.filter(template => !CollectionUtil.hasMatchingElement(selectedTemplates, el => el.systemCode === template.systemCode));
        const unassignedTemplates = selectedTemplates.filter(template => !CollectionUtil.hasMatchingElement(value, el => el.systemCode === template.systemCode));

        if (assignedTemplates && assignedTemplates.length > 0) {
          get(BASIC_DETAILS + assignedTemplates[0].systemCode).then(res => {
            assignPermissions(res);
            setSelectedTemplates(value);
          });
        } else if (unassignedTemplates && unassignedTemplates.length > 0) {
          get(BASIC_DETAILS + unassignedTemplates[0].systemCode).then(res => {
            unassignPermissions(res);
            setSelectedTemplates(value);
          });
        }
      }}
      responseTransformer={res => {
        return res.rows ? res.rows : [];
      }}
      displayField='title'
      isOptionEqualToValue={(option, value) => option.systemCode === value.systemCode}
    />
  );
}
