import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from "@mui/material";
import { useState } from "react";
import { SideDrawer } from "components/Drawer/Drawer";
import { DropDownMenu } from "components/Menu/Menu";
import { HtmlBlockMenu } from "../block/html/HtmlBlockMenu";
import { AddCompanyMember, AddPeopleMember } from './AddMember';
import useFileSystem from 'hooks/filesystem/useFileSystem';
import { Companies, People } from './Members';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Properties } from 'components/properties/properties';
import { AttachmentArtifactMenu } from 'views/attachment/AttachmentArtifactMenu';
import { ProductBinderMenu } from 'views/product-binder/ProductBinderMenu';

export function FileObjectContextMenu(props) {
    const {item, onRename} = props
    const [showProperties, setShowProperties] = useState();
    const {editObject, deleteObject} = useFileSystem();

    const menu = [{
        name : 'Members',
        icon : <PeopleAltIcon />,
        nextLevel : [
            {
                name : 'Add Members',
                children : [
                    {
                        name : "Companies",
                        component : <AddCompanyMember onSave={(values) => editObject({url : "/file-object/add/members/" + item.systemCode, memberType : 'ACCOUNT', type : item.type, systemCode : item.systemCode, ...values})}/>
                    },
                    {
                        name : "People",
                        component : <AddPeopleMember onSave={(values) => editObject({url : "/file-object/add/members/" + item.systemCode, memberType : 'PERSON', type : item.type, systemCode : item.systemCode, ...values})}/>
                    }
                ]
            }, 
            {   
                name : 'Manage Members',
                children : [
                    {
                        name : "Companies",
                        component : <Companies type={item.type} systemCode={item.systemCode}/>
                    },
                    {
                        name : "People",
                        component : <People type={item.type} systemCode={item.systemCode}/>
                    }
                ]
            }
        ],
    }]
    
    switch(item.type) {
        case 'HTML_BLOCK' : return <>
            <HtmlBlockMenu {...props} />
        </>
        case 'ATTACHMENT_ARTIFACT' : return <>
            <AttachmentArtifactMenu {...props} />
        </>
        case 'PRODUCT_BINDER' : return <>
            <ProductBinderMenu {...props} />
        </>
        case 'FOLDER' : return <>
             {showProperties && 
                <SideDrawer  anchor="right" headerTitle={"Properties"} open={showProperties} onClose={() => setShowProperties(false)}>
                    <Properties open={showProperties} handleClose={() => setShowProperties(false)} menu={menu} title={"Properties"}/>
                </SideDrawer>
            }
             <DropDownMenu menuIcon={<MoreVertIcon />}>
                <MenuItem onClick={() => {
                    onRename(item)
                }}>Rename</MenuItem>
                <MenuItem onClick={() => {
                    setShowProperties(true)
                }}>Properties</MenuItem>
                <MenuItem onClick={() => {
                    deleteObject({systemCode : item.systemCode, type : item.type}).then(() => alert("Deletion Successful."))
                }}>Delete</MenuItem>
            </DropDownMenu>
        </>
        default : return <></>
    }
}

