import { Chip } from "@mui/material";
import StringUtil from "utils/StringUtil";




/**
 * A Deletable chip.
 */
export function DeletableChip(props) {
    return <Chip label={props.label} style={{ marginBottom: "2px" }} onDelete={props.onDelete} />
}

export function SmartChip(props) {
    const { label, maxLength, sx, ...others } = props;
    const chipLabelLength = maxLength && maxLength > 0 ? maxLength : 50;
    const chipLabel = <label title={label}>{StringUtil.curtailWithEllipses(label, chipLabelLength, true)}</label>
    return (
        <Chip label={chipLabel} sx={{ marginTop: "0px", marginRight: "5px", marginBottom: "5px", ...sx }} {...others} />
    )
}

const ChipStatus = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO'
};

export function StatusChip(props) {
    const { label, maxLength, status, sx, ...others } = props;
    const chipLabelLength = maxLength && maxLength > 0 ? maxLength : 50;
    const chipLabel = <label title={label}>{StringUtil.curtailWithEllipses(label, chipLabelLength, true)}</label>
    const backgroundColors = {
        [ChipStatus.SUCCESS || ""]: "rgb(174, 229, 132)",
        [ChipStatus.ERROR]: "rgb(255, 204, 204)",
        [ChipStatus.INFO]: "rgb(173, 216, 230)",
    }
    const fontColors = {
        [ChipStatus.SUCCESS]: "rgb(30, 70, 32)",
        [ChipStatus.ERROR]: "rgb(138, 11, 11)",
        [ChipStatus.INFO]: "rgb(31, 97, 141)",
    }
    return (
        <Chip label={chipLabel} sx={{
            marginTop: "0px", marginRight: "5px", marginBottom: "5px",
            backgroundColor: backgroundColors[status],
            color: fontColors[status], ...sx
        }} {...others} />
    )
}

export function SuccessChip(props) {
    return <StatusChip {...props} status={ChipStatus.SUCCESS} />
}

export function ErrorChip(props) {
    return <StatusChip {...props} status={ChipStatus.ERROR} />
}

export function InfoChip(props) {
    return <StatusChip {...props} status={ChipStatus.INFO} />
}
