import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { useState } from 'react';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import useTheme from 'contexts/Theme/ThemeContext';

/**
 * An Tab panel for the application.
 */
export function UiTabContext(props) {
  const { onTabChange } = props;
  const [value, setValue] = useState(props.defaultTab);
  const { theme } = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <TabContext value={value} {...props}>
      <TabList aria-label='Tabs' onChange={handleChange} className={'tab-bar'} sx={{ height: theme.componentCssVars.tabBarHeight }}>
        {props.tabs}
      </TabList>
      {props.children}
    </TabContext>
  );
}

/**
 * An Tab panel for the application.
 */
export function MainHorizontalTabContext(props) {
  const [value, setValue] = useState(props.defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (props.onTabChange && typeof props.onTabChange === 'function') {
      props.onTabChange(newValue);
    }
  };

  return (
    <TabContext value={value} {...props}>
      <TabList aria-label='Tabs' onChange={handleChange}>
        {props.tabs}
      </TabList>
      {props.children}
    </TabContext>
  );
}

export function HorizontalTab(props) {
  return <Tab {...props} />;
}

/**
 * An Tab panel for the application.
 */
export function UiTabPanel(props) {
  return <TabPanel {...props}>{props.children}</TabPanel>;
}
