import { CssBaseline, ThemeProvider } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { MuiScreenType } from 'utils/ScreenUtil';
import { useCompactTheme } from 'contexts/Theme/compact-theme';
import { useComfortableTheme } from 'contexts/Theme/comfortable-theme';

const ThemeContext = createContext({
  changeTheme: () => {},
});

export const ThemeContextProvider = ({ children }) => {
  const comfortableTheme = useComfortableTheme();
  const compactTheme = useCompactTheme();
  const [themeName, setThemeName] = useState('compact');
  const [currentTheme, setCurrentTheme] = useState(compactTheme);

  const themes = {
    comfort: comfortableTheme,
    compact: compactTheme,
  };
  const changeTheme = theme => {
    setCurrentTheme(themes[theme]);
    setThemeName(theme);
  };

  useEffect(() => {
    const screenType = MuiScreenType.getScreenType();
    const root = document.documentElement;
    root.setAttribute('data-screen', screenType);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, changeTheme }}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default function useTheme() {
  return useContext(ThemeContext);
}
