import ObjectList from 'components/object-list/ObjectList';

export default function AddressTableList(props) {
  const { allTabData, showDetails } = props;
  return (
    <ObjectList
      data={allTabData}
      initials={{ text: 'name', id: 'systemCode' }}
      onRowClick={item => showDetails(item.systemCode)}
      headCells={[
        {
          id: 'shortCode',
          numeric: false,
          label: 'Short code',
          nuggetInfo: {
            order: 1,
            type: 'subtitle',
          },
        },
        {
          id: 'name',
          numeric: false,
          label: 'Name',
          nuggetInfo: {
            order: 2,
            type: 'title',
          },
        },
        {
          id: 'state',
          numeric: false,
          label: 'Status',
          nuggetInfo: {
            order: 34,
            type: 'subtitle',
          },
        },
        {
          id: 'createdAt',
          numeric: false,
          label: 'Created At',
          nuggetInfo: {
            order: 4,
            type: 'date',
          },
        },
      ]}
    />
  );
}
