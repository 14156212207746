import useLayout from "layout/frame/FrameLayoutContext";
import Editor from "@monaco-editor/react";

const CodeEditor = (props) => {
  const {layout} = useLayout();
console.log(props)
  return <Editor
  height={"calc(100vh - " + layout.cssVariables.headerHeight + ")"}
  defaultLanguage={props.language}
  options={{
    automaticLayout: true,
    wordWrap: "on",
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
    theme: "vs-dark", // Use "vs" for light mode
  }}
  {...props}
/>
//   return <Monaco
//                 height="400px"
//                 defaultLanguage="json"
//                 value={props.value}
//                 onChange={props.onChange}
//                 options={{
//                 automaticLayout: true,
//                 wordWrap: "on",
//                 scrollBeyondLastLine: false,
//                 minimap: { enabled: false },
//                 theme: "vs-dark", // Use "vs" for light mode
//                 }}
//             />
};

export default CodeEditor;
