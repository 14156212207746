import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import { HoverLink } from 'components/Link';
import useAppState from 'hooks/useAppState';
import useLayout from 'layout/frame/FrameLayoutContext';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import StringUtil from '../../../utils/StringUtil';

export function Body({ children }) {
  const [currentView, setCurrentView] = useState(null);
  const { application, appMenuDisplayed, applicationMenu, setAppMenuDisplayed } = useAppState();
  const { layout } = useLayout();
  const { appContentAreaPadding, headerHeight, bottomAppMenuBarHeight, mainContentNavBoxColor, navigationPaneWidth } = layout.cssVariables;

  const appContentBoxHeightSm = `calc(100vh - ${headerHeight} - ${bottomAppMenuBarHeight})`;
  const appContentBoxHeightlg = `calc(100vh - ${headerHeight})`;
  const appConentBoxHeight = {
    sx: appContentBoxHeightSm,
    md: appContentBoxHeightlg,
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        setCurrentView(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  /* Middle Column */
  return (
    <Box
      className='app-content-box'
      sx={{
        display: 'flex',
        mt: headerHeight,
        flexGrow: 1,
        height: appConentBoxHeight,
      }}>
      {/* Navigation Pane */}
      {appMenuDisplayed && (
        <Box
          className='app-main-content-nav-box'
          sx={{
            width: navigationPaneWidth,
            bgcolor: mainContentNavBoxColor,
            height: appConentBoxHeight,
            overflowY: 'auto',
            p: 2,
            color: 'white',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='directory-menu'>
            <div style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
              <HoverLink>{application && StringUtil.nullSafe(application.name)}</HoverLink>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton sx={{ color: '#FFF' }} onClick={() => setAppMenuDisplayed(false)}>
                {' '}
                <ChevronLeftIcon />{' '}
              </IconButton>
            </div>
          </div>
          {application && application.component ? (
            application.component
          ) : (
            <Box sx={{ height: '100%' }}>
              {applicationMenu.map((item, index) => (
                <ModuleItem item={item} key={index} setCurrentView={setCurrentView} />
              ))}
            </Box>
          )}
        </Box>
      )}

      {/* Main Content */}
      <Box
        className='app-content-box-outer'
        sx={{
          overflowY: 'hidden',
          flexGrow: 1,
          height: appConentBoxHeight,
          padding: appContentAreaPadding,
        }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export function ModuleItem({ item, sx, setCurrentView }) {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState();
  const navigate = useNavigate();
  const handleClick = () => {
    if (item.children) {
      setOpen(!open);
    } else if (item.url) {
      navigate(item.url);
      setCurrentView(item);
    }
  };

  useEffect(() => {
    setChildren(item.children && item.children.length > 0);
  }, [item]);

  return (
    <>
      <ListItemButton onClick={handleClick} sx={sx}>
        <ListItemText primary={item.name} />
        {children ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children?.map((child, index) => (
              <ModuleItem item={child} sx={{ pl: 4 }} key={index} setCurrentView={setCurrentView} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
