import { FormBox } from 'components/Box/Box';
import { Box, Drawer, styled } from '@mui/material';
import { AppMainTitleBar } from '../TitleBar/Titlebar';
// import './drawer.scss';
// import 'styles/app-console.scss';

class AppStateHolder {
  static appState = {
    setSideDrawerDisplayed: message => {},
  };
}
export const FullWidthSideDrawer = styled(SideDrawer)({
  '& .MuiDrawer-paperAnchorRight': {
    maxWidth: '100%',
    width: '100vw',
    zIndex: 99999,
    overflowY: 'visible',
    height: '100vh',
  },
});

export function SideDrawer(props) {
  const { headerTitle, onClose, rightIcons, rightToolBarId, ...others } = props;
  const appState = AppStateHolder.appState;
  function handleClose(e) {
    if (onClose && typeof onClose === 'function') {
      onClose(e);
    } else {
      appState.setSideDrawerDisplayed(false);
    }
  }
  return (
    <Drawer
      className='sideDrawer'
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '960px',
          overflowY: 'hidden',
          height: '100vh',
        },
      }}
      {...others}>
      <AppMainTitleBar rightToolBarId={rightToolBarId} fixed onClose={handleClose} rightIcons={rightIcons} title={props.headerTitle ? props.headerTitle.toUpperCase() : ''}></AppMainTitleBar>
      <Box sx={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 40px)' }}>{props.children}</Box>
    </Drawer>
  );
}

export function HeaderLessSideDrawer(props) {
  const { onClose, rightIcons, ...others } = props;

  function handleClose(e) {
    if (onClose && typeof onClose === 'function') {
      onClose(e);
    }
  }
  return (
    <Drawer onClose={handleClose} {...others}>
      {props.children}
    </Drawer>
  );
}

export function SideDrawerInternalDataContainer(props) {
  return (
    <>
      <FormBox sx={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px' }}>{props.children}</FormBox>
    </>
  );
}
