import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormBox } from 'components/Box/Box';
import { HotGrid180 } from 'components/Containers/Grid';
import { FormDatePicker, FormNumericInput } from 'components/Form/FormInputs';
import { HorizontalTab, UiTabContext, UiTabPanel } from 'components/Tabs';
import dayjs from 'dayjs';
import { Field, Formik } from 'formik';
import useHttp from 'hooks/useHttp';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// form field validation schema
const validationSchema = Yup.object().shape({
  startDate: Yup.date().required('Start Date is required!'),
  endDate: Yup.date().required('End Date is required!'),
});

const DETAIL_URL = '/cloud/subscription/show';
const SAVE_URL = '/cloud/subscription/save';
export function SubscriptionDetail({ accountCode, id }) {
  const [subscriptionDetail, setSubscriptionDetail] = useState({
    startDate: new Date(),
    endDate: new Date(),
    employees: 0,
  });

  const [loading, setLoading] = useState(true);
  const { get, post } = useHttp();
  useEffect(() => {
    if (id) {
      get(DETAIL_URL, { ac: accountCode, sb: id }).then(res => {
        setSubscriptionDetail(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [accountCode, id]);

  const tabs = [<HorizontalTab label='Main' value='main' key='main' />, <HorizontalTab label='Audit Logs' value='logs' key='logs' />];

  const handleFormSubmit = async values => {
    const data = { ...values };
    data.accountCode = accountCode;
    data['allowances'] = { EMPLOYEE: values.employees };
    setLoading(true);
    post(SAVE_URL, data).then(res => setLoading(false));
  };

  return (
    !loading && (
      <Box>
        <UiTabContext tabs={tabs} defaultTab={'main'}>
          <UiTabPanel value='main'>
            <Formik onSubmit={handleFormSubmit} initialValues={subscriptionDetail} validationSchema={validationSchema}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <FormBox>
                  <form onSubmit={handleSubmit}>
                    <SubscriptionForm handleBlur={handleBlur} handleChange={handleChange} values={values} touched={touched} errors={errors} />
                    <LoadingButton type='submit' color='primary' loading={loading} variant='contained' sx={{ my: 2 }}>
                      Save
                    </LoadingButton>
                  </form>
                </FormBox>
              )}
            </Formik>
          </UiTabPanel>
          <UiTabPanel value='logs'></UiTabPanel>
        </UiTabContext>
      </Box>
    )
  );
}

export function SubscriptionForm({ handleBlur, handleChange, values, touched, errors }) {
  const {t} = useTranslation()
  return (
    <Card className='card' sx={{ paddingX: 2 }}>
      <HotGrid180>
          <Field component={FormDatePicker} name='startDate' label={t('startDate')} format='DD-MMM-YYYY' onBlur={handleBlur} />
          <Field component={FormDatePicker} name='endDate' label={t('endDate')} format='DD-MMM-YYYY' onBlur={handleBlur}  />
        <FormNumericInput label='Users' name='employees' onBlur={handleBlur} value={values.employees} onChange={handleChange} />
      </HotGrid180>
    </Card>
  );
}
