import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Body } from 'layout/frame/layout2/body';
import { Feed } from 'layout/frame/layout2/feed';
import Header from 'layout/frame/layout2/header';
import { Nav } from 'layout/frame/layout2/nav';
import { applicationBoxHeight } from './constants';

function Layout2({ children }) {
  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: applicationBoxHeight,
    overflow: 'hidden',
    backgroundImage: 'url(https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1642&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }));

  return (
    <StyledBox>
      <Header />
      <Nav />
      <Body>{children}</Body>
      <Feed />
    </StyledBox>
  );
}

export default Layout2;

export const layout2Structure = {
  component: <Layout2 />,
  cssVariables: {
    sidebarWidthExpanded: '200px',
    sidebarWidthCollapsed: '60px',
    navigationPaneWidth: '250px',
    rightSidebarWidth: '320px',
    headerColor: '#1976d2',
    headerHeight: '40px',
    bottomAppMenuBarHeight: '40px',
    mainContentNavBoxColor: 'rgba(0, 0, 0, 0.65)',
    appContentAreaPadding: '10px',
    applicationBoxHeight: '100vh',
    applicationMainContentHeight: '100%',
    mainContentBgColor: '#fff',
    mainContentBorderRadius: '5px',
    mainContentPadding: '10px',
  },
};
