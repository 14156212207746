import axios from 'axios';
import 'fake-db';
import Mock, { axiosInstanceForMocking } from 'fake-db/mock';
import useAppErrors from 'hooks/useAppErrors';
import { createContext, useState } from 'react';
import { CollectionUtil } from 'utils/CollectionUtil';
import UrlUtil from 'utils/UrlUtils';
import { getTokenFromCookie } from '../utils/utils';
import { SuccessSnackbar } from './ErrorSuccessContext';

const HttpContext = createContext({
  get: async () => {},
  post: () => {},
  postMultipart: async () => {},
});

export function HttpContextProvider({ children }) {
  const { setAppErrors } = useAppErrors();
  const [showSuccess, setShowSuccess] = useState(false);
  const [getMockedPaths] = useState(Mock.handlers.get.map(a => a.url));
  const [postMockedPaths] = useState(Mock.handlers.post.map(a => a.url));

  const deleteResource = async (url, data, hideErrors) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = getMockedPaths.includes(url);
    return mocked
      ? axiosInstanceForMocking
          .delete(url, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(response => (response.data ? response.data : {}))
      : axios
          .delete(resolvedUrl, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(ajaxResponse => {
            const response = ajaxResponse.data ? ajaxResponse.data : {};
            if (CollectionUtil.isNotBlank(response.errors)) {
              setAppErrors(response.errors);
            }
            return response;
          })
          .catch(function (e) {
            if (!hideErrors) {
              if (e.status === 403) {
                setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
              } else {
                setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
              }
            }
          });
  };

  const get = async (url, data, hideErrors) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = getMockedPaths.includes(url);
    return mocked
      ? axiosInstanceForMocking
          .get(url, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(response => (response.data ? response.data : {}))
      : axios
          .get(resolvedUrl, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(ajaxResponse => {
            const response = ajaxResponse.data ? ajaxResponse.data : {};
            if (CollectionUtil.isNotBlank(response.errors)) {
              setAppErrors(response.errors);
            }
            return response;
          })
          .catch(function (e) {
            if (!hideErrors) {
              if (e.status === 403) {
                setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
              } else {
                setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
              }
            }
          });
  };

  const download = async (url, data, hideErrors) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = getMockedPaths.includes(url);
    return mocked
      ? axiosInstanceForMocking
          .get(url, {
            headers: { ...headers },
            responseType: 'blob',
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(response => (response.data ? response.data : {}))
      : axios
          .get(resolvedUrl, {
            headers: { ...headers },
            params: data, // Query parameters
            responseType: 'blob',
            withCredentials: true,
          })
          .catch(function (e) {
            if (!hideErrors) {
              if (e.status === 403) {
                setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
              } else {
                setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
              }
            }
          });
  };

  function getFileNameFromContentDisposition(contentDisposition) {
    // Regular expression to extract the filename
    const match = contentDisposition.match(/filename="([^"]+)"/);
    return match ? match[1] : 'downloaded-file';
  }

  const downloadFile = async (url, data) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    axios
      .get(resolvedUrl, {
        headers: { ...headers },
        params: data, // Query parameters
        responseType: 'blob',
        withCredentials: true,
      })
      .then(response => {
        // Extract the filename from the server's Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        const fileName = getFileNameFromContentDisposition(contentDisposition);

        // Create a link element to trigger the download
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (e) {
        if (e.status === 403) {
          setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
        } else {
          setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
        }
      });
  };

  const post = async (url, data, onSuccess) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    setShowSuccess(false);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = postMockedPaths.includes(url);
    var isException = false;
    const response = mocked
      ? await axiosInstanceForMocking.post(url, data, {
          headers: { ...headers },
          withCredentials: true,
        })
      : await axios
          .post(resolvedUrl, data, {
            headers: { ...headers },
            withCredentials: true,
          })
          .catch(function (e) {
            isException = true;
            if (e.status === 403) {
              setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
            } else {
              setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
            }
          });

    if (!isException) {
      if (response && response.data && response.data.errors && response.data.errors.length > 0) {
        setAppErrors(response.data.errors);
      } else {
        setShowSuccess(true);
      }
    }

    return response ? response : { data: { errors: 'An unexpected error has been encountered. Please try after sometime.' } };
  };

  const postMultipart = async (url, data, files, onSuccess) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    setShowSuccess(false);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    var buffer = new FormData();
    Object.keys(data).forEach(key => data[key] && buffer.append(key, data[key]));
    files.forEach((file, i) => buffer.append('files', file));

    var isException = false;
    const response = await axios
      .post(resolvedUrl, buffer, {
        headers: { ...headers },
        withCredentials: true,
        contentType: false, // Required to submit multipart requests.
        processData: false, // Required to avoid form data processing.
      })
      .catch(function () {
        isException = true;
        setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
      });

    if (!isException) {
      if (response && response.data.errors && response.data.errors.length > 0) {
        setAppErrors(response.data.errors);
      } else {
        setShowSuccess(true);
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(response);
        }
      }
    }
  };

  return (
    <HttpContext.Provider value={{ download, get, post, postMultipart, downloadFile, deleteResource }}>
      {showSuccess && <SuccessSnackbar />}
      {children}
    </HttpContext.Provider>
  );
}

export default HttpContext;
