import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './menu.scss';

class AppStateHolder {
    static appState = {
        appListMenuDisplayed: null,
        setAppListMenuDisplayed: null
    };
}

/**
 * An Application list menu.
 */
export function AppListMenu(props) {
    const appState = AppStateHolder.appState;

    return <div className={appState.appListMenuDisplayed ? "applistmenu applistmenushow" : "applistmenu applistmenuhide"}>
        <div className="app-dropdown-menu hangleft">
            {props.children}
        </div>
    </div>
}

/**
 * An application list menu button.
 */
export function AppListMenuTrigger() {
    const appState = AppStateHolder.appState;

    function toggleMenu() {
        appState.setAppListMenuDisplayed(!appState.appListMenuDisplayed);
    }

    return <div onClick={toggleMenu} className={appState.appListMenuDisplayed ? "applistmenuclick applistmenushow" : "applistmenuclick applistmenuhide"}>
        <i className="fas fa-bars" aria-hidden="true">&nbsp;</i>
    </div>
}

/**
 * An application list menu button.
 */
export function AppListAddButton(props) {
    const { t } = useTranslation();

    const appState = AppStateHolder.appState;
    const url = props.url;


    return <button title={t("new")} onClick={() => appState.setObjectDetailUrl(url)}><i className="fa fa-plus"></i><span>{t("new")}</span></button>
}

export function AppListMenuUploadButton(props) {
    const { t } = useTranslation();

    const appState = AppStateHolder.appState;
    const { onClick, title, ...others } = props;
    const url = props.url;

    function handleClick(e) {
        if (onClick && typeof (onClick) === 'function') {
            onClick(e);
        }
        else {
            appState.setObjectDetailUrl(url)
        }
    }

    return <button title={title ? title : t("upload")} onClick={(e) => handleClick(e)}><i className="fa fa-upload"></i><span>{title ? title : t("upload")}</span></button>
}

export function DropDownMenu(props) {
    const { menuIcon } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-expanded={open ? 'true' : undefined}
        >
            {menuIcon ? menuIcon : <MenuIcon />}
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {props.children}
        </Menu>
    </>
}

export function MoreVertMenu(props) {
    return <DropDownMenu menuIcon={<MoreVertIcon />}>
        {props.children}
    </DropDownMenu>
}