import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import SaveIcon from '@mui/icons-material/Save';
import { IconButton, MenuItem } from '@mui/material';
import { FormBox } from 'components/Box/Box';
import { HotGrid280 } from 'components/Containers/Grid';
import { FormDatePicker, FormInput, FormSelect } from 'components/Form/FormInputs';
import { AppMainBody } from 'components/ToolBar/Toolbar';
import { VerticalTabContainer } from 'components/object-list/vertical-tab/VerticalTabContainer';
import { Field, Formik } from 'formik';
import useHttp from 'hooks/useHttp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtil from 'utils/StringUtil';
import { RolesTab } from 'views/roles/RolesTab';
import * as Yup from 'yup';

/**
 * A detailed information about a panel.
 */

const ROLES_URL = '/cloud/people/roles/';
const STATE_ID = 'employeeDetails';

export function EmployeeDetail(props) {
  return (
    <AppMainBody classes={'no-tool-bar'}>
      <MainTab {...props} />
    </AppMainBody>
  );
}

function MainTab(props) {
  const systemCode = props.systemCode;
  const { t } = useTranslation();

  const items = [
    {
      legendTitle: t('basic'),
      legendIcon: <KeyboardArrowRightIcon />,
      itemHeaderLeftToolSet: t('basic'),
      itemHeaderRightToolSet: (
        <IconButton type='submit' form='EmployeeDetailsForm'>
          <SaveIcon />
        </IconButton>
      ),
      detail: <BasicSection systemCode={systemCode} />,
    },
  ];

  if (systemCode) {
    items.push({
      legendTitle: 'Access rights',
      legendIcon: <KeyboardArrowRightIcon />,
      itemHeaderLeftToolSet: 'Access rights',
      itemHeaderRightToolSet: (
        <IconButton type='submit' form='accessRightForm'>
          <SaveIcon />
        </IconButton>
      ),
      detail: <RolesTab {...props} rolesUrl={ROLES_URL} stateId={STATE_ID} />,
    });
  }

  return <VerticalTabContainer classes='full-height' items={items} defaultIndex={0} />;
}

function BasicSection(props) {
  const { systemCode } = props;
  const { t } = useTranslation();
  const { get, post } = useHttp();
  const [loading, setLoading] = useState(true);

  const [employee, setEmployee] = useState({
    systemCode: '',
    accountLegalName: '',
    accountName: '',
    referenceCode: '',
    emailAddress: '',
    name: '',
    firstName: '',
    lastName: '',
    title: '',
    employmentType: 'FULL_TIME',
    permanent: true,
    startDate: '',
    endDate: '',
    revenueGenerating: false,
  });

  const BASIC_DETAILS = '/cloud/people/edit';

  useEffect(() => {
    const dataFetchUrl = systemCode ? BASIC_DETAILS + '/' + systemCode : null;
    if (systemCode) {
      setLoading(true);
      get(dataFetchUrl).then(res => {
        setEmployee(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [systemCode]);

  const validationSchema = Yup.object().shape({
    referenceCode: Yup.string().min(6, 'Reference Code must be 6 character length').required('Reference Code is required!'),
    emailAddress: Yup.string().email('Invalid Email address').required('Email is required!'),
  });

  function handleFormSubmit(values) {
    setLoading(true);
    setEmployee(values);
    post(BASIC_DETAILS, values).then(response => {
      setLoading(false);
    });
  }

  const update = StringUtil.isNotBlank(systemCode);
  return (
    !loading && (
      <FormBox>
        <Formik onSubmit={handleFormSubmit} initialValues={employee} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form id='EmployeeDetailsForm' onSubmit={handleSubmit}>
              <HotGrid280>
                {values.systemCode && <input type='hidden' name='systemCode' value={values.systemCode} />}
                <FormInput
                  helperText={touched.referenceCode && errors.referenceCode}
                  error={Boolean(errors.referenceCode && touched.referenceCode)}
                  name='referenceCode'
                  label={t('code')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={StringUtil.nullSafe(values.referenceCode)}
                />
                <FormInput
                  helperText={touched.emailAddress && errors.emailAddress}
                  error={Boolean(errors.emailAddress && touched.emailAddress)}
                  name='emailAddress'
                  label={t('email')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.emailAddress}
                />
                <FormInput
                  helperText={touched.title && errors.title}
                  error={Boolean(errors.title && touched.title)}
                  name='title'
                  label={t('user-title')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.title}
                />
                <FormInput
                  helperText={touched.name && errors.name}
                  error={Boolean(errors.name && touched.name)}
                  name='name'
                  label={t('name')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.name}
                />

                <FormInput
                  helperText={touched.firstName && errors.firstName}
                  error={Boolean(errors.firstName && touched.firstName)}
                  name='firstName'
                  label={t('firstName')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.firstName}
                />
                <FormInput
                  helperText={touched.lastName && errors.lastName}
                  error={Boolean(errors.lastName && touched.lastName)}
                  name='lastName'
                  label={t('lastName')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.lastName}
                />
                <FormSelect
                  error={Boolean(errors.employmentType && touched.employmentType)}
                  label={t('employmentType')}
                  name='employmentType'
                  value={values.employmentType}
                  onBlur={handleBlur}
                  onChange={handleChange}>
                  <MenuItem value='FULL_TIME'>Full Time</MenuItem>
                  <MenuItem value='PART_TIME'>Part Time</MenuItem>
                </FormSelect>
                <Field component={FormDatePicker} name='startDate' label={t('startDate')} format='DD-MMM-YYYY' onBlur={handleBlur} onValueChange={handleChange} />
                <Field component={FormDatePicker} name='endDate' label={t('endDate')} format='DD-MMM-YYYY' onBlur={handleBlur} onValueChange={handleChange} />
              </HotGrid280>
            </form>
          )}
        </Formik>
      </FormBox>
    )
  );
}
