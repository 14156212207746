import { Link } from '@mui/material';
import { SmartSpan } from 'components/Box/Box';
import { Typography } from '@mui/material';

export function HyperLink(props) {
  return (
    <SmartSpan sx={{ cursor: 'pointer', color: '#936', fontWeight: '600' }} {...props}>
      {props.children}
    </SmartSpan>
  );
}

export function HoverLink(props) {
  const { onClick, ...others } = props;
  return (
    <Link
      underline='hover'
      color='inherit'
      href='#'
      onClick={e => {
        onClick && onClick();
        e.stopPropagation();
      }}>
      {props.children}
    </Link>
  );
}
