import { NumberUtil } from './NumberUtil';

export default class StringUtil {
  /**
   * Gets whether the first value contains second value.
   * @param first  The first value.
   * @param second  The second value.
   */
  static containingIgnoreCase(first, second) {
    return first.toLowerCase().includes(second.toLowerCase());
  }

  static firstNotNull(...args) {
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (typeof arg === 'string' && StringUtil.isNotBlank(arg)) {
        return arg;
      }
    }
    return null;
  }

  /**
   * Gets whether the {first} and {second} are equal ignoring case.
   */
  static equalsIgnoreCase(first, second) {
    return first && second && first.toLowerCase() === second.toLowerCase();
  }

  /**
   * Converts the value into proper case / Title case.
   */
  static toProperCase(value) {
    return value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Gets the hash code for a string.
   * @returns The hash code for a string.
   */
  static getHashCode(text) {
    var hash = 0;
    for (var i = 0; i < text.length; i++) {
      var char = text.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }

    return hash;
  }

  /**
   * Gets the initials for the text.
   */
  static getInitials(text) {
    const parts = text.split(' ');
    let initials = '';
    if (parts.length > 1) {
      initials = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
      initials = text.substring(0, 2);
    }

    return initials.toUpperCase();
  }

  static generateRandomNumberString(digits) {
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += Math.floor(Math.random() * 10);
    }
    return result;
  }

  static removeNonAlphanumeric(str) {
    return str.replace(/[^a-zA-Z0-9]/g, '');
  }

  static getRandomString(length) {
    const str = Math.random().toString(36);
    return str.substring(str.length - length);
  }

  static isBlank(inputStr) {
    return !StringUtil.isNotBlank(inputStr);
  }

  static isNotBlank(inputStr) {
    return inputStr != undefined && inputStr !== null && typeof inputStr === 'string' && inputStr.trim().length > 0;
  }

  static concatenate(...args) {
    var result = '';
    if (args && args.length > 0) {
      for (var i = 0; i < args.length; i++) {
        var safeArgs = this.nullSafe(args[i]);
        if (this.isNotBlank(safeArgs)) {
          result = result + safeArgs + ' ';
        }
      }
      if (this.isNotBlank(result)) {
        result = result.substring(0, result.length - 1);
      }
    }
    return result;
  }

  static nullSafe(inputStr, defaultValue) {
    if (defaultValue === undefined || defaultValue === null) {
      defaultValue = '';
    }
    if (inputStr === undefined || inputStr === null) {
      return defaultValue;
    } else {
      return inputStr;
    }
  }

  static defaultValue(inputStr, defaultValuePrefix) {
    return StringUtil.isNotBlank(inputStr) ? inputStr : StringUtil.nullSafe(defaultValuePrefix, '') + NumberUtil.fixedLengthRandomInt();
  }

  static curtailWithEllipses(inputStr, maxLength, takefullWordsOnly) {
    if (!takefullWordsOnly) {
      if (StringUtil.isNotBlank(inputStr) && inputStr.length > maxLength) {
        return inputStr.substr(0, maxLength) + '...';
      }
      return inputStr;
    } else {
      if (StringUtil.isNotBlank(inputStr) && inputStr.length > maxLength) {
        return StringUtil.curtailByWord(inputStr, maxLength) + '...';
      }
      return inputStr;
    }
  }

  static curtailByWord(inputStr, maxLength) {
    if (StringUtil.isNotBlank(inputStr) && inputStr.length > maxLength) {
      var tempLength = 0;
      const words = inputStr.split(' ');
      if (words.length > 1) {
        var outPutString = '';
        for (var i = 0; tempLength + words[i].length + 1 <= maxLength; i++) {
          if (i > 0) {
            outPutString = outPutString + ' ';
          }
          outPutString = outPutString + words[i];
          tempLength = outPutString.length;
        }
        return outPutString;
      } else {
        return inputStr.substring(0, maxLength);
      }
    }
    return inputStr;
  }

  static isValidHandle(value) {
    return StringUtil.isNotBlank(value) && /^[A-Z|a-z|\-|_|0-9]{10,}$/.test(value);
  }

  static showUnsupportedError() {
    return this.showAsError('This operation is not supported');
  }

  static showUnspportedErrror() {
    return this.showAsError('This operation is currently not supported');
  }

  static isValidJson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  static getLabel(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split('_') // Split the string by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' ');
  }

  static getHtmlValidId(str) {
    return str
      .toLowerCase() // Convert the string to lowercase
      .trim() // Remove any leading or trailing spaces
      .replace(/[^a-z0-9 _-]/g, '') // Remove invalid characters (anything that's not a-z, 0-9, space, underscore, or hyphen)
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
      .replace(/^[^a-z]+/i, '_');
  }

  static stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  static stringAvatar(name) {
    const split = name.split(' ');
    const initials = split[1] ? split[0][0] + split[1][0] : split[0][0];
    return {
      sx: {
        bgcolor: StringUtil.stringToColor(name),
      },
      children: initials,
    };
  }

  static getPixelToNumber(value) {
    if (typeof value == 'string') {
      return value.replace('px', '');
    }
    return value;
  }
}
