import useAppState from "hooks/useAppState";
import { CollectionUtil } from "../../utils/CollectionUtil";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function PermissionBoundary(props) {
    const permissions = props.permissions;
    const {hasAnyPermission} = useAppState()
    return CollectionUtil.isBlank(permissions) || hasAnyPermission(permissions) 
            ? props.children
            : <PermissionErrorDialogBox />
}

export function PermissionErrorDialogBox(props) {

    const { t } = useTranslation();
    const description = t('insufficient-permission-description');
    const title = t('insufficient-permission')
    const [open, setOpen] = useState(true);
    //const navigate = useNavigate();

    function handleClose() {
        //navigate("/")
        setOpen(false);
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{padding : 0}}>
            <Alert severity="error" sx={{display : 'flex', fontSize:"20px", fontStyle:"bold"}}>
                {title}
            </Alert>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{marginTop : "10px"}}>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button key={"Ok"} onClick={handleClose}>{"Ok"}</Button>
        </DialogActions>
    </Dialog>
}