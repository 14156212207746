import { Box } from '@mui/material';
import { SideDrawerBackButtonIcon } from 'components/Icons';
import { createPortal } from 'react-dom';
import StringUtil from 'utils/StringUtil';

export function AppMainTitleBar(props) {
  const { title, fixed, trackMenu, onClose, rightIcons, rightToolBarId, classes, ...others } = props;

  function handleClose(e) {
    if (onClose && typeof onClose === 'function') {
      onClose(e);
    }
  }

  return (
    <Box
      className={StringUtil.isNotBlank(classes) ? classes : 'appmain-titlebar'}
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: '#ddd',
        fontWeight: '600',
        minHeight: '40px',
      }}>
      <SideDrawerBackButtonIcon onClick={handleClose} />
      <Box
        sx={{
          width: 300, // Set the width of the box
          display: 'flex', // Use flexbox to center content
          justifyContent: 'left', // Horizontally center the text
          alignItems: 'center', // Vertically center the text
          paddingLeft: '10px',
        }}>
        {title}
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, paddingRight: '10px' }} id={rightToolBarId || StringUtil.getHtmlValidId(title)}></Box>
    </Box>
  );
}

export function AppMainTitleIcon({ onClick, children }) {
  return (
    <span className='appmainoptionsclick new-tab-link' onClick={onClick}>
      {children}
    </span>
  );
}

export function ContainerButtons(props) {
  const { buttons, containerId } = props;
  const container = document.getElementById(containerId);
  return container && createPortal(<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{buttons.map(button => button)}</Box>, container);
}
