import FolderIcon from '@mui/icons-material/Folder';
import UploadIcon from '@mui/icons-material/Upload';
import { Breadcrumbs, Button, IconButton, Link, MenuItem, TextField } from '@mui/material';
import { AppContentMainDataFrame } from 'components/application-content/AppContentMainDataFrame';
import { AppContentDataContainerBoxWithTabs, AppContentInnerBox } from 'components/Box/Box';
import { ContextMenuContextProvider } from 'components/context-menu/ContextMenuContext';
import DialogBox from 'components/Dialog';
import { AppContentSearchableHeader } from 'components/Header/Header';
import { FileIcon } from 'components/Icons';
import { DropDownMenu } from 'components/Menu/Menu';
import { ClickableNuggetMain, NuggetImageIcon } from 'components/object-list/Nuggets/Nugget';
import FolderUpload from 'components/upload/FolderUpload';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { FileSystemProvider } from 'contexts/filesystem/FileSystemContext';
import useFileSystem from 'hooks/filesystem/useFileSystem';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StringUtil from 'utils/StringUtil';
import ObjectList from '../../components/object-list/ObjectList';
import { FileObjectContextMenu } from './FileObjectContextMenu';
import useFileUpload from 'hooks/filesystem/useFileUpload';

const PAGE_URL = '/file-object/page';
export const Directory = forwardRef((props, ref) => {
  return (
    <ContextMenuContextProvider>
      <FileSystemProvider>
        <DirectoryList {...props} />
      </FileSystemProvider>
    </ContextMenuContextProvider>
  );
});

const DirectoryList = forwardRef((props, ref) => {
  const { driveName } = useParams();
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState();
  const { addObject, getMandatoryRequestParams, editObjectName, currentDirectory, setCurrentDirectory, breadcrumbs, goToBreadcrumb} = useFileSystem();
  const {uploadFolderAndFiles} = useFileUpload();
  const [objects, setObjects] = useState([]);
  const [renameItem, setRenameItem] = useState();
  const [data, setData] = useState();
  const [showDropZone, setShowDropZone] = useState(false);

  useEffect(() => {
    setData({...data, keywords: keywords, ...getMandatoryRequestParams() });
  }, [keywords, currentDirectory]);

  function open(item) {
    setCurrentDirectory(item);
  }

  const menuItems = [
    { label: 'Create Folder', action: () => createNewObject({ url: '/file-object/add', name: 'New Folder', type: 'FOLDER' }) },
    { label: 'Create Html Block', action: () => createNewObject({ url: '/file-object/add', name: 'New Block', type: 'HTML_BLOCK' }) },
    { label: 'Create Product Binder', action: () => createNewObject({ url: '/file-object/add', name: 'New Product Binder', type: 'PRODUCT_BINDER' }) },
  ];

  function createNewObject({ url, ...params }) {
    const parentObjectCode = breadcrumbs[breadcrumbs?.length - 1]?.systemCode;
    addObject({ url, ...params }).then(res => {
      if (res?.summary) {
        const buffer = [...objects];
        const { summary } = res;
        summary.parentObjectCode = parentObjectCode;
        buffer.push(summary);

        setObjects(buffer);
      }

      // Simply update data state variable to reload the page.
      setData({...data})
    });
  }

  function actionMenu(item) {
    return <FileObjectContextMenu item={item} onRename={() => setRenameItem(item)} />;
  }

  function handleRename() {
    editObjectName({ url: '/file-object/basic', name: renameItem.name, systemCode: renameItem.systemCode, type: renameItem.type }).then(() => setRenameItem(null));
  }

  function nuggetTemplate(item) {
    return (
      <div className={item.focused ? 'rootnugget appactive' : 'rootnugget'}>
        <ClickableNuggetMain item={item} onClick={() => open(item)}>
          <div>
            <NuggetImageIcon icon={item.directory ? <FolderIcon /> : <FileIcon fileType={item.type} />} />
            <div className='nugget__rootclick-appmain'>
              <div className={'nugget-title'}>{item.name}</div>
              <div className={'nugget-subtitle'}>{item.handle}</div>
            </div>
          </div>
        </ClickableNuggetMain>
        <div className='nugget-after'>{actionMenu(item)}</div>
      </div>
    );
  }
  const headerButtons = [
    <DropDownMenu>
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.action}>
          {item.label}
        </MenuItem>
      ))}
    </DropDownMenu>,
    <IconButton onClick={() => setShowDropZone(!showDropZone)}>
      <UploadIcon />
    </IconButton>,
  ];

  useEffect(() => {
    // Prevent default browser behavior for drag-and-drop globally
    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    data && (
      <div>
        {renameItem && (
          <DialogBox
            open={true}
            title={'Rename :: ' + renameItem.handle}
            description={
              <TextField
                label={t('name')}
                value={renameItem.name}
                size='small'
                onChange={e =>
                  setRenameItem(prevState => {
                    return { ...prevState, name: e.target.value };
                  })
                }
              />
            }
            handleClose={() => setRenameItem(null)}
            buttons={[
              <Button key={'Yes'} onClick={() => handleRename()}>
                {t('update')}
              </Button>,
              <Button key={'No'} onClick={() => setRenameItem(null)}>
                {t('cancel')}
              </Button>,
            ]}
          />
        )}
        <AppContentInnerBox>
          <AppContentSearchableHeader buttons={headerButtons} itemNameToSearch={driveName} setKeywords={setKeywords} onSearch={() => {}}></AppContentSearchableHeader>
          <AppContentMainDataFrame>
            {showDropZone ? (
              <FolderUpload />
            ) : (
              <>
                <Breadcrumbs maxItems={2} aria-label='breadcrumb' sx={{ marginY: '5px' }}>
                  {breadcrumbs.map((b, index) => {
                    return (
                      <Link underline='hover' color='inherit' href='#' onClick={() => goToBreadcrumb(index)} key={b.systemCode}>
                        {b.name}
                      </Link>
                    );
                  })}
                </Breadcrumbs>
                <PageableDataManagerProvider url={PAGE_URL} eventName={'DRIVE__CREATED'}>
                  <AppContentDataContainerBoxWithTabs>
                    <ObjectList
                      onDrop={async (row, event) => {
                        uploadFolderAndFiles(row, event)
                      }}
                      data={data}
                      onRowClick={item => open(item)}
                      nuggetTemplate={nuggetTemplate}
                      actionMenu={actionMenu}
                      headCells={[
                        {
                          id: 'systemCode',
                          numeric: false,
                          label: '',
                          formatter: item => (item.directory ? <FolderIcon /> : <FileIcon fileType={item.type} />),
                          nuggetInfo: {
                            order: 6,
                            type: 'subtitle',
                          },
                        },
                        {
                          id: 'handle',
                          numeric: false,
                          label: 'Handle',
                          nuggetInfo: {
                            order: 3,
                            type: 'subtitle',
                          },
                        },
                        {
                          id: 'name',
                          numeric: false,
                          label: 'Name',
                          nuggetInfo: {
                            order: 1,
                            type: 'title',
                          },
                        },
                        {
                          id: 'type',
                          numeric: false,
                          label: 'Type',
                          nuggetInfo: {
                            order: 1,
                            type: 'subtitle',
                          },
                          formatter: item => StringUtil.getLabel(item.type),
                        },
                        {
                          id: 'createdAt',
                          numeric: false,
                          label: 'Created At',
                          nuggetInfo: {
                            order: 4,
                            type: 'date',
                          },
                        },
                      ]}
                    />
                  </AppContentDataContainerBoxWithTabs>
                </PageableDataManagerProvider>
              </>
            )}
          </AppContentMainDataFrame>
        </AppContentInnerBox>
      </div>
    )
  );
});
