import React from "react";
import useAppState from "hooks/useAppState";

class AppStateHolder {
  static appStat = {
    objectDetailUrl: '',
    detailsDisplayed: false
  }
}
/**
 * Application Layout
 */
export default function AppMain() {
  const appState = AppStateHolder.appState;

  const url = appState.objectDetailUrl;
  const closed = !appState.detailsDisplayed;
  return <div className={closed ? 'appmain iframe-container' : 'appmain iframe-container appmainshow'}>
    {!closed && (
      <React.Fragment>
        <div className="appmain-float-closebutton appmain-closeclick" onClick={() => appState.setObjectDetailUrl('')}>
          <i className="fas fa-times">&nbsp;</i>
        </div>
        {process.env.ENV === "dev" ? <iframe title="Iframe" src={url} allow="geolocation"></iframe> : <iframe title="Iframe" src={url}></iframe>}

      </React.Fragment>
    )}
  </div>
}


export function AppMainContainer(props) {
  const style = props.style;
  const showAppContainer = props.showAppContainer;
  const {detailsDisplayed} = useAppState();
  return (
    <div className={detailsDisplayed || showAppContainer ? 'appmain appmainshow' : 'appmain appmainhide'} style={{ ...style }}>
      <div className="appmain-object">
        {props.children}
      </div>
    </div>
  )
}

export function AppInternalContainer(props) {
  const style = props.style;
  return (
    <div className="appmain appmainshow" style={{ margin: "0", ...style }}>
      <div className="appmain-object">
        {props.children}
      </div>
    </div>
  )
}
