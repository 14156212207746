import { headerColor } from "../../layout/frame/layout2/constants";

export const menuPaddingValue = 16;

export const menuBackgroundColor = "#F5F5F5";
export const menuPadding = menuPaddingValue + "px";
export const firstColumnWidth = '200px';
export const firstColumnRightBorder = "1px solid #ddd";
export const mainTitleColor = "#333";
export const mainTitleFontSize = "1.25rem";

/* Menu Item Properties */
export const menuItemFontSize = '1.5rem';
export const menuItemLeftPaddingValue = (menuPaddingValue/2);
export const menuItemLeftPadding = menuItemLeftPaddingValue + "px";
export const menuItemIconMinHeight = '1em';
export const menuItemIconBorderValue = 3;
export const menuItemIconBorder = menuItemIconBorderValue + "px solid ";
export const menuItemIconMinWidth = '35px';
export const menuItemIconPaddingLeftValue = (menuPaddingValue - menuItemLeftPaddingValue - menuItemIconBorderValue);
export const menuItemIconPaddingLeft = menuItemIconPaddingLeftValue + "px";


export function getColor(item)  {
    switch(item) {
        case 'FOLDER' : return '#F8D775';
        case 'HTML_BLOCK' : return '#f16529';
        default : return headerColor;
    }
}
