export default function LandingPage() {
  return (
    <div
      className='landing-page'
      style={{
        width: '100%',
        height: '100%',
      }}></div>
  );
}
