import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const sales = { name : 'Sales',
    icon : <PeopleAltIcon />,
    nextLevel : [, 
        {
            name : 'Lead Management',
            icon : <StarBorderOutlinedIcon />,
            children : [
                {
                    name : 'Setup',
                }
            ]
        }, {
            name : 'Sales Credit / Planning Rules',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Sales Targets',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ]
  }