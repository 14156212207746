import { LoadingButton } from "@mui/lab";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import dayjs from 'dayjs';
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AppMainContainer } from "components/AppRoot/AppMain";
import { AppMainTitleBar } from "components/TitleBar/Titlebar";
import { AppMainBody } from "components/ToolBar/Toolbar";
import useAppState from "hooks/useAppState";
import useAuth from "hooks/useAuth";
import useHttp from "hooks/useHttp";
import { useEffect } from "react";


const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  backgroundColor: "#FFF"
}));


const initialValues = {
    inceptionDate : new Date()
};

// form field validation schema
const validationSchema = Yup.object().shape({
  legalName: Yup.string().required("Legal Name is required!"),
  address1: Yup.string().required("Address is required!"),
  countryCode: Yup.string().required("Country is required!"),
  districtCode: Yup.string().required("District is required!"),
  town: Yup.string().required("Town is required!"),
  inceptionDate: Yup.date().required("Inception Date is required!"),
  legalEmailAddress: Yup.string().email("Invalid Email address").required("Email is required!")
});


export default function AccountCreationView() {
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const {setDetailsDisplayed} = useAppState();
    const {user} = useAuth();
    const {get, post} = useHttp();
  
    const handleFormSubmit = async (values) => {
        const data = {
            administrator : user.principal,
            authorizer : user.principal,
            claimed : true,
            inceptionDate : values.inceptionDate,
            virtualId : values.virtualId,
            legalName : values.legalName,
            legalEmailAddress : values.legalEmailAddress,
            emailAddress2 : values.emailAddress2,
            website1 : values.website,
            phone1 : values.phone1,
            registeredAddress : {
                line1 : values.address1,
                line2 : values.address2,
                countryCode : values.countryCode,
                districtCode : values.districtCode,
                town : values.town,
                postCode : values.postCode
            }
        }
        setLoading(true);
        post("/account/create", data).then(res => setLoading(false));
    };
  
    useEffect(() => {
        const loadCountries = async () => {
            get("/location/countries").then(data => setCountries(data));
        }
    
        loadCountries()

        setDetailsDisplayed(true)
    }, []);

    async function loadProvince(country) {
        get("/location/provinces/" + country).then(data => setProvinces(data));
    }
    
    async function loadDistricts(province) {
        get("/location/districts/" + province).then(data => setDistricts(data));
    }
    
    return (
        <AppMainContainer>
            <AppMainTitleBar title={"Create Account"} />
            <AppMainBody classes={"no-tool-bar"}>
                <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                        <Card className="card" sx={{paddingX : 2}}>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <ContentBox>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="virtualId"
                                            label="Handle"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.handle}
                                            onChange={handleChange}
                                            helperText={touched.handle && errors.handle}
                                            error={Boolean(errors.handle && touched.handle)}
                                            sx={{ mb: 3 }}
                                            />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="legalName"
                                            label="Name"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.legalName}
                                            onChange={handleChange}
                                            helperText={touched.legalName && errors.legalName}
                                            error={Boolean(errors.legalName && touched.legalName)}
                                            sx={{ mb: 3 }}
                                            />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                fullWidth
                                                size="small"
                                                name="inceptionDate"
                                                label="Inception Date"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={dayjs(values.inceptionDate)}
                                                onChange={handleChange}
                                                helperText={touched.inceptionDate && errors.inceptionDate}
                                                error={Boolean(errors.inceptionDate && touched.inceptionDate)}
                                                sx={{ mb: 3, width : '100%' }}
                                                />
                                        </LocalizationProvider>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="legalEmailAddress"
                                            label="Legal Email Address"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.legalEmailAddress}
                                            onChange={handleChange}
                                            helperText={touched.legalEmailAddress && errors.legalEmailAddress}
                                            error={Boolean(errors.legalEmailAddress && touched.legalEmailAddress)}
                                            sx={{ mb: 3 }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="emailAddress2"
                                            label="Email Address 2"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.emailAddress2}
                                            onChange={handleChange}
                                            helperText={touched.emailAddress2 && errors.emailAddress2}
                                            error={Boolean(errors.emailAddress2 && touched.emailAddress2)}
                                            sx={{ mb: 3 }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="phone1"
                                            label="Phone"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.phone1}
                                            onChange={handleChange}
                                            helperText={touched.phone1 && errors.phone1}
                                            error={Boolean(errors.phone1 && touched.phone1)}
                                            sx={{ mb: 3 }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="website"
                                            label="Website"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.website}
                                            onChange={handleChange}
                                            helperText={touched.website && errors.website}
                                            error={Boolean(errors.website && touched.website)}
                                            sx={{ mb: 3 }}
                                        />
                                    </ContentBox>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <ContentBox>            
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="address1"
                                            label="Address 1"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.address1}
                                            onChange={handleChange}
                                            helperText={touched.address1 && errors.address1}
                                            error={Boolean(errors.address1 && touched.address1)}
                                            sx={{ mb: 3 }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="address2"
                                            label="Address 2"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.address2}
                                            onChange={handleChange}
                                            helperText={touched.address2 && errors.address2}
                                            error={Boolean(errors.address2 && touched.address2)}
                                            sx={{ mb: 3 }}
                                        />
                                        <FormControl fullWidth sx={{ mb: 3 }} size="small">
                                            <InputLabel id="country">Country</InputLabel>
                                            <Select
                                                labelId="country"
                                                id="country"
                                                value={values.countryCode}
                                                label="Age"
                                                name="countryCode"
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    loadProvince(e.target.value)
                                                }}
                                            >
                                                {countries.map(country => {
                                                    return <MenuItem value={country.isoAlpha2Code} key={country.isoAlpha2Code}> 
                                                        <em>{country.name}</em>
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 3 }} size="small">
                                            <InputLabel id="state">State</InputLabel>
                                            <Select
                                                labelId="state"
                                                id="state"
                                                value={values.province}
                                                label="State"
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    loadDistricts(e.target.value)
                                                }}
                                            >
                                                {provinces.map(province => {
                                                    return <MenuItem value={province.referenceCode}>
                                                        <em>{province.name}</em>
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 3 }} size="small">
                                            <InputLabel id="district">District</InputLabel>
                                            <Select
                                                labelId="district"
                                                id="district"
                                                value={values.districtCode}
                                                label="District"
                                                name="districtCode"
                                                onChange={handleChange}
                                            >
                                                {districts.map(district => {
                                                    return <MenuItem value={district.referenceCode}>
                                                        <em>{district.name}</em>
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="town"
                                            label="Town"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.town}
                                            onChange={handleChange}
                                            helperText={touched.town && errors.town}
                                            error={Boolean(errors.town && touched.town)}
                                            sx={{ mb: 3 }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="postCode"
                                            label="Pin Code"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.postCode}
                                            onChange={handleChange}
                                            helperText={touched.postCode && errors.postCode}
                                            error={Boolean(errors.postCode && touched.postCode)}
                                            sx={{ mb: 3 }}
                                        />
                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            loading={loading}
                                            variant="contained"
                                            sx={{ my: 2 }}>
                                            Create
                                        </LoadingButton>                
                                    </ContentBox>
                                </Grid>                    
                            </Grid>
                        </Card>
                    </form>
                )}
                </Formik>
            </AppMainBody>
        </AppMainContainer>
      );
    
}
